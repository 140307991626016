import React, { useEffect, useState, useRef } from "react";
import api from "../../api";
import Success from "components/Typography/Success";
import TimeSeries from "../TimeSeries.js";
import TimeSeries1 from "../TimeS";
import { message, Spin } from "antd";
import Devicetrend from "views/Devicetrend";
import DeviceTrend24hrs from "views/Devicetrend24hrs";
import Backdrop from "@material-ui/core/Backdrop";
import { Grid, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { CircularProgress } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
import { format, compareAsc } from "date-fns";
import { blackColor, hexToRgb } from "assets/jss/material-dashboard-react.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    width: "100%",
  },
  paper: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    padding: theme.spacing(1),
    textAlign: "center",
    // color: theme.palette.text.secondary,
    // boxShadow: '0px 4px 20px #0123B41A',
    // backgroundColor: 'white',
    // borderRadius: '14px',
    borderRadius: "6px",
    boxShadow: "1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor: "#fcfafa",
    opacity: "1",
  },
  formControl: {
    autosize: true,
    clearable: false,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  select: {
    "&:after": {
      borderBottomColor: "blue",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      backgroundColor: "#0123b4",
      borderRadius: "8px",
    },
    "& .MuiSelect-root ": {
      marginTop: "-2vh",
    },
  },
  headingFont: {
    "@media (min-width:0px) and (max-width:599.95px)": {
      //xs
      textAlign: "center",
      fontSize: "1.5vh",
      color: "black",
    },
    "@media (min-width:600px) and (max-width:959.95px)": {
      //sm
      textAlign: "center",
      fontSize: "1.6vh",
      color: "black",
    },
    "@media (min-width:960px) and (max-width:1279.95px)": {
      //md
      textAlign: "center",
      fontSize: "1.5vh",
      color: "black",
    },
    "@media (min-width:1280px) and (max-width:1919.95px)": {
      //lg
      textAlign: "center",
      fontSize: "1.8vh",
      whiteSpace: "nowrap",
      color: "black",
    },
    "@media (min-width:1920px) and (max-width:2559.95px)": {
      //xl
      textAlign: "center",
      fontSize: "1.8vh",
      color: "black",
      whiteSpace: "nowrap",
    },
  },
  statusFont: {
    "@media (min-width:0px) and (max-width:599.95px)": {
      //xs
      textAlign: "center",
      fontSize: "2.3vh",
      color: "black",
    },
    "@media (min-width:600px) and (max-width:959.95px)": {
      //sm
      textAlign: "center",
      fontSize: "2.5vh",
      color: "black",
    },
    "@media (min-width:960px) and (max-width:1279.95px)": {
      //md
      textAlign: "center",
      fontSize: "2.7vh",
      color: "black",
    },
    "@media (min-width:1280px) and (max-width:1919.95px)": {
      //lg
      textAlign: "center",
      fontSize: "2.5vh",
      whiteSpace: "nowrap",
      color: "black",
    },
    "@media (min-width:1920px) and (max-width:2559.95px)": {
      //xl
      textAlign: "center",
      fontSize: "2.3vh",
      color: "black",
      whiteSpace: "nowrap",
    },
  },
  graphpaper: {
    height: "29vh",
    padding: theme.spacing(1),
    textAlign: "center",
    // color: theme.palette.text.secondary,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // boxShadow: '0px 4px 20px #0123B41A;',
    // backgroundColor: 'white',
    boxShadow: "1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor: "#fcfafa",
    opacity: "1",
    borderRadius: "14px",
  },
}));

const getMyValue = (input, params = []) => {
  let test = -1;
  try {
    if (input !== undefined) test = input[params[0]];
    else return;
    if (test !== undefined) {
      for (let i = 1; i < params.length; i++) {
        if (params[i] !== undefined) test = test[params[i]];
        else break;
      }
    }
  } catch (e) {
    console.log("My Error in getMyValue: ", e.message);
  }
  return test;
};

function GlEnergyMeter(props) {
  let myEMS = "a14c6706-37fe-11ed-80b8-9829a659bba7";
  const classes = useStyles();
  const [present, setPresent] = useState("");
  const [avgAmps, setAvgAmps] = useState("");
  const [active, setActive] = useState("");
  const [avgPf, setAvgPF] = useState("");
  const [kiloWatt, setKiloWatt] = useState("");
  const [kiloWattHr, setkiloWatHr] = useState("");
  const [filteredRooms, setFilteredRooms] = useState([]);
  const [newFreq1, setNewFreq1] = useState("");
  const [current, setCurrent] = useState("");
  const [freq, setFreq] = useState("");
  const [KWGraph, setKiloWattGraph] = useState({});
  const [pfGraph, setPFGraph] = useState([]);
  const [avgLNGrph, setAvgLNGraph] = useState([]);
  const [avgAmpsGraph, setAvgAmpsGraph] = useState([]);
  const [kwhGraph, setKWHGraph] = useState([]);
  const [freqGraph, setFrequencyGraph] = useState([]);
  const [newfreqGraph, setNewFreq] = useState([]);
  const [llphase3, setLLPhase3] = useState([]);
  const [currentphase1, setCurrentPhase1] = useState([]);
  const [currentphase2, setCurrentPhase2] = useState([]);
  const [currentphase3, setCurrentPhase3] = useState([]);
  const [energy24hrs, setEnergy24hrs] = useState({});
  const [energy7days, setEnergy7days] = useState({});
  const [fdata, setFdata] = useState(localStorage.getItem("floorName"));
  const [showAllZones, setShowAllZones] = useState(false);
  const [showAllRooms, setShowAllRooms] = useState(false);
  const [showAlldevices, setShowAllDevices] = useState(false);

  const [floor, setFloor] = useState([]);
  const initialState1 =
    props.location.state.dev_id != null ? props.location.state.dev_id : "";
  const [deviceid, setDeviceid] = useState(initialState1);
  const initialState =
    props.location.state.dev_name != null ? props.location.state.dev_name : "";
  const initialState2 =
    props.location.state.electiRoom != null
      ? props.location.state.electiRoom
      : "";
  const [zdata, setZdata] = useState(initialState2);
  const initialState3 =
    props.location.state.FeedName != null ? props.location.state.FeedName : "";
  const [rdata, setRdata] = useState(initialState3);

  const [data, setData] = useState(initialState);
  const [ahu1, setAhu1] = useState({});
  const [graph, setGraph] = useState([]);
  const [filteredZones, setFilteredZones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [activeEMS, setActiveEMS] = useState(initialState1);
  const prevEMSRef = useRef(initialState1);
  const [energydevice, setEnergydevice] = useState([]);
  const [showZdataDropdown, setShowZdataDropdown] = useState(false);
  const [showRdataDropdown, setShowRdataDropdown] = useState(false);
  const [givenName, setgivenName] = useState("");
  const zone_data = useSelector((state) => state.inDashboard.locationData);
  const buildingID = useSelector((state) => state.isLogged.data.building.id);
  const [floorSelected, setFloorSelected] = useState(false);
  const [showZData, setShowZData] = useState(false);
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

  function calltheApis(id, name) {
    console.log('calltheApis',id,name)
    setLoading(true);
    setgivenName(name);
    setActiveEMS(id);

    // API calls array
    const apiCalls = [
      // First API call: EmsData
      api.floor
        .EmsData(id)
        .then((res) => {
          if (res.length === 0) {
            setPresent(0);
            setAvgAmps(0);
            setActive(0);
            setAvgPF(0);
            setKiloWatt(0);
            setkiloWatHr(0);
            setNewFreq1(0);
            setCurrent(0);
            setFreq(0);
          } else {
            res.forEach((ele) => {
              if (ele.param_id === "Prsnt_Dmd") setPresent(ele.param_value);
              else if (ele.param_id === "Cur_Avg") setAvgAmps(ele.param_value);
              else if (ele.param_id === "Volt_LN_Avg")
                setActive(ele.param_value);
              else if (ele.param_id === "PF_Ttl") setAvgPF(ele.param_value);
              else if (ele.param_id === "Act_Pwr_Ttl")
                setKiloWatt(ele.param_value);
              else if (ele.param_id === "Act_Enrg")
                setkiloWatHr(ele.param_value);
              else if (ele.param_id === "Freq") setNewFreq1(ele.param_value);
              else if (ele.param_id === "Cur_Avg") setCurrent(ele.param_value);
              else if (ele.param_id === "Volt_LL_Avg") setFreq(ele.param_value);
            });
            setKiloWattGraph([]);
            setPFGraph([]);
            setAvgLNGraph([]);
            setAvgAmpsGraph([]);
            setKWHGraph([]);
            setFrequencyGraph([]);
            setNewFreq([]);
            setLLPhase3([]);
            setCurrentPhase1([]);
            setCurrentPhase2([]);
            setCurrentPhase3([]);
            setEnergy24hrs([]);
            setEnergy7days([]);
          }
        })
        .catch(() => {
          setPresent("");
          setAvgAmps("");
          setActive("");
          setAvgPF("");
          setKiloWatt("");
          setkiloWatHr("");
          setNewFreq1("");
          setCurrent("");
          setFreq("");
        }),

      // Second API call: EmsGraphData
      api.floor
        .EmsGraphData(id)
        .then((res) => {
          if (res.graphData.length) {
            if (res.graphData[0]["Act_Pwr_Ttl"])
              setKiloWattGraph(
                getMyValue(res, ["graphData", 0, "Act_Pwr_Ttl"])
              );
            if (res.graphData[0]["PF_Ttl"])
              setPFGraph(getMyValue(res, ["graphData", 0, "PF_Ttl"]));
            if (res.graphData[0]["Volt_LN_Avg"])
              setAvgLNGraph(getMyValue(res, ["graphData", 0, "Volt_LN_Avg"]));
            if (res.graphData[0]["Cur_Avg"])
              setAvgAmpsGraph(getMyValue(res, ["graphData", 0, "Cur_Avg"]));
            if (res.graphData[0]["Act_Enrg"])
              setKWHGraph(getMyValue(res, ["graphData", 0, "Act_Enrg"]));
            if (res.graphData[0]["Volt_LL_Avg"])
              setFrequencyGraph(
                getMyValue(res, ["graphData", 0, "Volt_LL_Avg"])
              );
            if (res.graphData[0]["Freq"])
              setNewFreq(getMyValue(res, ["graphData", 0, "Freq"]));
            if (res.graphData[0]["Volt_LL_Ph3"])
              setLLPhase3(getMyValue(res, ["graphData", 0, "Volt_LL_Ph3"]));
            if (res.graphData[0]["Cur_Ph1"])
              setCurrentPhase1(getMyValue(res, ["graphData", 0, "Cur_Ph1"]));
            if (res.graphData[0]["Cur_Ph2"])
              setCurrentPhase2(getMyValue(res, ["graphData", 0, "Cur_Ph2"]));
            if (res.graphData[0]["Cur_Ph3"])
              setCurrentPhase3(getMyValue(res, ["graphData", 0, "Cur_Ph3"]));
          }
        })
        .catch(() => {
          setKiloWattGraph([]);
          setPFGraph([]);
          setAvgLNGraph([]);
          setAvgAmpsGraph([]);
          setKWHGraph([]);
          setFrequencyGraph([]);
          setNewFreq([]);
          setLLPhase3([]);
          setCurrentPhase1([]);
          setCurrentPhase2([]);
          setCurrentPhase3([]);
        }),

      // Third API call: EmsGraph24hrs
      api.floor
        .EmsGraph24hrs(id, format(new Date(), "yyyy-MM-dd"))
        .then((res) => {
          if (res.graphData.length) setEnergy24hrs(res.graphData[0]);
        })
        .catch(() => setEnergy24hrs({})),

      // Fourth API call: EmsGraph7days
      api.floor
        .EmsGraph7days(id, format(new Date(), "yyyy-MM-dd"))
        .then((res) => {
          if (res.graphData.length) setEnergy7days(res.graphData[0]);
        })
        .catch(() => setEnergy7days({})),
    ];

    Promise.all(apiCalls)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const handlefloorchange = (name, id) => {
    setFdata(name);
    setShowAllZones(true);
    setShowZdataDropdown(true);
    setIsFirstLoad(true);
    setZdata("ALL");
    setRdata("ALL");
    setData("ALL");
    setFilteredZones([]);
    setFilteredRooms([]);
    setEnergydevice([]);
    setKiloWattGraph([]);
    setPFGraph([]);
    setAvgLNGraph([]);
    setAvgAmpsGraph([]);
    setKWHGraph([]);
    setFrequencyGraph([]);
    setNewFreq([]);
    setLLPhase3([]);
    setCurrentPhase1([]);
    setCurrentPhase2([]);
    setCurrentPhase3([]);
    setEnergy24hrs([]);
    setEnergy7days([]);
    api.floor
      .devicemap(id, props.history.location.state.dev_type)
      .then((res) => {
        if (res.length === 0) {
          setEnergydevice([]);
          setPresent(0);
          setAvgAmps(0);
          setActive(0);
          setAvgPF(0);
          setKiloWatt(0);
          setkiloWatHr(0);
          setNewFreq1(0);
          setCurrent(0);
          setFreq(0);
          setFilteredZones([]); // Ensure filteredZones is an empty array
          setZdata(""); // Reset zdata
        } else {
          const matchingFloor = res.find((item) => item.zoneId === id);
          if (matchingFloor) {
            const ssid = matchingFloor.ssid;
            const name = matchingFloor.name;
            calltheApis(ssid, name);
            const filteredRes = res.filter(
              (item) => item.zone_type === "GL_LOCATION_TYPE_ZONE"
            );
            filteredRes.sort((a, b) => a.zone_name.localeCompare(b.zone_name));

            setFilteredZones(filteredRes); // Set the filtered array
            setZdata("ALL"); // Set the first zone name or default to an empty string
            setRdata("ALL");
            setData("ALL");
          }
        }
      })
      .catch((error) => {
        console.error("Error in devicemap API:", error);
        setEnergydevice([]);
        setFilteredZones([]); // Ensure filteredZones is reset on error
      });
  };

  const handlezonechange = (name, id) => {
    setShowAllRooms(true);
    setShowRdataDropdown(true);
    setRdata("ALL");
    setData("ALL");
    setFilteredRooms([]);
    api.floor.devicemap(id, "energyMeter").then((res) => {
      if (res.length === 0) {
        setEnergydevice([]);
        setPresent(0);
        setAvgAmps(0);
        setActive(0);
        setAvgPF(0);
        setKiloWatt(0);
        setkiloWatHr(0);
        setNewFreq1(0);
        setCurrent(0);
        setFreq(0);
        setShowAllRooms([]); // Ensure filteredZones is an empty array
        setRdata(""); // Reset rdata
      } else {
        const matchingFloor = res.find((item) => item.zoneId === id);
        if (matchingFloor) {
          const ssid = matchingFloor.ssid;
          const name = matchingFloor.name;
          calltheApis(ssid, name);
          const filteredRes1 = res.filter(
            (item) => item.zone_type === "GL_LOCATION_TYPE_ROOM"
          );
          const uniqueZones = Array.from(
            new Map(filteredRes1.map((item) => [item.zone_name, item])).values()
          );

          uniqueZones.sort((a, b) => (a.zone_name > b.zone_name ? 1 : -1));

          if (uniqueZones.length > 0) {
            setRdata(uniqueZones[0].zone_name);
          }
          setFilteredRooms(uniqueZones);
          setRdata("ALL");
          setData("ALL");
          res.sort(function (a, b) {
            return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
          });
          setEnergydevice(res);
        }
      }
    });
  };

  const handleroomchange = (name, id) => {
    setShowAllDevices(true);
    setData("ALL");
    api.floor.devicemap(id, "energyMeter").then((res) => {
      if (res.length === 0) {
        setShowAllDevices([]);
      } else {
        const matchingFloor = res.find((item) => item.zoneId === id);
        if (matchingFloor) {
          const ssid = matchingFloor.ssid;
          const name = matchingFloor.name;
          calltheApis(ssid, name);
          setData("ALL");
          const filteredRes = res.filter(
            (item) => item.zone_type === "GL_LOCATION_TYPE_VIRTUAL_ROOM"
          );
          filteredRes.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          );
          setEnergydevice(filteredRes);
        }
      }
    });
  };

  const handleChange = (id, name) => {
    setData(name);
    calltheApis(id, name);
  };

  useEffect(() => {
    const prevEMS = prevEMSRef.current;
    setIsFirstLoad(false);
    if (prevEMS !== activeEMS) {
      console.log("");
    } else {
      let zone_id = "",
        z_data = [];
      zone_data.sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });
      // zone_data.filter((_each)=>_each.zone_type==='GL_LOCATION_TYPE_FLOOR')
      // if(fdata!== null){
      zone_data.filter((_each, i) => {
        if (_each.zone_type === "GL_LOCATION_TYPE_FLOOR") {
          z_data.push(_each);
        }
      });
      zone_id = z_data[0].uuid;
      setFdata(z_data[0].name);
      api.floor
        .devicemap(zone_id, props.history.location.state.dev_type)
        .then((res) => {
          const filteredRes1 = res.filter(
            (item) => item.zone_type === "GL_LOCATION_TYPE_ROOM"
          );
          setFilteredRooms(filteredRes1);
          const filteredRes2 = res.filter(
            (item) => item.zone_type === "GL_LOCATION_TYPE_ZONE"
          );
          setFilteredZones(filteredRes2);
          res.sort(function (a, b) {
            return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
          });
          setEnergydevice(res);
          const matchingDevice = res.find((item) => item.ssid === prevEMS);
          const ssidToUse = matchingDevice ? matchingDevice.ssid : res[0]?.ssid;
          if (ssidToUse) {
            api.floor
              .EmsData(ssidToUse)
              .then((res) => {
                if (res.length === 0) {
                  setPresent(0);
                  setAvgAmps(0);
                  setActive(0);
                  setAvgPF(0);
                  setKiloWatt(0);
                  setkiloWatHr(0);
                  setNewFreq1(0);
                  setCurrent(0);
                  setFreq(0);
                } else {
                  res.map((ele) => {
                    if (ele.param_id === "Prsnt_Dmd") {
                      setPresent(ele.param_value);
                    } else if (ele.param_id === "Cur_Avg") {
                      setAvgAmps(ele.param_value);
                    } else if (ele.param_id === "Volt_LN_Avg") {
                      setActive(ele.param_value);
                    } else if (ele.param_id === "PF_Ttl") {
                      setAvgPF(ele.param_value);
                    } else if (ele.param_id === "Act_Pwr_Ttl") {
                      setKiloWatt(ele.param_value);
                    } else if (ele.param_id === "Act_Enrg") {
                      setkiloWatHr(ele.param_value);
                    } else if (ele.param_id === "Freq") {
                      setNewFreq1(ele.param_value);
                    } else if (ele.param_id === "Cur_Avg") {
                      setCurrent(ele.param_value);
                    } else if (ele.param_id === "Volt_LL_Avg") {
                      setFreq(ele.param_value);
                    }
                  });
                }
              })
              .catch((error) => {
                setPresent("");
                setAvgAmps("");
                setActive("");
                setAvgPF("");
                setKiloWatt("");
                setkiloWatHr("");
                setNewFreq1("");
                setCurrent("");
                setFreq("");
              });
            api.floor
              .EmsGraphData(ssidToUse)
              .then((res) => {
                if (res.graphData.length) {
                  if (res.graphData[0]["Act_Pwr_Ttl"]) {
                    setKiloWattGraph(
                      getMyValue(res, ["graphData", 0, "Act_Pwr_Ttl"])
                    );
                  }
                  if (res.graphData[0]["PF_Ttl"]) {
                    setPFGraph(getMyValue(res, ["graphData", 0, "PF_Ttl"]));
                  }
                  if (res.graphData[0]["Volt_LN_Avg"]) {
                    setAvgLNGraph(
                      getMyValue(res, ["graphData", 0, "Volt_LN_Avg"])
                    );
                  }
                  if (res.graphData[0]["Cur_Avg"]) {
                    setAvgAmpsGraph(
                      getMyValue(res, ["graphData", 0, "Cur_Avg"])
                    );
                  }
                  if (res.graphData[0]["Act_Enrg"]) {
                    setKWHGraph(getMyValue(res, ["graphData", 0, "Act_Enrg"]));
                  }
                  if (res.graphData[0]["Volt_LL_Avg"]) {
                    setFrequencyGraph(
                      getMyValue(res, ["graphData", 0, "Volt_LL_Avg"])
                    );
                  }
                  if (res.graphData[0]["Freq"]) {
                    setNewFreq(getMyValue(res, ["graphData", 0, "Freq"]));
                  }
                  if (res.graphData[0]["Volt_LL_Ph3"]) {
                    setLLPhase3(
                      getMyValue(res, ["graphData", 0, "Volt_LL_Ph3"])
                    );
                  }
                  if (res.graphData[0]["Cur_Ph1"]) {
                    setCurrentPhase1(
                      getMyValue(res, ["graphData", 0, "Cur_Ph1"])
                    );
                  }
                  if (res.graphData[0]["Cur_Ph2"]) {
                    setCurrentPhase2(
                      getMyValue(res, ["graphData", 0, "Cur_Ph2"])
                    );
                  }
                  if (res.graphData[0]["Cur_Ph3"]) {
                    setCurrentPhase3(
                      getMyValue(res, ["graphData", 0, "Cur_Ph3"])
                    );
                  }
                }
              })
              .catch((error) => {
                setKiloWattGraph([]);
                setPFGraph([]);
                setAvgLNGraph([]);
                setAvgAmpsGraph([]);
                setKWHGraph([]);
                setFrequencyGraph([]);
                setNewFreq([]);
                setLLPhase3([]);
                setCurrentPhase1([]);
                setCurrentPhase2([]);
                setCurrentPhase3([]);
              });

            api.floor
              .EmsGraph24hrs(ssidToUse, format(new Date(), "yyyy-MM-dd"))
              .then((res) => {
                if (res.graphData.length) {
                  setEnergy24hrs(res.graphData[0]);
                }
              })
              .catch((error) => {
                setEnergy24hrs({});
              });

            api.floor
              .EmsGraph7days(ssidToUse, format(new Date(), "yyyy-MM-dd"))
              .then((res) => {
                if (res.graphData.length) {
                  setEnergy7days(res.graphData[0]);
                }
              })
              .catch((error) => {
                setEnergy7days({});
              });
          }
        })
        .catch((error) => {
          setEnergydevice([]);
          setData("");
          setActiveEMS("");
        });
    }
    prevEMSRef.current = activeEMS;
    api.dashboard
      .getMetricData(buildingID)
      .then((res) => {
        res.sort(function (a, b) {
          return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
        });
        setFloor(res);
      })
      .catch((error) => {
        setFloor([]);
      });

    const timer = setInterval(() => {
      api.floor
        .EmsData(activeEMS)
        .then((res) => {
          if (res.length === 0) {
            setPresent(0);
            setAvgAmps(0);
            setActive(0);
            setAvgPF(0);
            setKiloWatt(0);
            setkiloWatHr(0);
            setNewFreq1(0);
            setCurrent(0);
            setFreq(0);
          } else {
            res.map((ele) => {
              if (ele.param_id === "Prsnt_Dmd") {
                setPresent(ele.param_value);
              } else if (ele.param_id === "Cur_Avg") {
                setAvgAmps(ele.param_value);
              } else if (ele.param_id === "Volt_LN_Avg") {
                setActive(ele.param_value);
              } else if (ele.param_id === "PF_Ttl") {
                setAvgPF(ele.param_value);
              } else if (ele.param_id === "Act_Pwr_Ttl") {
                setKiloWatt(ele.param_value);
              } else if (ele.param_id === "Act_Enrg") {
                setkiloWatHr(ele.param_value);
              } else if (ele.param_id === "Freq") {
                setNewFreq1(ele.param_value);
              } else if (ele.param_id === "Cur_Avg") {
                setCurrent(ele.param_value);
              } else if (ele.param_id === "Volt_LL_Avg") {
                setFreq(ele.param_value);
              }
            });
          }
        })
        .catch((error) => {
          setPresent("");
          setAvgAmps("");
          setActive("");
          setAvgPF("");
          setKiloWatt("");
          setkiloWatHr("");
          setNewFreq1("");
          setCurrent("");
          setFreq("");
        });
      api.floor
        .EmsGraphData(activeEMS)
        .then((res) => {
          if (res.graphData.length) {
            if (res.graphData[0]["Act_Pwr_Ttl"]) {
              setKiloWattGraph(
                getMyValue(res, ["graphData", 0, "Act_Pwr_Ttl"])
              );
            }
            if (res.graphData[0]["PF_Ttl"]) {
              setPFGraph(getMyValue(res, ["graphData", 0, "PF_Ttl"]));
            }
            if (res.graphData[0]["Volt_LN_Avg"]) {
              setAvgLNGraph(getMyValue(res, ["graphData", 0, "Volt_LN_Avg"]));
            }
            if (res.graphData[0]["Cur_Avg"]) {
              setAvgAmpsGraph(getMyValue(res, ["graphData", 0, "Cur_Avg"]));
            }
            if (res.graphData[0]["Act_Enrg"]) {
              setKWHGraph(getMyValue(res, ["graphData", 0, "Act_Enrg"]));
            }
            if (res.graphData[0]["Volt_LL_Avg"]) {
              setFrequencyGraph(
                getMyValue(res, ["graphData", 0, "Volt_LL_Avg"])
              );
            }
            if (res.graphData[0]["Freq"]) {
              setNewFreq(getMyValue(res, ["graphData", 0, "Freq"]));
            }
            if (res.graphData[0]["Volt_LL_Ph3"]) {
              setLLPhase3(getMyValue(res, ["graphData", 0, "Volt_LL_Ph3"]));
            }
            if (res.graphData[0]["Cur_Ph1"]) {
              setCurrentPhase1(getMyValue(res, ["graphData", 0, "Cur_Ph1"]));
            }
            if (res.graphData[0]["Cur_Ph2"]) {
              setCurrentPhase2(getMyValue(res, ["graphData", 0, "Cur_Ph2"]));
            }
            if (res.graphData[0]["Cur_Ph3"]) {
              setCurrentPhase3(getMyValue(res, ["graphData", 0, "Cur_Ph3"]));
            }
          }
        })
        .catch((error) => {
          setKiloWattGraph([]);
          setPFGraph([]);
          setAvgLNGraph([]);
          setAvgAmpsGraph([]);
          setKWHGraph([]);
          setFrequencyGraph([]);
          setNewFreq([]);
          setLLPhase3([]);
          setCurrentPhase1([]);
          setCurrentPhase2([]);
          setCurrentPhase3([]);
        });

      api.floor
        .EmsGraph24hrs(activeEMS, format(new Date(), "yyyy-MM-dd"))
        .then((res) => {
          if (res.graphData.length) {
            setEnergy24hrs(res.graphData[0]);
          }
        })
        .catch((error) => {
          setEnergy24hrs({});
        });

      api.floor
        .EmsGraph7days(activeEMS, format(new Date(), "yyyy-MM-dd"))
        .then((res) => {
          if (res.graphData.length) {
            setEnergy7days(res.graphData[0]);
          }
        })
        .catch((error) => {
          setEnergy7days({});
        });
    }, 960000);
    return () => {
      clearInterval(timer);
    };
  }, [activeEMS]);

  return (
    <>
      <Spin
        spinning={loading}
        size="default"
        style={{
          justifyContent: "center",
          alignContent: "center",
          position: "fixed",
        }}
      >
        <div className={classes.root} style={{ marginTop: "0%" }}>
          <Grid container xs={12} spacing={2}>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                <FormControl
                  variant="filled"
                  size="large"
                  className={classes.formControl}
                  style={{
                    width: "max-content",
                    minWidth: "100%",
                    backgroundColor: "#eeeef5",
                    fontFamily: "Arial",
                  }}
                >
                  <Select
                    labelId="filled-hidden-label-small"
                    id="demo-simple-select-outlined"
                    label="Floor"
                    value={fdata || ""}
                    disableUnderline
                    onChange={(e) =>
                      handlefloorchange(e.target.value, e.target.id)
                    }
                    style={{
                      fontWeight: "bold",
                      height: "6vh",
                      borderRadius: "0.8vw",
                      fontFamily: "Arial",
                    }}
                    className={classes.select}
                  >
                    {floor.map((_item) => (
                      <MenuItem
                        key={_item.id}
                        value={_item.name}
                        onClick={() => handlefloorchange(_item.name, _item.id)}
                      >
                        {_item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {(props.location.state?.electiRoom &&
                props.location.state.electiRoom.trim() !== "") ||
              (showZdataDropdown && filteredZones.length > 0) ? (
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                  <FormControl
                    variant="filled"
                    size="large"
                    className={classes.formControl}
                    style={{
                      width: "max-content",
                      minWidth: "100%",
                      backgroundColor: "#eeeef5",
                      fontFamily: "Arial",
                    }}
                  >
                    <Select
                      labelId="filled-hidden-label-zone"
                      id="zone-select"
                      label="Zone"
                      value={zdata || "ALL"}
                      onChange={(e) => {
                        const selectedZone = filteredZones.find(
                          (item) => item.zone_name === e.target.value
                        );
                        if (selectedZone) {
                          setZdata(selectedZone.zone_name);
                          handlezonechange(
                            selectedZone.zone_name,
                            selectedZone.zoneId
                          );
                        }
                      }}
                      style={{
                        fontWeight: "bold",
                        height: "6vh",
                        borderRadius: "0.8vw",
                        fontFamily: "Arial",
                      }}
                      className={classes.select}
                      disableUnderline
                    >
                      <MenuItem key="ALL" value="ALL" disabled>
                        All Rooms
                      </MenuItem>
                      {filteredZones.map((_item) => (
                        <MenuItem
                          key={_item.zone_name}
                          value={_item.zone_name}
                          onClick={() => {
                            setZdata(_item.zone_name);
                            handlezonechange(_item.zone_name, _item.zoneId);
                          }}
                        >
                          {_item.zone_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}

              {(props.location.state?.FeedName &&
                props.location.state.FeedName.trim() !== "") ||
              (showRdataDropdown && filteredRooms.length > 0) ? (
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                  <FormControl
                    variant="filled"
                    size="large"
                    className={classes.formControl}
                    style={{
                      width: "max-content",
                      minWidth: "100%",
                      backgroundColor: "#eeeef5",
                      fontFamily: "Arial",
                    }}
                  >
                    <Select
                      labelId="filled-hidden-label-small"
                      id="demo-simple-select-outlined"
                      label="Room"
                      value={rdata || "ALL"}
                      onChange={(e) => {
                        const selectedRoom = filteredRooms.find(
                          (item) => item.zone_name === e.target.value
                        );
                        if (selectedRoom) {
                          setRdata(selectedRoom.zone_name);
                          handleroomchange(
                            selectedRoom.zone_name,
                            selectedRoom.zoneId
                          );
                        }
                      }}
                      style={{
                        fontWeight: "bold",
                        height: "6vh",
                        borderRadius: "0.8vw",
                        fontFamily: "Arial",
                      }}
                      className={classes.select}
                      disableUnderline
                    >
                      <MenuItem key="ALL" value="ALL" disabled>
                        All Feeder
                      </MenuItem>
                      {!showAllRooms ? (
                        <MenuItem key={rdata} value={rdata}>
                          {rdata}
                        </MenuItem>
                      ) : (
                        filteredRooms.map((_item) => (
                          <MenuItem
                            key={_item.zone_name}
                            value={_item.zone_name}
                            onClick={() => {
                              setRdata(_item.zone_name);
                              handleroomchange(_item.zone_name, _item.zoneId);
                            }}
                          >
                            {_item.zone_name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}

              <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                <FormControl
                  variant="filled"
                  size="large"
                  className={classes.formControl}
                  style={{
                    width: "max-content",
                    minWidth: "100%",
                    backgroundColor: "#eeeef5",
                    fontFamily: "Arial",
                  }}
                >
                  <Select
                    labelId="filled-hidden-label-small"
                    id="demo-simple-select-outlined"
                    label="EnergyMeter"
                    value={data || "ALL"}
                    style={{
                      fontWeight: "bold",
                      height: "6vh",
                      borderRadius: "0.8vw",
                      fontFamily: "Arial",
                    }}
                    className={classes.select}
                    disableUnderline
                  >
                    <MenuItem key="ALL" value="ALL" disabled>
                      All EnergyMeters
                    </MenuItem>
                    {!showAlldevices ? (
                      <MenuItem key={data} value={data}>
                        {data}
                      </MenuItem>
                    ) : (
                      energydevice.map((_item) => (
                        <MenuItem
                          key={_item.ssid}
                          value={_item.name}
                          onClick={() => handleChange(_item.ssid, _item.name)}
                        >
                          {_item.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={2}>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Card className={`${classes.paper}`}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={6} sm={4} md lg xl xxl>
                      <Card
                        style={{
                          boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",
                          backgroundColor: "#fcfafa",
                          "border-radius": "10px",
                          height: "12vh",
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          direction="column"
                          style={{
                            "justify-content": "center",
                            "align-content": "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={1}
                            direction="column"
                            alignItems="center"
                          >
                            <Grid
                              item
                              xs={1}
                              sm={1}
                              md={1}
                              lg={1}
                              xl={1}
                              xxl={1}
                            >
                              {" "}
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              className={classes.headingFont}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "1vh",
                              }}
                            >
                              Average L_L [V]
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              className={classes.statusFont}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Success>{formatter.format(freq)}</Success>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={6} sm={4} md lg xl xxl>
                      <Card
                        style={{
                          boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",
                          backgroundColor: "#fcfafa",
                          "border-radius": "10px",
                          height: "12vh",
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          direction="column"
                          style={{
                            justifyContent: "center",
                            alignContent: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={1}
                            direction="column"
                            alignItems="center"
                          >
                            <Grid
                              item
                              xs={1}
                              sm={1}
                              md={1}
                              lg={1}
                              xl={1}
                              xxl={1}
                            >
                              {" "}
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              className={classes.headingFont}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "1vh",
                              }}
                            >
                              Average Current [A]
                            </Grid>
                            <Grid container item xs={12}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                className={classes.statusFont}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Success>{formatter.format(avgAmps)}A</Success>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={6} sm={4} md lg xl xxl>
                      <Card
                        style={{
                          boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",
                          backgroundColor: "#fcfafa",
                          "border-radius": "10px",
                          height: "12vh",
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          direction="column"
                          style={{
                            "justify-content": "center",
                            "align-content": "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={1}
                            direction="column"
                            alignItems="center"
                            className={classes.headingFont}
                          >
                            <Grid
                              item
                              xs={1}
                              sm={1}
                              md={1}
                              lg={1}
                              xl={1}
                              xxl={1}
                            >
                              {" "}
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "1vh",
                              }}
                            >
                              Average L_N [V]
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                            >
                              <Grid container item xs={12}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  xxl={12}
                                  className={classes.statusFont}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Success>{formatter.format(active)}</Success>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={6} sm={4} md lg xl xxl>
                      <Card
                        style={{
                          boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",
                          backgroundColor: "#fcfafa",
                          "border-radius": "10px",
                          height: "12vh",
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          direction="column"
                          style={{
                            "justify-content": "center",
                            "align-content": "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={1}
                            direction="column"
                            alignItems="center"
                            className={classes.headingFont}
                          >
                            <Grid
                              item
                              xs={1}
                              sm={1}
                              md={1}
                              lg={1}
                              xl={1}
                              xxl={1}
                            >
                              {" "}
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "1vh",
                              }}
                            >
                              Average PF
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              className={classes.statusFont}
                            >
                              <Success>{formatter.format(avgPf)}</Success>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={6} sm={4} md lg xl xxl>
                      <Card
                        style={{
                          boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",
                          backgroundColor: "#fcfafa",
                          "border-radius": "10px",
                          height: "12vh",
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          direction="column"
                          style={{
                            "justify-content": "center",
                            "align-content": "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={1}
                            direction="column"
                            alignItems="center"
                            className={classes.headingFont}
                          >
                            <Grid
                              item
                              xs={1}
                              sm={1}
                              md={1}
                              lg={1}
                              xl={1}
                              xxl={1}
                            >
                              {" "}
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "1vh",
                              }}
                            >
                              Active Power [kW]
                            </Grid>
                            <Grid container item xs={12}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                className={classes.statusFont}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Success>{formatter.format(kiloWatt)}</Success>
                                {/* <Grid item xs={12}>
                                              <Grid container item xs={12}>
                                                <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} className={classes.statusFont}>
                                                  <Success>{formatter.format(kiloWatt)}</Success>
                                                </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}> </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ marginTop: "1.5vh",fontSize:"2vh",color:"forestgreen"}}>
                                                  <>V</>
                                                </Grid>
                                              </Grid>
                                          </Grid> */}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={6} sm={4} md lg xl xxl>
                      <Card
                        style={{
                          boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",
                          backgroundColor: "#fcfafa",
                          "border-radius": "10px",
                          height: "12vh",
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          direction="column"
                          style={{
                            "justify-content": "center",
                            "align-content": "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={1}
                            direction="column"
                            alignItems="center"
                            className={classes.headingFont}
                          >
                            <Grid
                              item
                              xs={1}
                              sm={1}
                              md={1}
                              lg={1}
                              xl={1}
                              xxl={1}
                            >
                              {" "}
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "1vh",
                              }}
                            >
                              Active Energy [kWh]
                            </Grid>
                            <Grid container item xs={12}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                className={classes.statusFont}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Success>
                                  {formatter.format(kiloWattHr)}
                                </Success>
                                {/* <Grid item xs={12}>
                                              <Grid container item xs={12}>
                                                <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} className={classes.statusFont}>
                                                  <Success>{formatter.format(kiloWattHr)}</Success>
                                                </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}> </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ marginTop: "1.5vh",fontSize:"2vh",color:"forestgreen"}}>
                                                  <>V</>
                                                </Grid>
                                              </Grid>
                                          </Grid> */}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={6} sm={4} md lg xl xxl>
                      <Card
                        style={{
                          boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",
                          backgroundColor: "#fcfafa",
                          "border-radius": "10px",
                          height: "12vh",
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          direction="column"
                          style={{
                            "justify-content": "center",
                            "align-content": "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={1}
                            direction="column"
                            alignItems="center"
                            className={classes.headingFont}
                          >
                            <Grid
                              item
                              xs={1}
                              sm={1}
                              md={1}
                              lg={1}
                              xl={1}
                              xxl={1}
                            >
                              {" "}
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              sm={5}
                              md={5}
                              lg={5}
                              xl={5}
                              xxl={5}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "1vh",
                              }}
                            >
                              Frequency [Hz]
                            </Grid>
                            <Grid container item xs={12}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                className={classes.statusFont}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Success>{formatter.format(newFreq1)}</Success>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>

                    {/* <Grid item xs={6} sm={4} md lg xl xxl>
                  <Card style={{ boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)", backgroundColor: "#fcfafa", "border-radius": "10px", height: "12vh" }}>
                    <Grid container item xs={12} direction='column' style={{ "justify-content": "center", "align-content": "center", whiteSpace: "nowrap" }} >
                      <Grid container item xs={12} spacing={1} direction='column' alignItems='center' className={classes.headingFont}>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}> </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5} style={{ display: 'flex', justifyContent: 'center' }}>Frequency</Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.statusFont} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Success>{formatter.format(freq)}Hz</Success>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid> */}
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={2} style={{ marginTop: "0.5vh" }}>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                    <Card className={classes.graphpaper}>
                      <Grid container item xs={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          style={{
                            fontSize: "15px",
                            color: "black",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <b>Average L_L [V]</b>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                        >
                          {Object.keys(freqGraph).length == 0 ? (
                            <>
                              {/* <h4 style={{marginTop:"62px",marginLeft:"80px"}}>No data available</h4> */}
                              <h4
                                style={{ marginTop: "44px", marginLeft: "5px" }}
                              >
                                No data available
                              </h4>
                            </>
                          ) : (
                            <>
                              <TimeSeries
                                data={freqGraph}
                                via ='EM'
                                param="Average L_L [V]"
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                    <Card className={classes.graphpaper}>
                      <Grid container item xs={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          style={{
                            fontSize: "15px",
                            color: "black",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <b>Average L_N [V]</b>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                        >
                          {Object.keys(avgLNGrph).length == 0 ? (
                            <>
                              {/* <h4 style={{marginTop:"62px",marginLeft:"80px"}}>No data available</h4> */}
                              <h4
                                style={{ marginTop: "44px", marginLeft: "5px" }}
                              >
                                No data available
                              </h4>
                            </>
                          ) : (
                            <>
                              <TimeSeries
                                data={avgLNGrph}
                                via ='EM'
                                param="Average L_N [V]"
                              />
                            </>
                          )}{" "}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                    <Card className={classes.graphpaper}>
                      <Grid container item xs={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          style={{
                            fontSize: "15px",
                            color: "black",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <b>Average Current [A]</b>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                        >
                          {Object.keys(avgAmpsGraph).length == 0 ? (
                            <>
                              <h4
                                style={{ marginTop: "44px", marginLeft: "5px" }}
                              >
                                No data available
                              </h4>
                            </>
                          ) : (
                            <>
                              <TimeSeries
                                data={avgAmpsGraph}
                                via ='EM'
                                param="Average Current [A]"
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                    <Card className={classes.graphpaper}>
                      <Grid container item xs={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          style={{
                            fontSize: "15px",
                            color: "black",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <b>Active Power [kW]</b>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                        >
                          {Object.keys(KWGraph).length == 0 ? (
                            <>
                              <h4
                                style={{ marginTop: "44px", marginLeft: "5px" }}
                              >
                                No data available
                              </h4>
                            </>
                          ) : (
                            <>
                              <TimeSeries
                                data={KWGraph}
                                via ='EM'
                                param="Active Power [kW]"
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                    <Card className={classes.graphpaper}>
                      <Grid container item xs={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          style={{
                            fontSize: "15px",
                            color: "black",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <b>24 Hour Consumption [kWh]</b>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                        >
                          {Object.keys(energy24hrs).length == 0 ? (
                            <>
                              <h4
                                style={{ marginTop: "44px", marginLeft: "5px" }}
                              >
                                No data available
                              </h4>
                            </>
                          ) : (
                            <>
                              {Object.keys(energy24hrs).map((key) => (
                                <DeviceTrend24hrs
                                  data={energy24hrs[key]}
                                  via ='EM'
                                  param="24 Hour Consumption [kWh]"
                                />
                              ))}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                    <Card className={classes.graphpaper}>
                      <Grid container item xs={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                          style={{
                            fontSize: "15px",
                            color: "black",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <b>7 Days Consumption [kWh]</b>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                        >
                          {Object.keys(energy7days).length == 0 ? (
                            <>
                              <h4
                                style={{ marginTop: "44px", marginLeft: "5px" }}
                              >
                                No data available
                              </h4>
                            </>
                          ) : (
                            <>
                              {Object.keys(energy7days).map((key) => (
                                <Devicetrend
                                  data={energy7days[key]}
                                  via ='EM'
                                  param="7 Days Consumption [kWh]"
                                />
                              ))}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Spin>
      {loading === true ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default GlEnergyMeter;
