import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Box,Card, TextField, } from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from '@material-ui/core/Typography';
import { Map, ImageOverlay, Marker, Tooltip, ZoomControl } from 'react-leaflet';
// import ChillerImg from './../../assets/img/ChillerImg.png'
import ChillerImg from './../../assets/img/Chiller.png'
import TimeSeriesUpsStatic from "./../TimeSeriesUpsStatic";
import SwitchSelector from "react-switch-selector";
import { CalendarToday } from "@material-ui/icons";
import { ButtonBase } from "@material-ui/core";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import ProgressBar from "@ramonak/react-progress-bar";
import api from './../../api'
import TimeS from './../TimeS'
import TimeSeriesChart from './../TimeSeriesVav';
import Alert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";
import { greenColor, grayColor, redColor} from "assets/jss/material-dashboard-react";
import { SemanticToastContainer, toast } from 'react-semantic-toasts'

const Leaflet = require('leaflet');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  semicircularbarcomp:{
    '@media (min-width:0px) and (max-width:599.95px)': {//xs
        marginTop:'-0.5vh',
        marginLeft:'-1.5vh'
      },
      '@media (min-width:600px) and (max-width:959.95px)': {//sm
        marginTop:'1vh',
        marginLeft:'-0.1vh'
      },
      '@media (min-width:960px) and (max-width:1279.95px)': {//md
        marginTop:'-1vh',
        marginLeft:'-1.1vh'
      },
      '@media (min-width:1280px) and (max-width:1919.95px)': {//lg
        marginTop:'0vh',
        marginLeft:'-0.1vh'
      },
      '@media (min-width:1920px) and (max-width:2559.95px)': {//xl
        marginTop:'1vh',
        marginLeft:'0.5vh'
      },
  },
  paper: {
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    // boxShadow: '0px 4px 20px #0123B41A',
    // backgroundColor: 'white',
    // borderRadius: '14px',
    borderRadius:"9px",
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#fcfafa",
    height: '50vh',
    marginTop:"1vh",
    opacity:'1'
  },
  paper1: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  controls_paper: {
    // padding: theme.spacing(1),
    borderRadius: "37px",
    color: "white",
    display: 'flex',
    textAlign: "center",
    alignItems: 'center',
    justify: 'center',
    height: '3.5vh',
    backgroundColor: 'lightgrey',
    width: "10vh",
    fontSize: "1.8vh"
  },
  faults_paper: {
    // padding: theme.spacing(1),
    borderRadius: "37px",
    color: "white",
    display: 'flex',
    textAlign: "center",
    alignItems: 'center',
    justify: 'center',
    height: '2vh',
    backgroundColor: 'lightgrey',
    [theme.breakpoints.down('sm')]: {
      width: "7.5vh"
    },
    [theme.breakpoints.up('md')]: {
      width: "6vh"
    },
    [theme.breakpoints.up('lg')]: {
      width: "7.5vh"
    },
    [theme.breakpoints.up('xl')]: {
      width: "7.5vh"
    },
  },
  set_button: {
    marginLeft: "-0.7vh",
    fontFamily: "Arial",
    [theme.breakpoints.down('sm')]: {
      // marginLeft:'0.5vh',
      marginLeft: '-1.2vh',
      width: '3vh'
    },
    [theme.breakpoints.up('md')]: {
      width: '3vh',
      marginLeft: '-1vh'
    },
    [theme.breakpoints.up('lg')]: {
      width: '3.5vh'
    },
    [theme.breakpoints.up('xl')]: {
      width: '3.5vh'
    },
  },
  select: {
    "&:after": {
      borderBottomColor: "blue",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      backgroundColor:"#0123b4",borderRadius:"8px"
    },
    "& .MuiSelect-root ": {
      marginTop:"-2vh"
    }
  },
  switchselector:{
    height:'3.5vh',
    [theme.breakpoints.down('sm')]: {
      width:'8.5vh'
    },
    [theme.breakpoints.up('md')]: {
      width:'13vh'
    },
    [theme.breakpoints.up('lg')]: {
      width:'10.5vh'
    },
    [theme.breakpoints.up('xl')]: {
      width:'10.5vh'
    },
  },
  semicircularbar:{
    height:"12vh",
    borderRadius:"6px",
    background:"#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#fcfafa",
    // boxShadow: '0px 4px 20px #0123B41A;',
    // borderRadius: '14px',
    opacity:"1",
  },
  graphpaper: {
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius:"6px",
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#fcfafa",
    height: "27vh",
    textAlign: 'center',
    color: theme.palette.text.secondary,
    // boxShadow: '0px 4px 20px #0123B41A',
    // borderRadius: '14px',
  },
  // controlcard: {
  //   background:'#FFFFFF 0% 0% no-repeat padding-box',
  //   height: "30.5vh",
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  //   boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
  //   backgroundColor:"#fcfafa",
  //   // boxShadow: '0px 4px 20px #0123B41A',
  //   borderRadius: '6px',
  // },
  graphpaper: {
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius:"6px",
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#fcfafa",
    height: "28vh",
    textAlign: 'center',
    color: theme.palette.text.secondary,
    // boxShadow: '0px 4px 20px #0123B41A',
    // borderRadius: '14px',
  },
  text: {
    fontSize: "14px",
    color: " #292929",
    fontFamily: "Arial"
  },
  control1: {
    width: "6vh",
    marginTop: "0vh",
    marginLeft: "-11vh",
    fontFamily: "Arial"
  },
  formControl: {
    autosize: true,
    clearable: false,
  },
  paper1: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  CardHeadFont:{
    '@media (min-width:0px) and (max-width:599.95px)': {//xs
      fontSize: "1.5vh"
      },
      '@media (min-width:600px) and (max-width:959.95px)': {//sm
        fontSize: "1.9vh",
        borderRadius:'10px',
      },
      '@media (min-width:960px) and (max-width:1279.95px)': {//md
        fontSize: "1.7vh",
      },
      '@media (min-width:1280px) and (max-width:1919.95px)': {//lg
        fontSize: "1.8vh",
      },
      '@media (min-width:1920px) and (max-width:2559.95px)': {//xl
        fontSize: "1.7vh",
      },
  },
}));

export default function GlChillerPage(props) {
  // console.log("props in glchillerpage",props.location.state)
  const classes = useStyles();
  const mapRef = React.createRef()
  const [roleId, setRoleId] = React.useState(props.role_id);
  const [eqpType, setEqpType] = useState(props.eqpType);
  const [imageParams, setImageParams] = React.useState(props.imageParams);
  const [controlsCard, setControlsCard] = React.useState(props.controlsCard);
  const [paramsCard, setParamsCard] = React.useState(props.paramsCard);
  const [graphsCard, setgraphsCard] = useState(props.graphsCard);
  const [deviceImage, setDeviceImage] = useState(false);
  const [allEquipmentData, setAllEquipmentData] = React.useState([]);
  const [particularEquipDataSet1, setParticularEquipDataSet1] = useState([]);
  const [particularEquipDataSet2, setParticularEquipDataSet2] = useState([]);
  const [eqpGraphList, setEqpGraphList] = useState({});
  const [particularEquipDataSet3, setParticularEquipDataSet3] = useState([]);
  const [chillerOnOff, setChillerOnOff] = React.useState(0)
  const [selectedChId, setSelectedChId] = useState(props.initialState1);
  const selectedChIdRef = useRef(selectedChId);
  const [selectedChName, setSelectedChName] = useState(props.initialState);
  const [openerr,setOpenerr] = React.useState(false);
  const [errmsg,setErrmsg] = React.useState('');
  const [chillerAlarm,setChillerAlarm] = React.useState([]);
  const [selectedChAlarm, setSelectedChAlarm] = useState('DP high and SP normal');
  const [disable, setDisable] = useState(false);
  const CPM_Status = localStorage.getItem("CPM_AM_Status");
  const iconDevice1 = new Leaflet.Icon({
    iconUrl: require("../../assets/img/sensor-icon.png"),
    iconSize: new Leaflet.Point(0, 0),
    className: "leaflet-div-icon-2",
  });
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const options = [
    {
      selectedFontColor: "white",
      label: "OFF",
      value: 0,
      selectedBackgroundColor: "red",
    },
    {
      selectedFontColor: "white",
      label: "ON",
      value: 1,
      selectedBackgroundColor: "green",
    },
    //  {
    //   selectedFontColor: "white",
    //   label: "AUTO",
    //   value: 2,
    //   selectedBackgroundColor: "#0123B4",
    // }
  ];

  const handleLocationClick = (name) => {
    props.history.push(`/admin/Glschedule`);
  };

  const handleChillerChange = (name, id) => {
    console.log("idddd HCC",id,name)
    setSelectedChId(id)
    setSelectedChName(name)
    Object.values(allEquipmentData).map((res)=>{
      if( id === res.id )
      {        
        let eqpParams1 = []
        imageParams.map((respp) => {
            if (res.Eqp_Attributes[respp.parameter] != undefined){
               eqpParams1.push({
                 ...respp,
                 value: (!isNaN(parseFloat(res.Eqp_Attributes[respp.parameter].presentValue)))? (Math.round(res.Eqp_Attributes[respp.parameter].presentValue)) : res.Eqp_Attributes[respp.parameter].presentValue // Add value here
               })
             }
           }) 
        setParticularEquipDataSet1(eqpParams1)
        setDeviceImage(true)

        let eqpParams2 = []
        controlsCard.map((respp) => {
         if (res.Eqp_Attributes[respp.parameter] != undefined){
            eqpParams2.push({
              ...respp,
              value: res.Eqp_Attributes[respp.parameter].presentValue // Add value here
            })
          }
        })
        setParticularEquipDataSet2(eqpParams2)

        let eqpParams3 = []
        paramsCard.map((respp) => {
         if (res.Eqp_Attributes[respp.parameter] != undefined){
            eqpParams3.push({
              ...respp,
              value: res.Eqp_Metrics[respp.parameter]
            })
          }
        })
        setParticularEquipDataSet3(eqpParams3)

        if(id){
          console.log("idddddddddddd",id)
        api.floor.chillerGraphDataLast1Hr(id).then((response)=>{
          setEqpGraphList(response.graphData[0]);
        }).catch((error)=>{
        //   setOpenerr(true)
          if(error.response.data.message){
            setErrmsg(error.response.data.message)
            }else{
              setErrmsg('')
            }
        })
      }
      }
    })
   
  }

  const handleerrorclose = () => {
    setOpenerr(false);
    setErrmsg('');
  };

  const handleChillerAlarmChange = (message, id) => {
    let data ={'id' : id,'message': message}
    setSelectedChAlarm(data)
  }

  const handleSubmitAlarmClick = (data) => {
    // let ss_id = selectedChId, message = data.message
    const req = {
      ss_id : selectedChId,
      message: data.message
    }
    if(selectedChId && data.message){
        setDisable(true)
        setTimeout(() => {     setDisable(false)    }, 5000);
      
        api.floor.insertSelectedChillerAlarm(req).then((res)=>{
          // console.log("api resp",res)
          // setOpenerr(true)
          toast({
            type: 'success',
            icon: 'check circle',
            title: 'Success',
            description: 'Fault injected',
            time: 3000
        });
        })
        .catch((error)=>{
        //   setOpenerr(true)
          if(error.response.data.message){
            setErrmsg(error.response.data.message)
            }else{
              setErrmsg('')
            }
        })
      }
      else{
        if(selectedChId != undefined){
          setOpenerr(true)
          setErrmsg('Invalid')
        }else if(data.message){
          setOpenerr(true)
          setErrmsg('Please select the fault')
        }
      }
    }

  useEffect(()=>{
    console.log("selectedChId",selectedChId)
    selectedChIdRef.current = selectedChId;
    api.floor.cpmGetDevData().then((res)=>{
      if(res[eqpType]){ 
        let data=Object.values(res[eqpType]).sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
        setAllEquipmentData(data) 
        setDeviceImage(true)
      }
      Object.values(res[eqpType]).map((res)=>{
        if(selectedChIdRef.current === res.id )
        {
            let eqpParams1 = []
            imageParams.map((respp) => {
                if (res.Eqp_Attributes[respp.parameter] != undefined){
                   eqpParams1.push({
                     ...respp,
                     value: (!isNaN(parseFloat(res.Eqp_Attributes[respp.parameter].presentValue)))? (Math.round(res.Eqp_Attributes[respp.parameter].presentValue)) : res.Eqp_Attributes[respp.parameter].presentValue // Add value here
                   })
                 }
               })  
            setParticularEquipDataSet1(eqpParams1)

            let eqpParams2 = []
            controlsCard.map((respp) => {
             if (res.Eqp_Attributes[respp.parameter] != undefined){
                eqpParams2.push({
                  ...respp,
                  value: res.Eqp_Attributes[respp.parameter].presentValue // Add value here
                })
              }
            })
            setParticularEquipDataSet2(eqpParams2)

            let eqpParams3 = []
            paramsCard.map((respp) => {
             if (res.Eqp_Metrics[respp.parameter] != undefined){
                eqpParams3.push({
                  ...respp,
                  value: res.Eqp_Metrics[respp.parameter]
                })
              }
            })
            setParticularEquipDataSet3(eqpParams3)

            setDeviceImage(true)

          if(res.id){
          api.floor.chillerGraphDataLast1Hr(res.id).then((response)=>{ 
            setEqpGraphList(response.graphData[0]);
          }).catch((error)=>{
            // setOpenerr(true)
            if(error.response){
              setErrmsg(error.response.data.message)
              }else{
                setErrmsg('')
              }     
             })
            }
        }
      })
    }).catch((error)=>{
    //   setOpenerr(true)
      if(error.response){
        setErrmsg(error.response.data.message)
        }else{
          setErrmsg('')
        }
    })

    let chillAlarm = [{id:'1',alarm:'DP high and SP normal',Todisplay:"Fault-1"},{id:'2',alarm:'DP high and SP high',Todisplay:"Fault-2"},{id:'3',alarm:'DP normal, SP low and DT normal',Todisplay:"Fault-3"},{id:'4',alarm:'DP low and SP low',Todisplay:"Fault-4"},{id:'5',alarm:'DP normal, SP high and DT low',Todisplay:"Fault-5"},{id:'6',alarm:'DP normal, SP low and DT high',Todisplay:"Fault-6"},{id:'7',alarm:'DP normal, SP normal and DT high',Todisplay:"Fault-7"}];
    setChillerAlarm(chillAlarm)

    const timer = setInterval(() => {
      console.log('selectedChId in interval------------>',selectedChIdRef.current)
      if(selectedChIdRef.current){
        console.log("ppppppppppppppppppppppppppppppppppp")
          api.floor.cpmGetDevData().then((resp)=>{
            Object.values(resp.NONGL_SS_AIR_COOLED_CHILLER).map((res)=>{
            if(selectedChIdRef.current === res.id)
          {
            let eqpParams1 = []
            imageParams.map((respp) => {
                if (res.Eqp_Attributes[respp.parameter] != undefined){
                   eqpParams1.push({
                     ...respp,
                     value: (!isNaN(parseFloat(res.Eqp_Attributes[respp.parameter].presentValue)))? (Math.round(res.Eqp_Attributes[respp.parameter].presentValue)) : res.Eqp_Attributes[respp.parameter].presentValue // Add value here
                   })
                 }
               }) 
            setParticularEquipDataSet1(eqpParams1)
 
            let eqpParams2 = []
            controlsCard.map((respp) => {
             if (res.Eqp_Attributes[respp.parameter] != undefined){
                eqpParams2.push({
                  ...respp,
                  value: res.Eqp_Attributes[respp.parameter].presentValue // Add value here
                })
              }
            })
            setParticularEquipDataSet2(eqpParams2)

            let eqpParams3 = []
            paramsCard.map((respp) => {
             if (res.Eqp_Metrics[respp.parameter] != undefined){
                eqpParams3.push({
                  ...respp,
                  value: res.Eqp_Metrics[respp.parameter]
                })
              }
            })
            setParticularEquipDataSet3(eqpParams3)

            if(selectedChIdRef.current){
            api.floor.chillerGraphDataLast1Hr(selectedChIdRef.current).then((response)=>{
              setEqpGraphList(response.graphData[0]); 
            }).catch((error)=>{
            //   setOpenerr(true)
              if(error.response.data.message){
                setErrmsg(error.response.data.message)
                }else{
                  setErrmsg('')
                }     
               })
              }
          }
          })
        }).catch((error)=>{
        //   setOpenerr(true)
          if(error.response){
            setErrmsg(error.response.data.message)
            }else{
              setErrmsg('')
            }
          })
      }
      
    }, 5000);
    return () => clearInterval(timer);
  },[selectedChId, props.initialState1])

  const onChange = (newValue) => {
    setChillerOnOff(newValue)
    let id= selectedChId;
    let action= newValue == 0? "TURN_OFF_CHILLER":"TURN_ON_CHILLER";
    let ss_type= "NONGL_SS_AIR_COOLED_CHILLER";
    let req={
        id,
        action,
        ss_type
    }
    api.floor.cpmOnOffControl(req).then((response)=>{
      setChillerOnOff(response.startsWith('Working with a Scenario')?(newValue == 0?1:0):newValue)   
      toast({
        type:  response.startsWith('Working with a Scenario')?"error":"success",
        icon:  response.startsWith('Working with a Scenario')?"exclamation triangle":"check circle",
        title: response.startsWith('Working with a Scenario')?"Error":"Success",
        description: response,
        time: 2000,
      });

    })
    .catch((err)=>{
    //   setOpenerr(true)
      setErrmsg(err)   
    })
  };

  const ChipMethod = (props) => {
    // console.log("propssss in chip method",props)
    if(props.value == 'inactive'){
      setDisable(true)
    }
    return (
      <Paper className={classes.controls_paper} style={{ backgroundColor: props.color? props.color:"#0123B4", justifyContent: 'center' }}>
        <div style={{ color: 'white' }}>
          {/* {props.title=='AHU Mode' || 'FAU Mode'?<>
        {props.value == 'active'? 'Remote': props.value == 'inactive'? 'Local':''}</>
          : */}
          <>{props.value}{props.unit}</>
          {/* } */}
          </div>
      </Paper>
    )
  } 
  
  return (
    <div className={classes.root}>
       <Snackbar open={openerr} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert style={{ cursor: "pointer" }} severity="error" variant="filled" onClose={handleerrorclose}>
          {errmsg}
        </Alert>
      </Snackbar>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={1} style={{marginTop:'-1.5h'}}>
            {/* Left part */}
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9} xxl={9}>
                <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <FormControl
                              variant="filled"
                              size="large"
                              className={` ${classes.select} ${classes.formControl}`}
                              style={{
                                width: "max-content",
                                minWidth: "100%",
                                backgroundColor: "#eeeef5",
                                fontFamily: "Arial"
                              }}
                            >
                              <Select
                                labelId="filled-hidden-label-small"
                                id="demo-simple-select-outlined"
                                label="Chiller"
                                // className={classes.select}
                                value={selectedChName}
                                style={{
                                  fontWeight: "bold",
                                  height: "6vh",
                                  borderRadius: '0.8vw',
                                  fontFamily: "Arial"
                                }}
                                disableUnderline
                              >

                                {Object.values(allEquipmentData).map((_item) => (
                                  <MenuItem
                                    key={_item.id}
                                    value={_item.name}
                                    onClick={() => handleChillerChange(_item.name, _item.id)}
                                  >
                                    {_item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                        </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Card className={classes.paper} style={{borderRadius:"6px",boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",backgroundColor:"#fcfafa"}}>
                            <Map
                                              ref={mapRef}
                                              doubleClickZoom={false}
                                              zoomControl={false}
                                              dragging={false}
                                              scrollWheelZoom={false}
                                              crs={Leaflet.CRS.Simple}
                                              center={[0, 0]}
                                              attributionControl={false}
                                              // bounds={[[0, 0], [600, 730]]}
                                              bounds={[[0, 0], [420, 600]]}
                                              className={"floor-map"}
                                              style={{backgroundColor:"white",height: "48vh"}}
                                              onClick={(e) => { console.log({ x: e.latlng.lat, y: e.latlng.lng }) }}
                                            >
                                              <ImageOverlay
                                                interactive
                                                // url={'https://localhost/' + image + '.png'}
                                                url={ChillerImg}
                                                // bounds={[[50, 15], [600, 730]]}
                                                // bounds={[[100, -8], [525, 640]]}
                                                bounds={[[75, 70], [350, 570]]}
                                              />
                                          {
                                        ((particularEquipDataSet1) != undefined) ?
                                        <>{particularEquipDataSet1.map((res) => (
                                          <Marker position={res.coordinates} icon={iconDevice1}>
                                            <Tooltip direction={res.tooltipDirection} opacity={1} permanent>
                                              <div>
                                                <span className={classes.Leaflet_Tooltip_Heading}>
                                                  {res.title}
                                                </span>
                                                <br />
                                                <div
                                                  className={classes.Leaflet_Tooltip_Values}
                                                  style={{
                                                    color:'white',
                                                    // backgroundColor: (res.minRange && res.maxRange) ? (res.value >= res.minRange && res.value <= res.maxRange? greenColor[0]:redColor[0]): res.backgroundColor,
                                                    backgroundColor: res.backgroundColor ? res.backgroundColor : 'grey',
                                                  }}
                                                >
                                                  {res.value}
                                                  {res.unit ? res.unit : ""}
                                                </div>
                                              </div>{" "}
                                            </Tooltip>
                                          </Marker>
                                        ))   
                                        }</>
                                          :
                                          <></>
                                    }
                            </Map>
                    </Card>
                </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1} style={{marginTop:"1vh"}}>
                  {eqpGraphList?
                    (graphsCard.map((resData) =>(
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <Box className={classes.graphpaper}>
                        <div style={{fontWeight:'bold',color:'black'}} className={classes.CardHeadFont}>{resData.title}</div>
                          <div style={{marginTop:'2vh'}}>
                                <TimeS
                                        name={resData.title}
                                        data={eqpGraphList[resData['parameters'][0]]} data2={eqpGraphList[resData['parameters'][1]]} 
                                    style={{ width: "100%", height: "50%" }}
                                    via="Chiller"
                                  />
                            </div>
                      </Box>
                      </Grid>
                    ))):<></>
                    }
                    
                    {/* <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Box className={classes.graphpaper}>
                        <div style={{fontWeight:'bold',color:'black'}}  className={classes.CardHeadFont}>Condenser Water Temperature</div>
                                  <div style={{marginTop:'2vh'}}>
                                  <TimeS
                                        name='Condenser Water Temperature'
                                        data={CndW_HST} data2={CndW_HRT}
                                      style={{ width: "100%", height: "50%" }}
                                    /></div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Box className={classes.graphpaper}>
                          <div style={{fontWeight:'bold',color:'black'}}  className={classes.CardHeadFont}>VFD Phase Current</div>
                              <div style={{marginTop:'2vh'}}>
                                 <TimeSeriesChart
                                style={{ width: "100%", height: "50%" }}
                                data={VFD_Ph_Cur}
                                param='VFD_Ph_Cur'
                              />
                              </div>
                        </Box>
                    </Grid> */}
                </Grid>
            </Grid>
            {/* Right part */}
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                <Grid container item xs={12} spacing={1} direction="column">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Paper style={{maxWidth:"100%",color: "white",backgroundColor: "#0123b4",borderRadius: "10px",height:"6vh",display: "flex", justifyContent: "flex-start", paddingLeft: "16px",alignItems: "center"}}  className={classes.CardHeadFont}>
                        <Typography style={{ color: "#ffffff",fontFamily:"Arial",fontSize:"2vh",textAlign: "left"}}>Chiller Controls</Typography>
                      </Paper>
                    </Grid> 
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Card className={classes.paper} style={{marginTop:"0vh",height:'29.3vh'}}>
                        {
                        deviceImage ? <>
                        {
                            particularEquipDataSet2.map((res) => (
                            <Grid container spacing={1} >
                                <Grid container item xs={12} style={{ marginTop: '1.7vh',textAlign:'left'}}
                                direction="row" alignItems="center" justify="flex-start"
                                >
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.controls_text}>
                                    { res.title }
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    {
                                    res.component == 'Chip' ? 
                                        <ChipMethod value={res.value} unit={res.unit} title={res.title}/>
                                    : 
                                    res.component == 'Switch Selector' ? 
                                    <div style={{pointerEvents: (roleId !=2)||disable ?"none":"", opacity:(roleId !=2)||disable ?"0.4":""}}
                                    className={classes.switchselector}>
                                    <SwitchSelector
                                    onChange={onChange}
                                    options={options}
                                    // initialSelectedIndex={initialSelectedIndex}
                                    forcedSelectedIndex={res.value == 'active' ? 1 : 0}
                                    fontColor={"#000"}
                                    selectedFontColor={"#000"}
                                    optionBorderRadius={5}
                                    wrapperBorderRadius={7}
                                    fontSize={8}
                                    />
                                    </div>
                                    : res.component == 'Text Field' ?
                                    <Grid container item xs={12} alignItems="center" justify="flex-end">
                                                                    {/* <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                                                    <TextField
                                                                    // label="%"
                                                                    placeholder={res.value + "℃"}
                                                                    // style={{marginTop:'3px',marginLeft:'18px',"letter-spacing":"9px",width:'45px'}}
                                                                    name="Set_Point"
                                                                    autoComplete="off"
                                                                    // formatter.format(freq)
                                                                    value={setPointvalue}
                                                                    onChange={handleChangeForsetpointRAT}
                                                                    className={classes.text_field}
                                                                    // variant="outlined"
                                                                    // style={{ marginTop: '3vh' }}
                                                                    />
                                                                    </Grid>
                                                                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                                                    <Paper className={classes.set_button} onClick={()=>handleClickRat(props)} style={{ backgroundColor:"#0123B4",display:'flex', justifyContent: 'center',cursor:'pointer',marginTop:'1vh', pointerEvents: roleId != 2 ? "none" : "", opacity: roleId != 2 ? "0.4" : ""}}>
                                                                    <div style={{color:'white'}}>set</div>
                                                                </Paper> 
                                                                    </Grid> */}
                                    </Grid> 
                                    :
                                    <></>
                                    }
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                </Grid>
                            </Grid>
                            ))
                        }
                        </>:
                        <div style={{display:'flex',justifyContent:'center',marginTop:'20vh', fontSize:'2.5vh'}}>No Equipment Available</div
                        >}
                        <Grid  container item xs={12} style={{textAlign:'left',marginTop:'2vh'}}
                                          direction="row"  alignItems="center" justify="flex-start"
                                          >  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                              <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                                  <FormControl
                                                    variant="filled"
                                                    size="large"
                                                    className={`${classes.select} ${classes.formControl}`}
                                                    style={{
                                                      width: "max-content",
                                                      minWidth: "85%",
                                                      backgroundColor: "#eeeef5",
                                                      fontFamily: "Arial"
                                                    }}
                                                  >
                                                    <Select
                                                      labelId="filled-hidden-label-small"
                                                      id="demo-simple-select-outlined"
                                                      label="Chiller"
                                                      value={selectedChAlarm.message}
                                                      style={{
                                                        fontWeight: "bold",
                                                        height: "4vh",
                                                        borderRadius: '0.8vw',
                                                        fontFamily: "Arial"
                                                      }}
                                                      disableUnderline
                                                    >
                                                      {chillerAlarm.map((_item) => (
                                                        <MenuItem
                                                       
                                                          key={_item.id}
                                                          value={_item.alarm}
                                                          onClick={() => handleChillerAlarmChange(_item.alarm, _item.id)}
                                                        >
                                                          {_item.Todisplay}
                                                        </MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                              </Grid>
                                              {/* <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>      */}
                                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                              {disable ?
                                                <Paper style={{height:'3.5vh',width:"8vh",borderRadius:"2vh",backgroundColor:"lightgrey",color:"black",textAlign:"center", display: 'flex',alignItems: 'center', justifyContent: 'center',pointerEvents: "none", opacity: "0.4"}}>
                                                <div onClick={()=>handleSubmitAlarmClick(selectedChAlarm)}>Submit</div></Paper>
                                                :
                                                <Paper style={{height:'3.5vh',width:"8vh",borderRadius:"2vh",backgroundColor:"lightgrey",color:"black",textAlign:"center", display: 'flex',alignItems: 'center', justifyContent: 'center',cursor:"pointer"}}>
                                                <div onClick={()=>handleSubmitAlarmClick(selectedChAlarm)}>Submit</div></Paper>
                                                }
                                              </Grid>
                                </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Paper style={{maxWidth:"100%",color: "white",backgroundColor: " #0123b4",borderRadius: "6px",height:"6vh",display: "flex", justifyContent: "flex-start", paddingLeft: "16px",alignItems: "center"}}>
                        <Typography style={{ color: "#ffffff",fontFamily:"Arial",fontSize:"2vh",textAlign: "left"}}>Chiller Parameters</Typography>
                      </Paper>
                    </Grid>   
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Card
                          className={classes.paper}
                          style={{
                            marginTop:'0.5vh',
                            // boxShadow:"0 4px 10px 2px rgba(0, 0, 0, 0.1)",
                            // backgroundColor: " #ffffff",
                            borderRadius: "6px",
                            height: "41.3vh",
                          }}
                        >
                                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1}>
                                  {particularEquipDataSet3.map((resp)=>(
                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                              <Card className={classes.semicircularbar} style={{fontWeight:'bold',color:'black'}}>
                                              <div className={classes.CardHeadFont} style={{fontSize:'1.6vh',whiteSpace:'nowrap'}}>{resp.title}</div>
                                              {
                                                resp.component == 'Text'?
                                                     <div style={{display:'flex',justifyContent:'center',textAlign:'center',color:'#0123B4',fontSize:'2.8vh',marginTop:'1.5vh'}}>
                                                    {resp.title =='IKW/TR'? '1': resp.title == 'Run Hours'? "750":""}{resp.unit}
                                                    </div>
                                                    :
                                                    <>
                                                    <div className={classes.semicircularbarcomp}>
                                                      <SemiCircleProgressBar strokeWidth={15} stroke="#0123B4" diameter={75} orientation="up" percentage='55'  />
                                                    </div>
                                                    <div style={{ marginTop: '-2.1vh', fontSize: '11px',marginLeft:'0.7vh' }}>55%</div>
                                                    </>
                                             }
                                              {/* {
                                                resp.component == 'Text'?
                                                     <div style={{display:'flex',justifyContent:'center',textAlign:'center',color:'#0123B4',fontSize:'3.5vh',marginTop:'1vh'}}>
                                                    {resp.value? formatter.format((formatter.format(resp.value)/60))+'Hrs' : 'N/A'}
                                                    </div>
                                                    :
                                                    <>
                                                    <div className={classes.semicircularbarcomp}>
                                                      <SemiCircleProgressBar strokeWidth={15} stroke="#0123B4" diameter={75} orientation="up" percentage='50'  />
                                                    </div>
                                                    <div style={{ marginTop: '-2.1vh', fontSize: '11px',marginLeft:'0.7vh' }}>50%</div>
                                                    </>
                                             } */}
                                              </Card>
                                          </Grid>
                                  ))}
                                </Grid>
                                <Grid container xs={12} style={{marginTop:'1.5vh',fontWeight:'bold',color:'black'}}>
                                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1} style={{display:'flex',justifyContent:'center',alignItems:'left',marginTop:'1vh'}}>
                                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Grid>
                                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:'-0.5vh'}}>
                                              <div className={classes.CardHeadFont}>Amps(A)</div>
                                          </Grid>
                                          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}></Grid>
                                          <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5} style={{marginTop:'-0.5vh'}}>
                                            {/* <ProgressBar 
                                            completed="60" 
                                            bgColor="#00C2FF"
                                            margin="10px"
                                            height="18px"
                                            width="100%"
                                            borderRadius="0"
                                            isLabelVisible={true}/> */}<ChipMethod value={130} unit="[A]" color="#00c2ff"/>
                                          </Grid>
                                    </Grid>
                                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1} style={{display:'flex',justifyContent:'center',alignItems:'left',marginTop:'1vh'}}>
                                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Grid>
                                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                              <div className={classes.CardHeadFont}>Volts -R(V)</div>
                                          </Grid>
                                          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}></Grid>
                                          <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5} style={{marginTop:'-0.5vh'}}>
                                            {/* <ProgressBar 
                                            completed={60} 
                                            bgColor="#00C2FF"
                                            margin="10px"
                                            height="18px"
                                            width="100%"
                                            borderRadius="0"
                                            isLabelVisible={false}/> */}
                                            <ChipMethod value={415} unit="[V]" color="#00c2ff"/>
                                          </Grid>
                                    </Grid>
                                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1} style={{display:'flex',justifyContent:'center',alignItems:'left',marginTop:'1vh'}}>
                                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Grid>
                                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:'-0.5vh'}}>
                                              <div className={classes.CardHeadFont}>Volts -Y(V)</div>
                                          </Grid>
                                          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}></Grid>
                                          <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5} style={{marginTop:'-0.5vh'}}>
                                            {/* <ProgressBar 
                                            completed={60} 
                                            bgColor="#00C2FF"
                                            margin="10px"
                                            height="18px"
                                            width="100%"
                                            borderRadius="0"
                                            isLabelVisible={false}/> */}
                                            <ChipMethod value={415} unit="[V]" color="#00c2ff"/>
                                          </Grid>
                                    </Grid>
                                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1} style={{display:'flex',justifyContent:'center',alignItems:'left',marginTop:'1vh'}}>
                                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Grid>
                                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:'-0.5vh'}}>
                                              <div className={classes.CardHeadFont}>Volts -B(V)</div>
                                          </Grid>
                                          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}></Grid>
                                          <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5} style={{marginTop:'-0.5vh'}}>
                                            {/* <ProgressBar 
                                            completed={60} 
                                            bgColor="#00C2FF"
                                            margin="10px"
                                            height="18px"
                                            width="100%"
                                            borderRadius="0"
                                            isLabelVisible={false}/> */}
                                            <ChipMethod value={415} unit="[V]" color="#00c2ff"/>
                                          </Grid>
                                    </Grid>
                                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} spacing={1} style={{display:'flex',justifyContent:'center',alignItems:'left',marginTop:'1vh'}}>
                                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Grid>
                                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:'-0.5vh'}}>
                                              <div className={classes.CardHeadFont}>VFD(Hz)</div>
                                          </Grid>
                                          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}></Grid>
                                          <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5} style={{marginTop:'-0.5vh'}}>
                                            {/* <ProgressBar 
                                            completed={60} 
                                            bgColor="#00C2FF"
                                            margin="10px"
                                            height="18px"
                                            width="100%"
                                            borderRadius="0"
                                            isLabelVisible={false}/> */}
                                            <ChipMethod value={40} unit="Hz" color="#00c2ff"/>
                                          </Grid>
                                    </Grid>
                                </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
      </Grid>
      <SemanticToastContainer position='top-center' />
    </div>
  )
}
