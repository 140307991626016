import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Grid, Divider } from '@material-ui/core';
import { Card } from '@material-ui/core';
import api from './../../api';
import theme from './../../responsive/TextTypography';
import Warning from "components/Typography/Warning";
import Danger from "components/Typography/Danger";
import Success from 'components/Typography/Success';
import SemiCircleProgressBar from "react-progressbar-semicircle";
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import { setConstantValue } from 'typescript';
import { blueColor } from 'assets/jss/material-dashboard-react';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '-1vh'
  },
  paper: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    // boxShadow: '0px 4px 20px #0123B41A',
    // backgroundColor: 'white',
    // borderRadius: '14px',
    borderRadius: "6px",
    boxShadow: "1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor: "#fcfafa",
    height: '10vh',
    marginTop: "1vh",
    opacity: '1'
  },
  semicircularbar: {
    '@media (min-width:0px) and (max-width:599.95px)': {//xs
      marginTop: '-0.5vh',
      marginLeft: '-1.5vh'
    },
    '@media (min-width:600px) and (max-width:959.95px)': {//sm
      marginTop: '-1vh',
      marginLeft: '-0.1vh'
    },
    '@media (min-width:960px) and (max-width:1279.95px)': {//md
      marginTop: '-2vh',
      marginLeft: '-0.1vh'
    },
    '@media (min-width:1280px) and (max-width:1919.95px)': {//lg
      marginTop: '-2vh',
      marginLeft: '-0.1vh'
    },
    '@media (min-width:1920px) and (max-width:2559.95px)': {//xl
      marginTop: '-1vh',
      marginLeft: '0.5vh'
    },
  },
  text: {
    fontSize: "17px",
    color: " #292929",
    fontFamily: "Arial"
  },
  statusFont: {
    '@media (min-width:0px) and (max-width:599.95px)': {//xs
      textAlign: 'center',
      fontSize: '1.2vh',
      color: 'black',
      whiteSpace: 'nowrap'
    },
    '@media (min-width:600px) and (max-width:959.95px)': {//sm
      textAlign: 'center',
      fontSize: '1.5vh',
      color: 'black',
      whiteSpace: 'nowrap'
    },
    '@media (min-width:960px) and (max-width:1279.95px)': {//md
      textAlign: 'center',
      fontSize: '1.5vh',
      color: 'black',
      whiteSpace: 'nowrap'
    },
    '@media (min-width:1280px) and (max-width:1919.95px)': {//lg
      textAlign: 'center',
      fontSize: '1.7vh',
      whiteSpace: 'nowrap',
      color: 'black'
    },
    '@media (min-width:1920px) and (max-width:2559.95px)': {//xl
      textAlign: 'center',
      fontSize: '1.7vh',
      color: 'black',
      whiteSpace: 'nowrap',
    },
  },
  flrParamstatusFont: {
    '@media (min-width:0px) and (max-width:599.95px)': {//xs
      textAlign: 'center',
      fontSize: '1.2vh',
      color: 'black',
      whiteSpace: 'nowrap'
    },
    '@media (min-width:600px) and (max-width:959.95px)': {//sm
      textAlign: 'center',
      fontSize: '1.5vh',
      color: 'black',
      whiteSpace: 'nowrap'
    },
    '@media (min-width:960px) and (max-width:1279.95px)': {//md
      textAlign: 'center',
      fontSize: '1.5vh',
      color: 'black',
      whiteSpace: 'nowrap'
    },
    '@media (min-width:1280px) and (max-width:1919.95px)': {//lg
      textAlign: 'center',
      fontSize: '1.8vh',
      whiteSpace: 'nowrap',
      color: 'black'
    },
    '@media (min-width:1920px) and (max-width:2559.95px)': {//xl
      textAlign: 'center',
      fontSize: '1.8vh',
      color: 'black',
      whiteSpace: 'nowrap',
    },
  }
}));

function UpsEmsLanding(props) {
  const classes = useStyles();
  const alerts = useSelector(state => state.alarm.alarmData);
  const [deviceList, setDeviceList] = useState(props.device);
  const [deviceListnew, setDeviceListNew] = useState(props.parameterLevel);
  const [constantValue, setConstantValue] = useState(0);
  const [latest, setLatest] = useState(props.newName);
  const [eachChillerData, setEachChillerData] = React.useState([])
  const [criticalAlertsChiller, setcriticalAlertsChiller] = React.useState(0);
  const [soluAlertsChiller, setsoluAlertsChiller] = React.useState(0);
  const [openerr, setOpenerr] = React.useState(false);
  const [errmsg, setErrmsg] = React.useState('');
  let devData = props.type == 'CPM' ? Object.values(props.device) : props.device
  const numEmptyCards = 3 - props.device.length; // Adjust the total number as needed  
  const emptyCardsDev = Array.from({ length: numEmptyCards }, (_, index) => (
    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
      <Card style={{ boxShadow: "1px 0px 8px 2px rgba(0, 0, 0, 0.14)", backgroundColor: "#fcfafa", "border-radius": "10px", height: props.type === 'CPM' ? "13.5vh" : devData.length < 1 ? "11.5vh" : "11.7vh" }}>
      </Card>
    </Grid>
  ));

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const onClickIssue = (id, name, resp) => {
    api.floor.allDataForFloor().then((res) => {
      const matchedObject = res.find((item) => item.em_name === resp.name);

      if (props.type == 'UPS') {
        props.history.push({
          pathname: `/admin/glUps`,
          state: {
            flr_id: props.fid,
            fname: props.fdata,
            dev_id: id,
            dev_name: name
          }
        })
      } else if (props.type == 'energyMeter') {
        props.history.push({
          pathname: `/admin/glEnergyMeter`,
          state: {
            dev_type: props.type,
            flr_id: props.fid,
            fname: props.fdata,
            FeedName: matchedObject ? matchedObject.fdr_name : null,
            electiRoom: matchedObject ? matchedObject.rm_name : null,
            zoneName: props.zoneActualName,
            dev_id: id,
            dev_name: name,
          }
        })
      } else if (props.type == 'CPM') {
        props.history.push({
          pathname: `/admin/GlChillerPage`,
          state: {
            data: id,
            name: name
          }
        });
      } else if (props.type == 'waterMeter') {
        props.history.push({
          pathname: `/admin/glWaterMeter`,
          state: {
            dev_type: props.type,
            flr_id: props.fid,
            fname: props.fdata,
            dev_id: id,
            dev_name: name
          }
        })
      }
    })
  }

  const handleCardClick = (id, name, resp) => {
    api.floor.allDataForFloor().then((res) => {
      const matchedObject = res.find((item) => item.em_name === resp.name);
      if (props.type == 'UPS') {
        props.history.push({
          pathname: `/admin/glUps`,
          state: {
            flr_id: props.fid,
            fname: props.fdata,
            dev_id: id,
            dev_name: name
          }
        })
      } else if (props.type == 'energyMeter') {
        props.history.push({
          pathname: `/admin/glEnergyMeter`,
          state: {
            dev_type: props.type,
            flr_id: props.fid,
            fname: props.fdata,
            roomId: resp.zoneId,
            FeedName: matchedObject ? matchedObject.fdr_name : null,
            electiRoom: matchedObject ? matchedObject.rm_name : null,
            zoneName: props.zoneActualName,
            dev_id: name,
            dev_name: id
          }
        })
      } else if (props.type == 'CPM') {
        props.history.push({
          pathname: `/admin/GlChillerPage`,
          state: {
            data: id,
            name: name
          }
        });
      } else if (props.type == 'waterMeter') {
        props.history.push({
          pathname: `/admin/glWaterMeter`,
          state: {
            dev_type: props.type,
            flr_id: props.fid,
            fname: props.fdata,
            dev_id: id,
            dev_name: name
          }
        })
      }
    })
  }

  const sendDataToParent = (element) => {
    props.onDataChange(element);
  };

  const sendDataToParenttwice = (element) => {
    props.onDataChangetwice(element);
  };

  const handleCardClickNewApi = (name, id, type, element, fid) => {
    if (element.zone_type === 'GL_LOCATION_TYPE_ZONE') {
      sendDataToParent(element)
      const matchingDevice = props.device.find((device) => device.ssid === id);
      const zoneId = matchingDevice ? matchingDevice.zoneId : null;
      api.floor.devicemap(zoneId, "energyMeter").then((res) => {
        const filteredZones = res.filter((device) => device.zone_parent === zoneId);
        filteredZones.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        setDeviceList(filteredZones);
        setDeviceListNew([element]);
        setLatest(name);
        const totalActPwrTtl = filteredZones.reduce((total, item) => {
          return total + parseFloat(item.Act_Pwr_Ttl || 0);
        }, 0);
        const parameterLevelActPwrTtl = element
          ? parseFloat(element.Act_Pwr_Ttl || 0)
          : 0;

        const result = parameterLevelActPwrTtl - totalActPwrTtl;
        setConstantValue(isNaN(result) ? 0 : result);
      })
    } else if (element.zone_type === 'GL_LOCATION_TYPE_ROOM') {
      sendDataToParenttwice(element)
      api.floor.devicemap("eb32cb5b-7eeb-45c5-9da9-a4a170aa3e20", "energyMeter").then((res) => {
        const matchedDevice = res.find((device) => device.ssid === id);
        setDeviceListNew([element]);
        setLatest(name);
        if (matchedDevice) {
          const zoneId = matchedDevice.zoneId;
          const filteredZones = res.filter((device) => device.zone_parent === zoneId);

          filteredZones.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
          setDeviceList(filteredZones);
          const totalActPwrTtl = filteredZones.reduce((total, item) => {
            return total + parseFloat(item.Act_Pwr_Ttl || 0);
          }, 0);
          const parameterLevelActPwrTtl = element
            ? parseFloat(element.Act_Pwr_Ttl || 0)
            : 0;

          const result = parameterLevelActPwrTtl - totalActPwrTtl;
          setConstantValue(isNaN(result) ? 0 : result);
        } else {
          console.log("No matching device found with the given id.");
        }

      })

    } else if (element.zone_type === 'GL_LOCATION_TYPE_VIRTUAL_ROOM') {
      handleCardClick(name, id, element)
    }
  }

  function EachDevCardForDetails(type, element, index, fid) {
    let active = {}
    if (type == 'UPS') {
      const input_ph_volt_values = [element.input_ph_volt_A,
      element.input_ph_volt_B,
      element.input_ph_volt_C,].map((value) => parseInt(value, 10));
      const sum = input_ph_volt_values.reduce((total, value) => total + value, 0);
      const status = (sum / input_ph_volt_values.length).toFixed(2);
      const output_ph_active_values = [element.ph_A_Out_acti_Pow,
      element.ph_B_Out_acti_Pow,
      element.ph_C_Out_acti_Pow,].map((value) => parseInt(value, 10));
      const sum1 = output_ph_active_values.reduce((total, value) => total + value, 0);
      const load = sum1 / output_ph_active_values.length
      active["Alarm"] = 0
      active["Status"] = status;
      active["Load"] = load;
      active["UPS Temperature"] = '19°C'
      active["Battery Charge"] = '47V'
      // if (element.kW) {
      active["kW"] = element.kW
      // }
    } else if (type === 'energyMeter') {
      if (element.Volt_LL_Avg) {
        active["Avg VL_L [V]"] = formatter.format(element.Volt_LL_Avg)
      }
      if (element.Cur_Avg) {
        active["Avg Current [A]"] = formatter.format(element.Cur_Avg)
      }
      if (element.Volt_LN_Avg) {
        active["Avg VL_N [V]"] = formatter.format(element.Volt_LN_Avg)
      }
      if (element.PF_Ttl) {
        active["PF"] = formatter.format(element.PF_Ttl)
      }
      if (element.Act_Pwr_Ttl) {
        active["Active power [kW]"] = formatter.format(element.Act_Pwr_Ttl)
      }
      if (element.Act_Enrg) {
        active["Frequency [Hz]"] = formatter.format(element.Freq)
      }
    } else if (type == 'waterMeter') {
      if (element.DAC) {
        active["Daily Consumption[liters]"] = formatter.format(element.DAC)
      }
      if (element.TC) {
        active["Total Consumption[liters]"] = formatter.format(element.TC)
      }
    }
    return (
      <>
        <Grid container item xs={12} spacing={1} className={classes.statusFont}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ textAlign: 'left', marginLeft: '0.5vh', fontWeight: 'bold', fontSize: '2.5vh' }}>{element.name}</Grid>
          {type === 'waterMeter'?
        <></>:
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ textAlign: 'right', marginLeft: '-0.5vh', cursor:'pointer'}}><ArrowDropDownCircleOutlinedIcon onClick={() => {
          if (type === 'waterMeter') {
            // handleCardClick(element.name, element.ssid, type, element, fid);
          } else {
            handleCardClickNewApi(element.name, element.ssid, type, element, fid);
          }
        }}/></Grid>
      }
      </Grid>

        <Grid container item xs={12} spacing={1} style={{ marginLeft: '0vh' }}>
          {Object.entries(active).map(([key, value]) => (
            <Grid item xs={type == 'waterMeter' ? 6 : 4} sm={type == 'waterMeter' ? 6 : 4} md={type == 'waterMeter' ? 6 : 4} lg={type == 'waterMeter' ? 6 : 4} xl={type == 'waterMeter' ? 6 : 4} xxl={type == 'waterMeter' ? 6 : 4}>
              <Card className={classes.paper} style={{ height: "11.5vh" }}>
                <Grid container item xs={12} spacing={1} direction='column' alignItems='center' className={classes.statusFont}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ color: "black", whiteSpace: 'nowrap', marginLeft: key == 'Daily Consumption[liters]' ? '-12vh' : key == 'Total Consumption[liters]' ? '-12vh' : key == 'ACTIVE ENERGY [kWh]' ? '-7.5vh' : key == 'Battery Charge' ? '-5vh' : key == 'ACTIVE POWER [kW]' ? '-6vh' : key == 'Avg PF[mA]' ? '-2vh' : key == 'ACTIVE POWER [kW]' ? '-2.5vh' : key == 'AVG CURRENT [A]' ? '-7vh' : key == 'PF' ? '1vh'  : '-4.5vh',marginTop:'1vh' }}>
                    {key}
                  </Grid>
                  {key == 'Load' ?
                    <>
                      <div className={classes.semicircularbar}>
                        <SemiCircleProgressBar
                          // strokeWidth={window.innerWidth<600? 20:window.innerWidth>600?20 : window.innerWidth<960? 30:window.innerWidth<1280?30:window.innerWidth<1920?30:40}
                          stroke="#0123b4"
                          strokeWidth={20}
                          diameter={100}
                          orientation="up"
                          percentage={value} // Set the value here
                        // showPercentValue
                        />
                      </div>
                      <div style={{ marginTop: '-2.5vh', fontSize: '10px' }}>{formatter.format(value)}</div>
                    </>
                    :
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ color: "#0123b4", fontWeight: 'bold' }}>
                      {/* {value!='0'?formatter.format(value):typeof value=='string'?'N/A':value} */}
                      {value}
                    </Grid>
                  }

                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      </>
    )
  }

  function eachParameterDetails(type, element, index, buildingid) {
    let active = {}
    if (type === 'energyMeter') {
      if (element.Volt_LL_Avg) {
        active["Avg VL_L [V]"] = formatter.format(element.Volt_LL_Avg)
      }
      if (element.Cur_Avg) {
        active["Avg Current [A]"] = formatter.format(element.Cur_Avg)
      }
      if (element.Volt_LN_Avg) {
        active["Avg VL_N [V]"] = formatter.format(element.Volt_LN_Avg)
      }
      if (element.PF_Ttl) {
        active["PF"] = formatter.format(element.PF_Ttl)
      }
      if (element.Act_Pwr_Ttl) {
        active["Active Power [kW]"] = formatter.format(element.Act_Pwr_Ttl)
      }
      if (element.Act_Enrg) {
        active["Frequency [Hz]"] = formatter.format(element.Freq)
      }
    }

    return (
      <>
        {Object.entries(active).map(([key, value], idx) => (
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
            <Card className={classes.paper}
              style={{ "border-radius": "10px", height: props.type === 'CPM' ? "13.5vh" : "12vh", marginTop: '-0.25vh' }}>
              <Grid container item xs={12} direction='column' style={{ display: 'inline-flex', justifyContent: "center", alignContent: "center", whiteSpace: "nowrap" }}>
                <Grid container item xs={12} spacing={1} direction='column' alignItems='center' className={classes.flrParamstatusFont}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}><div style={{ display: 'flex',marginTop: '1vh', justifyContent: "center", alignContent: "center" }} className={classes.flrParamstatusFont}>{key}</div></Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5} style={{ color: "#0123b4", fontWeight: 'bold' }}>
                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}> </Grid>
                    {value}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </>
    );
  }

  useEffect(() => {
    if (props.type === 'energyMeter') {
      setDeviceList(props.device);
      setDeviceListNew(props.parameterLevel);
      setLatest(props.newName);

      const totalActPwrTtl = props.device.reduce((total, item) => {
        return total + parseFloat(item.Act_Pwr_Ttl || 0);
      }, 0);

      const parameterLevelActPwrTtl =
        props.parameterLevel.length > 0
          ? parseFloat(props.parameterLevel[0].Act_Pwr_Ttl || 0)
          : 0;

      const result = parameterLevelActPwrTtl - totalActPwrTtl;
      setConstantValue(isNaN(result) ? 0 : result);
    } else {
      // Set to empty or reset states for non-energymeter type
      setDeviceList([]);
      setDeviceListNew([]);
      setLatest('');
      setConstantValue(0);
    }
  }, [props.device, props.parameterLevel])

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <Grid container spacing={1} style={{ marginTop: '1vh' }}>
          {props.type == 'energyMeter' ?
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12} sm={12} md={10} lg={10} xl={10} xxl={10}>
                    <Card className={classes.paper} style={{ height: props.type === 'CPM' ? "16vh" : props.type == 'waterMeter' || devData.length <= '12' ? "13.85vh" : "14vh" }}>
                    <Grid container item xs={12} spacing={1} style={{marginLeft:'0.7vh'}}>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                        <Card className={classes.paper} style={{ backgroundColor:'#0123B4',height:(props.type === 'CPM'|| props.type === 'AHU') ?"16vh":"12vh", display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'-0.2vh',position: 'relative' }} 
                        //  onClick={() => handleCardClick(deviceListnew[0].name,deviceListnew[0].ssid,deviceListnew[0])}
                        onClick={() => {
                          if (deviceListnew[0] && deviceListnew[0].name && deviceListnew[0].ssid) {
                            handleCardClick(deviceListnew[0].name, deviceListnew[0].ssid, deviceListnew[0]);
                          }
                        }}
                          >
                      <Grid container item xs={12} direction='column' style={{display:'inline-flex',justifyContent: "center",alignContent: "center", whiteSpace: "nowrap", cursor: "pointer"}}>
                                                  <Grid container item xs={12} spacing={1} direction='column' alignItems='center' className={classes.statusFont}>                                     
                                                      <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}> </Grid>
                                                      <Grid item xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} style={{alignItems:'center',justifyContent: "center", display:'flex',fontSize:"2.5vh",fontWeight:"bold",color:'white',}}>{latest}</Grid>
                                                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}></Grid>
                                                  </Grid>       
                      </Grid>
                      <AddIcon
    style={{
      position: 'absolute',
      top: '10px', // Adjust as needed
      right: '10px', // Adjust as needed
      color: 'white', // Change color if needed
      fontSize: '2vh', // Adjust size if needed
    }}
  />
                      </Card>
                    </Grid>

                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
                    <Grid container item xs={12} spacing={1} direction='row'>
                    <>{console.log('---------------->>',deviceListnew)}
                                {deviceListnew.map((elementnew, index) => (
                                  <>{eachParameterDetails(props.type,elementnew, index,props.buildingID)}</>
                                ))}
                                  
                                  </>
                                  </Grid>
                    </Grid>
                    </Grid>
                    </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                    <Card className={classes.paper} style={{height:(props.type === 'CPM'|| props.type === 'AHU') ?"16vh":"14vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                   <Grid container item xs={12} direction='column' style={{display:'inline-flex',justifyContent: "center",alignContent: "center", whiteSpace: "nowrap"}}>
                                               <Grid container item xs={12} spacing={1} direction='column' alignItems='center' className={classes.statusFont}>                                     
                                                   <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{fontSize:"2vh",fontWeight:"bold",alignItems:'center',justifyContent: "center", display:'flex',marginTop:"0vh"}}>Leakage [kvar]</Grid>
                                                   <Grid item xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} style={{alignItems:'center',justifyContent: "center", display:'flex',marginTop:"0vh"}} className={classes.statusFont}>
                                                   {constantValue.toFixed(2)}
                                                   </Grid>
                                               </Grid>       
                   </Grid>
                   </Card>
                    </Grid>
                  </Grid>
      
            :
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12} sm={12} md={props.type === 'CPM' ? 8 : 4} lg={props.type === 'CPM' ? 8 : 4} xl={props.type === 'CPM' ? 8 : 4} xxl={props.type === 'CPM' ? 8 : 4}>
                <Card className={classes.paper} style={{ height: props.type === 'CPM' ? "16vh" : props.type == 'waterMeter' || devData.length <= '12' ? "13.85vh" : "14vh",width:  "72vh", overflow: devData.length >= 8 ? 'auto' : '' }}>
                  <Grid container item xs={12} spacing={1} direction='row'>
                    {devData.map((res) =>
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>



                          {/* <Card onClick={() => onClickIssue(props.type === 'CPM' ? res.id : res.ssid, res.name, res)} style={{ boxShadow: parseInt(res.alerts_cri) + parseInt(res.alerts_solu) === 0 ? "inset 0px 0px 0px 2px rgb(76 175 80)" : parseInt(res.alerts_solu) >= 1 ? "inset 0px 0px 0px 2px rgb(242, 170, 26)" : parseInt(res.alerts_cri) ? "inset 0px 0px 0px 2px rgb(179, 0, 0)" : "inset 0px 0px 0px 2px rgb(76 175 80)", backgroundColor: "#fcfafa", "border-radius": "10px", height: props.type === 'CPM' ? "13.5vh" : devData.length < 1 ? "12vh" : "6.7vh" }}>
                            <Grid container item xs={12} direction='column' style={{ display: 'inline-flex', justifyContent: "center", alignContent: "center", whiteSpace: "nowrap", cursor: "pointer" }}>
                              <Grid container item xs={12} spacing={1} direction='column' alignItems='center' className={classes.statusFont}>
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}> </Grid>
                                <Grid item xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} style={{ alignItems: 'center', justifyContent: "center", display: 'flex' }}>{res.name}</Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}></Grid>
                              </Grid>
                            </Grid>
                          </Card> */}

                          <Card onClick={() => onClickIssue(props.type === 'CPM' ? res.id : res.ssid, res.name, res)} style={{  backgroundColor:'#0123B4', "border-radius": "10px", height: props.type === 'CPM' ? "13.5vh" : devData.length < 1 ? "16vh":"12vh", display: 'flex', justifyContent: 'center', alignItems: 'center',position: 'relative' }}>
                            <Grid container item xs={12} direction='column' style={{ display: 'inline-flex', justifyContent: "center", alignContent: "center", whiteSpace: "nowrap", cursor: "pointer" }}>
                              <Grid container item xs={12} spacing={1} direction='column' alignItems='center' className={classes.statusFont}>
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}> </Grid>
                                <Grid item xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} style={{ alignItems: 'center', justifyContent: "center", display: 'flex',fontSize:"2.5vh",fontWeight:"bold",color:'white'}}>{res.name}</Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}></Grid>
                              </Grid>
                            </Grid>
                            <AddIcon
    style={{
      position: 'absolute',
      top: '10px', // Adjust as needed
      right: '10px', // Adjust as needed
      color: 'white', // Change color if needed
      fontSize: '2vh', // Adjust size if needed
    }}
  />
                          </Card>
                        </Grid>
                      </>
                    )}
                    {emptyCardsDev}
                  </Grid>
                </Card>
              </Grid>
            </Grid>}

        </Grid>
        {props.type == 'energyMeter' ?
              // <div style={{height:'62vh',overflowY: "auto",overflowX:'hidden',marginTop:'1vh'}}>
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={1}>
              {deviceList.map((element, index) => (
                <Grid item xs={12} sm={12} md={props.type == 'waterMeter' ? 4 : 6} lg={props.type == 'waterMeter' ? 4 : 6} xl={props.type == 'waterMeter' ? 4 : 6} xxl={props.type == 'waterMeter' ? 4 : 6}>
                  <Card className={classes.paper} style={{ height: props.type == 'waterMeter' ? "19vh" : "33.5vh" }}>
                    <>{EachDevCardForDetails(props.type, element, index, props.fid)}</>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
            // </div>
           :
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={2}>
              {props.device.map((element, index) => (
                <Grid item xs={12} sm={12} md={props.type == 'waterMeter' ? 4 : 6} lg={props.type == 'waterMeter' ? 4 : 6} xl={props.type == 'waterMeter' ? 4 : 6} xxl={props.type == 'waterMeter' ? 4 : 6}>
                  <Card className={classes.paper} style={{ height: props.type == 'waterMeter' ? "19vh" : "33.5vh", 
                    // width:  "72vh"
                    }}>
                    <>{EachDevCardForDetails(props.type, element, index)}</>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        }
      </ThemeProvider>
    </div>
  )
}
export default withRouter(UpsEmsLanding)