  import React, {  useEffect, useState } from "react";
  import ReactApexCharts from 'react-apexcharts'
  import { format, compareAsc, toDate,addMinutes } from 'date-fns'
  import { makeStyles } from "@material-ui/core/styles";
  import { withStyles } from '@material-ui/core/styles';
  import Dialog from '@material-ui/core/Dialog';
  import MuiDialogTitle from '@material-ui/core/DialogTitle';
  import MuiDialogContent from '@material-ui/core/DialogContent';
  import IconButton from '@material-ui/core/IconButton';
  import CloseIcon from '@material-ui/icons/Close';
  import Typography from '@material-ui/core/Typography';

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const useStyles = makeStyles((theme) => ({
    customDialog: {
      // Set the desired width for the dialog
      width: '700px', // Adjust this value as needed
    },
  }))

  export default function ApexChart(props)  {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

      const { data,param} = props;
      const text = props.param
      let minimum = 0, maximum = 0;
      let elements = new Map(), elementsMin = new Map(), elementsMax = new Map();
      data.map((_elem, i) => {
          var myDate =toDate(new Date(_elem.measured_time))
          elements.set(i, {x: myDate, y: parseFloat(_elem.param_value).toFixed(2)})
      });
      var elemArr = [], elemArrMin = [], elemArrMax = [];
      for(let i of elements.values()) {
          elemArr.push(i)
      }
      const dataset = {
            
          series: [{
            name: text,
            data: elemArr
          }],
          options: {
            chart: {
              type: 'line',
              stacked: false,
              events: {
                click: function (event, chartContext, config) {
                  onclickchart();
                }},
              // height: 350,
              zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true,
              
              
              },
              toolbar: {
                autoSelected: 'zoom'
              }
            },
            dataLabels: {
              enabled: false
            },
            markers: {
              size: 0,
            },
            title: {
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 0.5,
                inverseColors: false,
                opacityFrom: 0.3,
                opacityTo: 0,
                stops: [0, 90, 100]
              },
            },
            yaxis: {
              show: true,
              showAlways: true,
              // min: 0,
              // tickAmount: 6,
              // max: 30,
              decimalsInFloat: true,
              formatter: (val) => { return val },
              labels: {
                formatter: function (val) {
                  return parseInt(val); // Convert the value to an integer (whole number)
                },
              },
            },
            xaxis: {
              type: 'datetime',
              labels: {
                formatter: function (val) {
                  return format(new Date(val), "HH:mm"); // Format date with hours and minutes only
                },
              }
            },
            tooltip: {
              shared: false,
              // y: {
              //   formatter: function (val) {
              //     return (val / 1000000).toFixed(0)
              //   }
              // }
            }
          },
        
        
        };
      // } 
      const onclickchart = () => {
        // console.log("clicked on chart",param)
        handleClickOpen(true)
      } 
      return (
      <div id="chart">
          <ReactApexCharts options={dataset.options} series={dataset.series} height={ 
                      window.innerHeight == 641 ?
                      ( props.via == 'EM'? 150:props.via == 'WM'? 310: 310):
                      window.innerHeight == 768 ?
                      ( props.via == 'EM'? 180:props.via == 'WM'? 380: 380):
                      window.innerHeight == 1080 ?
                      ( props.via == 'EM'? 240:props.via == 'WM'? 550: 380):
                      window.innerHeight == 1920 ?
                      ( props.via == 'EM'? 180:props.via == 'WM'? 450: 450):
                      window.innerHeight == 1929 ?
                      ( props.via == 'EM'? 180:props.via == 'WM'? 450: 450):
                      window.innerHeight == 864 ?
                      ( props.via == 'EM'? 220:props.via == 'WM'? 450: 450):
                      window.innerHeight == 540 ?//office tv
                      ( props.via == 'EM'? 140:props.via == 'WM'? 250: 450):
                      window.innerHeight == 599 ?//pradeep browser view
                      ( props.via == 'EM'? 150:props.via == 'WM'? 280: 280):
                      window.innerHeight == 1280 ?//tab
                      ( props.via == 'EM'? 300:props.via == 'WM'? 650: 650):
                      window.innerHeight == 600 ?//hmi
                      ( props.via == 'EM'? 140:props.via == 'WM'? 290: 340):
                      window.innerHeight == 844 ?//iphone12
                      ( props.via == 'EM'? 180:props.via == 'WM'? 430: 425):
                      window.innerHeight == 633 ?//moses laptop
                      ( props.via == 'EM'? 140: props.via == 'WM'? 320: 120):
                      window.innerHeight == 900 ?//office desktop
                      ( props.via == 'EM'? 210:props.via == 'WM'? 440: 440):
                      window.innerHeight == 793 ?//one plus
                      ( props.via == 'EM'? 185:props.via == 'WM'? 390: 390):
                      window.innerHeight == 939 ?//z fold
                      ( props.via == 'EM'? 225:props.via == 'WM'? 450: 430):
                      window.innerHeight == 915 ?//s20 ultra
                      ( props.via == 'EM'? 210:props.via == 'WM'? 470: 520):
                      130} type="area"   />
          <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} classes={{ paper: classes.customDialog }}>
                  <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {text}
                  </DialogTitle>
                  <DialogContent dividers>
                  <ReactApexCharts options={dataset.options} series={dataset.series}  type="area" />
                  </DialogContent>
                </Dialog>
      </div>
      );
      }
  




    
