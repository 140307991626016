import React from 'react'

function FloorHumIcon() {
  return (
    <div><svg xmlns="http://www.w3.org/2000/svg"  width="10" height="16" viewBox="0 0 13.541 19.88">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_232" data-name="Rectangle 232" width="13.541" height="19.88"/>
      </clipPath>
    </defs>
    <g id="Group_391" data-name="Group 391" clip-path="url(#clip-path)">
      <path id="Path_356" data-name="Path 356" d="M4,19.41A6.136,6.136,0,0,1,1.86,18.066,5.924,5.924,0,0,1,.481,15.979,7.252,7.252,0,0,1,0,13.279a8.49,8.49,0,0,1,.216-1.9A14.588,14.588,0,0,1,.755,9.559q.323-.879.654-1.617.532-1.18,1.213-2.407T4.056,3.1Q4.806,1.887,5.538.77A1.963,1.963,0,0,1,6.069.2a1.325,1.325,0,0,1,1.4,0A2.055,2.055,0,0,1,8.01.77Q8.734,1.888,9.485,3.1t1.437,2.438q.685,1.229,1.209,2.407.33.74.658,1.617a13.866,13.866,0,0,1,.539,1.825,8.663,8.663,0,0,1,.212,1.9,7.267,7.267,0,0,1-.481,2.7,5.934,5.934,0,0,1-1.378,2.087A6.146,6.146,0,0,1,9.539,19.41a7.784,7.784,0,0,1-2.769.469A7.785,7.785,0,0,1,4,19.41m5.6-1.568a4.687,4.687,0,0,0,1.883-1.825,5.406,5.406,0,0,0,.674-2.738,7.536,7.536,0,0,0-.4-2.388q-.4-1.193-.874-2.28A26.582,26.582,0,0,0,9.031,5.046Q7.987,3.367,6.939,1.771a.191.191,0,0,0-.162-.111.173.173,0,0,0-.162.111q-1.056,1.6-2.1,3.274A27.512,27.512,0,0,0,2.649,8.62Q2.172,9.7,1.779,10.891a7.616,7.616,0,0,0-.393,2.388,5.406,5.406,0,0,0,.674,2.738,4.684,4.684,0,0,0,1.884,1.825,5.871,5.871,0,0,0,2.827.651A5.873,5.873,0,0,0,9.6,17.842" transform="translate(0 0)"/>
    </g>
  </svg>
  </div>
  )
}

export default FloorHumIcon