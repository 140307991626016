import React, { useEffect, useState } from "react";
import api from "../../api";
import Success from "components/Typography/Success";
import TimeSeries from "../TimeSeries.js";
import TimeSeries1 from "../TimeS";
import Devicetrend from "views/Devicetrend";
import { Grid, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
import { format, compareAsc } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    width: "100%",
  },
  paper: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    padding: theme.spacing(1),
    textAlign: "center",
    // color: theme.palette.text.secondary,
    // boxShadow: '0px 4px 20px #0123B41A',
    // backgroundColor: 'white',
    // borderRadius: '14px',
    borderRadius: "6px",
    boxShadow: "1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor: "#fcfafa",
    opacity: "1",
  },
  formControl: {
    autosize: true,
    clearable: false,
  },
  select: {
    "&:after": {
      borderBottomColor: "blue",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      backgroundColor: "#0123b4",
      borderRadius: "8px",
    },
    "& .MuiSelect-root ": {
      marginTop: "-2vh",
    },
  },
  headingFont: {
    "@media (min-width:0px) and (max-width:599.95px)": {
      //xs
      textAlign: "center",
      fontSize: "1.5vh",
      color: "black",
    },
    "@media (min-width:600px) and (max-width:959.95px)": {
      //sm
      textAlign: "center",
      fontSize: "1.6vh",
      color: "black",
    },
    "@media (min-width:960px) and (max-width:1279.95px)": {
      //md
      textAlign: "center",
      fontSize: "1.5vh",
      color: "black",
    },
    "@media (min-width:1280px) and (max-width:1919.95px)": {
      //lg
      textAlign: "center",
      fontSize: "1.8vh",
      whiteSpace: "nowrap",
      color: "black",
    },
    "@media (min-width:1920px) and (max-width:2559.95px)": {
      //xl
      textAlign: "center",
      fontSize: "1.8vh",
      color: "black",
      whiteSpace: "nowrap",
    },
  },
  statusFont: {
    "@media (min-width:0px) and (max-width:599.95px)": {
      //xs
      textAlign: "center",
      fontSize: "2.3vh",
      color: "black",
    },
    "@media (min-width:600px) and (max-width:959.95px)": {
      //sm
      textAlign: "center",
      fontSize: "2.5vh",
      color: "black",
    },
    "@media (min-width:960px) and (max-width:1279.95px)": {
      //md
      textAlign: "center",
      fontSize: "2.7vh",
      color: "black",
    },
    "@media (min-width:1280px) and (max-width:1919.95px)": {
      //lg
      textAlign: "center",
      fontSize: "2.5vh",
      whiteSpace: "nowrap",
      color: "black",
    },
    "@media (min-width:1920px) and (max-width:2559.95px)": {
      //xl
      textAlign: "center",
      fontSize: "2.3vh",
      color: "black",
      whiteSpace: "nowrap",
    },
  },
  graphpaper: {
    // height: "29vh",
    height: "58vh",
    padding: theme.spacing(1),
    textAlign: "center",
    // color: theme.palette.text.secondary,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // boxShadow: '0px 4px 20px #0123B41A;',
    // backgroundColor: 'white',
    boxShadow: "1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor: "#fcfafa",
    opacity: "1",
    borderRadius: "14px",
  },
}));

const getMyValue = (input, params = []) => {
  let test = -1;
  try {
    if (input !== undefined) test = input[params[0]];
    else return;
    if (test !== undefined) {
      for (let i = 1; i < params.length; i++) {
        if (params[i] !== undefined) test = test[params[i]];
        else break;
      }
    }
  } catch (e) {
    console.log("My Error in getMyValue: ", e.message);
  }
  return test;
};

function GlWaterMeter(props) {
  const classes = useStyles();
  const [dcvalue, setDC] = useState("");
  const [tcvalue, setTC] = useState("");
  const [para2, setPara2] = useState("");
  const [para3, setPara3] = useState("");
  const [para4, setPara4] = useState("");
  const [voltage_ln, setVoltageLN] = useState("");
  const [current, setCurrent] = useState("");
  const [freq, setFreq] = useState("");
  const [dacGraph, setDACGraph] = useState([]);
  const [tcGraph, setTCGraph] = useState([]);
  const [fdata, setFdata] = useState(localStorage.getItem("floorName"));
  const [floor, setFloor] = useState([]);
  const initialState1 =
    props.location.state.dev_id != null ? props.location.state.dev_id : "";
  const [deviceid, setDeviceid] = useState(initialState1);
  const initialState =
    props.location.state != null ? props.location.state.dev_name : "";
  const [data, setData] = useState(initialState);
  const [ahu1, setAhu1] = useState({});
  const [graph, setGraph] = useState([]);
  const [activeWM, setActiveWM] = useState(initialState1);
  const [watermeter, setWaterMeter] = useState([]);
  const zone_data = useSelector((state) => state.inDashboard.locationData);
  const buildingID = useSelector((state) => state.isLogged.data.building.id);
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

  const handlefloorchange = (name, id) => {
    setFdata(name);
    api.floor
      .devicemap(id, props.history.location.state.dev_type)
      .then((res) => {
        console.log('res in hFC', res.length, typeof res.length)
        if (res.length> 0) {
          res.sort(function (a, b) {
            return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
          });
          setWaterMeter(res);
        } else {
          console.log("in else")
          setWaterMeter([]);
          setData('')
          setActiveWM("");
          setDC("");
          setTC("");
          setPara2("");
          setPara3("");
          setPara4("");
        }
      })
      .catch((error) => {
        console.log("hii em in catch block of devicemap in HFC");
        setWaterMeter([]);
      });
  };

  const handleChange = (name, id) => {
    setActiveWM(id);
    setData(name);
    api.floor
      .EmsData(id)
      .then((res) => {
        if (res.length === 0) {
          setDC("");
          setTC("");
          setPara2("");
          setPara3("");
          setPara4("");
        } else {
          res.map((ele) => {
            if (ele.param_id === "DAC") {
              setDC(ele.param_value);
            } else if (ele.param_id === "TC") {
              setTC(ele.param_value);
            }
          });
        }
      })
      .catch((error) => {
        setDC("");
        setTC("");
        setPara2("");
        setPara3("");
        setPara4("");
      });
    // setData(name);

    api.floor
      .WMGraph(id)
      .then((res) => {
        console.log("Response we got for the graph", res);
        if (res.graphData.length >0) {
          if (res.graphData[0]["DAC"]) {
            setDACGraph(getMyValue(res, ["graphData", 0, "DAC"]));
          }
          if (res.graphData[0]["TC"]) {
            setTCGraph(getMyValue(res, ["graphData", 0, "TC"]));
          }
        }else{
          setDACGraph([]);
          setTCGraph([]);
        }
      })
      .catch((error) => {
        console.log("hii em in handle change WMgraphdata api catch block");
        setDACGraph([]);
        setTCGraph([]);
      });
  };

  useEffect(() => {
    let zone_id = "",
      z_data = [];
    zone_data.sort(function (a, b) {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    });
    // zone_data.filter((_each)=>_each.zone_type==='GL_LOCATION_TYPE_FLOOR')
    // if(fdata!== null){
      if(fdata!== null){
        zone_data.filter((_each,i) =>{
          if(_each.zone_type==='GL_LOCATION_TYPE_FLOOR'&& _each.name===fdata){
             return zone_id=_each.uuid
          }
        })
      } else {
        zone_data.filter((_each,i) =>{
          if(_each.zone_type==='GL_LOCATION_TYPE_FLOOR'){
            z_data.push(_each);
          }
        })
        zone_id=z_data[0].uuid
        setFdata(z_data[0].name)
        // setFId(zone_id[0].uuid)
      }
    api.floor
      .devicemap(zone_id, props.history.location.state.dev_type)
      .then((res) => {
        res.sort(function (a, b) {
          return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
        });
        setWaterMeter(res);
        if (deviceid == "" && data == "") {
          res.sort(function (a, b) {
            return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
          });
          setData(res[0].name);
          // setActiveWM(res[0].ssid);

          api.floor
            .EmsData(res[0].ssid)
            .then((res) => {
              if (res.length === 0) {
                setDC(0);
                setTC(0);
                setPara2(0);
                setPara3(0);
                setPara4(0);
              } else {
                res.map((ele) => {
                  if (ele.param_id === "DAC") {
                    setDC(ele.param_value);
                  } else if (ele.param_id === "TC") {
                    setTC(ele.param_value);
                  } else if (ele.param_id === "Parameter_2") {
                    setPara2(ele.param_value);
                  } else if (ele.param_id === "Parameter_3") {
                    setPara3(ele.param_value);
                  } else if (ele.param_id === "Parameter_4") {
                    setPara4(ele.param_value);
                  }
                });
              }
            })
            .catch((error) => {
              setDC("");
              setTC("");
              setPara2("");
              setPara3("");
              setPara4("");
              setVoltageLN("");
              setCurrent("");
              setFreq("");
            });
          api.floor
            .WMGraph(activeWM)
            .then((res) => {
              if (res.graphData.length >0) {
                if (res.graphData[0]["DAC"]) {
                  setDACGraph(getMyValue(res, ["graphData", 0, "DAC"]));
                }
                if (res.graphData[0]["TC"]) {
                  setTCGraph(getMyValue(res, ["graphData", 0, "TC"]));
                }
              }else{
                setDACGraph([]);
                setTCGraph([]);
              }
            })
            .catch((error) => {
              console.log(
                "hii em in handle change WMgraphdata api catch block"
              );
              setDACGraph([]);
              setTCGraph([]);
            });
        } else {
          api.floor
            .EmsData(activeWM)
            .then((res) => {
              if (res.length === 0) {
                setDC("");
                setTC("");
                setPara2("");
                setPara3("");
                setPara4("");
              } else {
                res.map((ele) => {
                  if (ele.param_id === "DAC") {
                    setDC(ele.param_value);
                  } else if (ele.param_id === "TC") {
                    setTC(ele.param_value);
                  } else if (ele.param_id === "Parameter_2") {
                    setPara2(ele.param_value);
                  } else if (ele.param_id === "Parameter_3") {
                    setPara3(ele.param_value);
                  } else if (ele.param_id === "Parameter_4") {
                    setPara4(ele.param_value);
                  }
                });
              }
            })
            .catch((error) => {
              setDC("");
              setTC("");
              setPara2("");
              setPara3("");
              setPara4("");
            });
          api.floor
            .WMGraph(activeWM)
            .then((res) => {
              console.log("Response we got for the graph", res);
              if (res.graphData.length >0) {
                if (res.graphData[0]["DAC"]) {
                  setDACGraph(getMyValue(res, ["graphData", 0, "DAC"]));
                }
                if (res.graphData[0]["TC"]) {
                  setTCGraph(getMyValue(res, ["graphData", 0, "TC"]));
                }
              }else{
                setDACGraph([]);
                setTCGraph([]);
              }
            })
            .catch((error) => {
              console.log(
                "hii em in handle change WMgraphdata api catch block"
              );
              setDACGraph([]);
              setTCGraph([]);
            });
        }
      })
      .catch((error) => {
        setWaterMeter([]);
        setData("");
        setActiveWM("");
      });

    api.dashboard
      .getMetricData(buildingID)
      .then((res) => {
        res.sort(function (a, b) {
          return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
        });
        // console.log("reeeeesss",res)
        console.log("ioiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii", res);

        setFloor(res);
      })
      .catch((error) => {
        setFloor([]);
      });

    const timer = setInterval(() => {
      api.floor
        .EmsData(activeWM)
        .then((res) => {
          if (res.length === 0) {
            setDC("");
            setTC("");
            setPara2("");
            setPara3("");
            setPara4("");
          } else {
            res.map((ele) => {
              if (ele.param_id === "DAC") {
                setDC(ele.param_value);
              } else if (ele.param_id === "TC") {
                setTC(ele.param_value);
              } else if (ele.param_id === "Parameter_2") {
                setPara2(ele.param_value);
              } else if (ele.param_id === "Parameter_3") {
                setPara3(ele.param_value);
              } else if (ele.param_id === "Parameter_4") {
                setPara4(ele.param_value);
              }
            });
          }
        })
        .catch((error) => {
          setDC("");
          setTC("");
          setPara2("");
          setPara3("");
          setPara4("");
        });
      api.floor
        .WMGraph(activeWM)
        .then((res) => {
          console.log("Response we got for the graph", res);
          if (res.graphData.length>0) {
            if (res.graphData[0]["DAC"]) {
              setDACGraph(getMyValue(res, ["graphData", 0, "DAC"]));
            }
            if (res.graphData[0]["TC"]) {
              setTCGraph(getMyValue(res, ["graphData", 0, "TC"]));
            }
          }else{
            setDACGraph([]);
            setTCGraph([]);
          }
        })
        .catch((error) => {
          console.log("hii em in handle change WMgraphdata api catch block");
          setDACGraph([]);
          setTCGraph([]);
        });
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, [activeWM]);

  const numEmptyCards = 6 - 2; // Adjust the total number as needed
  const emptyCardsWaterMeter = Array.from(
    { length: numEmptyCards },
    (_, index) => (
      <Grid item xs={6} sm={2} md lg xl xxl>
        <Card
          style={{
            boxShadow: "1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
            backgroundColor: "#fcfafa",
            "border-radius": "10px",
            height: "12vh",
          }}
        ></Card>
      </Grid>
    )
  );

  return (
    <div className={classes.root} style={{ marginTop: "0%" }}>
      <Grid container xs={12} spacing={2}>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5} xxl={5}>
            <FormControl
              variant="filled"
              size="large"
              className={classes.formControl}
              style={{
                width: "max-content",
                minWidth: "100%",
                backgroundColor: "#eeeef5",
                fontFamily: "Arial",
              }}
            >
              <Select
                labelId="filled-hidden-label-small"
                id="demo-simple-select-outlined"
                label="Floor"
                className={classes.select}
                style={{
                  fontWeight: "bold",
                  height: "6vh",
                  borderRadius: "0.8vw",
                  fontFamily: "Arial",
                }}
                value={fdata || ""}
                disableUnderline
              >
                {floor.map((_item) => (
                  <MenuItem
                    key={_item.id}
                    value={_item.name}
                    onClick={() => handlefloorchange(_item.name, _item.id)}
                  >
                    {_item.name === "GVFloor-01" ? "Floor-17" : _item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
            <FormControl
              variant="filled"
              size="large"
              className={classes.formControl}
              style={{
                width: "max-content",
                minWidth: "100%",
                backgroundColor: "#eeeef5",
                fontFamily: "Arial",
              }}
            >
              <Select
                labelId="filled-hidden-label-small"
                id="demo-simple-select-outlined"
                label="EnergyMeter"
                className={classes.select}
                style={{
                  fontWeight: "bold",
                  height: "6vh",
                  borderRadius: "0.8vw",
                  fontFamily: "Arial",
                }}
                value={data || ""}
                disableUnderline
              >
                {watermeter.map((_item) => (
                  <MenuItem
                    key={_item.ssid}
                    value={_item.name}
                    onClick={() => handleChange(_item.name, _item.ssid)}
                  >
                    {_item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} spacing={2}>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Card className={`${classes.paper}`}>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={6} sm={2} md lg xl xxl>
                  <Card
                    style={{
                      boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",
                      backgroundColor: "#fcfafa",
                      "border-radius": "10px",
                      height: "12vh",
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      direction="column"
                      style={{
                        "justify-content": "center",
                        "align-content": "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Grid
                        container
                        item
                        xs={12}
                        spacing={1}
                        direction="column"
                        alignItems="center"
                      >
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                          {" "}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          xxl={6}
                          className={classes.headingFont}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          Daily Consumption{" "}
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          sm={5}
                          md={5}
                          lg={5}
                          xl={5}
                          xxl={5}
                          className={classes.statusFont}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Success>
                            {formatter.format(dcvalue)} Liters
                          </Success>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={2} md lg xl xxl>
                  <Card
                    style={{
                      boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",
                      backgroundColor: "#fcfafa",
                      "border-radius": "10px",
                      height: "12vh",
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      direction="column"
                      style={{
                        "justify-content": "center",
                        "align-content": "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Grid
                        container
                        item
                        xs={12}
                        spacing={1}
                        direction="column"
                        alignItems="center"
                      >
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                          {" "}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          xxl={6}
                          className={classes.headingFont}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          Total Consumption{" "}
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          sm={5}
                          md={5}
                          lg={5}
                          xl={5}
                          xxl={5}
                          className={classes.statusFont}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Success>{formatter.format(tcvalue)} Liters</Success>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                {emptyCardsWaterMeter}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} spacing={2} style={{ marginTop: "0.5vh" }}>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <Card className={classes.graphpaper}>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      style={{
                        fontSize: "15px",
                        color: "black",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <b>Daily Consumption[Liters]</b>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      {Object.keys(dacGraph).length == 0 ? (
                        <>
                          {/* <h4 style={{marginTop:"62px",marginLeft:"80px"}}>No data available</h4> */}
                          <h4 style={{ marginTop: "44px", marginLeft: "5px" }}>
                            No data available
                          </h4>
                        </>
                      ) : (
                        <>
                          <TimeSeries data={dacGraph} via ='WM' param="Daily Consumption [Liters]" />
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <Card className={classes.graphpaper}>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      style={{
                        fontSize: "15px",
                        color: "black",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <b>Total Consumption[Liters]</b>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      {Object.keys(tcGraph).length == 0 ? (
                        <>
                          {/* <h4 style={{marginTop:"62px",marginLeft:"80px"}}>No data available</h4> */}
                          <h4 style={{ marginTop: "44px", marginLeft: "5px" }}>
                            No data available
                          </h4>
                        </>
                      ) : (
                        <>
                          <TimeSeries data={tcGraph} via ='WM' param="Total Consumption [Liters]" />
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default GlWaterMeter;
