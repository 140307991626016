import React from "react";
import ReactApexCharts from "react-apexcharts";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { LogarithmicScale } from "chart.js";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  customDialog: {
    // Set the desired width for the dialog
    width: "700px", // Adjust this value as needed
  },
}));

function Devicetrend(props) {
  const classes = useStyles();
  const data = props.data;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const text = props.param;
  let tempArr = [];
  data.map((obj) => {
    let tempData = {};
    tempData.x = obj.measured_time;
    tempData.y = obj.param_value;
    tempData.goals = [];

    tempArr.push(tempData);
  });
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });
  const dataset = {
    series: [
      {
        // name: text.slice(3).charAt().toLocaleUpperCase()+text.slice(4),
        name: text,
        data: tempArr,
      },
    ],
    options: {
      title: {
        // text:text.slice(3).charAt().toLocaleUpperCase()+text.slice(4),
      },

      chart: {
        type: "bar",
        width: "100%",
        events: {
          click: function (event, chartContext, config) {
            onclickchart();
          },
        },
        zoom: {
          enabled: true,
        },

        // color:'rgb(38 231 166)',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        type: "",
        tickPlacement: "on",
        labels: {
          formatter: function (value) {
            // Define your logic to skip certain values
            // For example, skip every second value
            if (value % 2 === 0) {
              return value; // Show the value
            }
            return ""; // Skip the value
          },
        },
      },
      yaxis: {
        show: true,
        showAlways: true,
        // min: 0,
        tickAmount: 5,
        forceNiceScale: true,
        //  max: 25,
        decimalsInFloat: true,
        labels: {
          formatter: function (val) {
            return val.toFixed(2);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      colors: ["#00E396"],
      tooltip: {
        y: {
          formatter: function (val) {
            return formatter.format(val);
          },
        },
      },
    },
  };
  const onclickchart = () => {
    handleClickOpen(true);
  };

  return (
    <GridContainer>
      <GridItem>
        <ReactApexCharts
          options={dataset.options}
          series={dataset.series}
          type="bar"
          height={
            window.innerHeight == 641 ?
            150:
            window.innerHeight == 768 ?
            180:
            window.innerHeight == 1080 ?
            240:
            window.innerHeight == 1920 ?
            740:
            window.innerHeight == 864 ?
            220:
            window.innerHeight == 599 ?//pradeep browser view
            150:
            window.innerHeight == 1280 ?//tab
            300:
            window.innerHeight == 600 ?//hmi
            140:
            window.innerHeight == 844 ?//iphone12
            180:
            window.innerHeight == 633 ?//moses laptop
            130:
            window.innerHeight == 900 ?//office desktop
            210:
            window.innerHeight == 793 ?//one plus
            185:
            window.innerHeight == 939 ?//z fold
            225:
            window.innerHeight == 915 ?//s20 ultra
            210:
            'auto'
          }
          style={{ width: "22vw" }}
        />
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          classes={{ paper: classes.customDialog }}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {text}
          </DialogTitle>
          <DialogContent dividers>
            <ReactApexCharts
              options={dataset.options}
              series={dataset.series}
              type="bar"
            />
          </DialogContent>
        </Dialog>
      </GridItem>
      {/* <GridItem>
        <ReactApexCharts options={dataset2.options} series={dataset2.series} type="line" height={200} />
        </GridItem> */}
    </GridContainer>
  );
}

export default Devicetrend;
