import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactEcharts from 'echarts-for-react';
import api from '../../api'; // Assuming you are using a custom API handler
import { useCenteredTree } from '../../helper'; // Assuming this is a helper for tree positioning
import 'echarts/lib/chart/tree';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';

function prepareEquipmentParameters(eqpData = [], params = ['Act_Pwr_Ttl', 'PF_Ttl', 'Volt_LL_Avg', 'Volt_LN_Avg', 'Cur_Avg']) {
  if (!Array.isArray(eqpData)) return '';

  const filteredParams = eqpData
    .filter(item => params.includes(item.param_id))
    .map(item => {
      let paramValue = item.param_value;
      let paramId = item.param_id;

      // Replace parameter IDs with user-friendly names and add units
      switch (paramId) {
        case 'Act_Pwr_Ttl':
          paramId = 'ACTIVE POWER';
          paramValue += ' kW';
          break;
        case 'PF_Ttl':
          paramId = 'PF';
          break;
        case 'Volt_LL_Avg':
          paramId = 'AVG VL_L';
          paramValue += ' V';
          break;
        case 'Cur_Avg':
          paramId = 'AVG CURRENT';
          paramValue += ' A';
          break;
        case 'Volt_LN_Avg':
          paramId = 'AVG VL_N';
          paramValue += ' V';
          break;
        case 'VAV_ZAT_SP':
        case 'VAV_ZAT':
          paramValue += ' °C';
          break;
        case 'DAC':
          paramValue += ' ltr';
          break;
        case 'TC':
          paramValue += ' ltr/day';
          break;
        case 'CWH_RT_SP':
        case 'CWH_ST_SP':
          break;
      }

      return `<strong style="color: #333;">${paramId}</strong> : ${paramValue}`;
    })
    .join('<br/>');

  return filteredParams;
}

function NetworkDiagram() {
  const [translate] = useCenteredTree();
  const [orgChart, setOrgChart] = useState(null);
  const chartRef = useRef(null);
  const [clickedItem, setClickedItem] = useState('');
  const [type, setType] = useState(null);
  const [ss_type, setSs_type] = useState(null);
  const [onEquipmentClick, setOnEquipmentClick] = useState([]);
  const [isNodeClicked, setIsNodeClicked] = useState(false);
  const [itemsParams, setItemsParams] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const setInitialExpandedNodes = (node) => {
    if (node.children) {
      node.children = node.children.map(setInitialExpandedNodes);
    }
    node.collapsed = true;
    return node;
  };

  const fetchData = useCallback(async () => {
    try {
      if (process.env.REACT_APP_ENVIRONMENT === 'cloud') {
        const response = await fetch('/material-dashboard-react/data.json', {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });
        const myJson = await response.json();
        const rootNode = setInitialExpandedNodes(myJson.results);
        setOrgChart(rootNode);
      } else {
        if (clickedItem === '') {
          const res = await api.networkStatus.getNetworkStatus();
          const rootNode = setInitialExpandedNodes(res.results);
          setOrgChart(rootNode);
        } else {
          const res = await api.floor.networkDiagramEqpparameters(clickedItem);
          const itemPresent = res.find((item) => item.ss_id === clickedItem);
          setOnEquipmentClick(itemPresent);

          // Check the type and set itemsParams accordingly
          if (type === 'NONGL_SS_EMS') {
            setItemsParams(prepareEquipmentParameters(res));
          } else if (ss_type === 'NONGL_SS_VAV') {
            setItemsParams(prepareEquipmentParameters(res, ["VAV_ZAT_SP", "VAV_ZAT"]));
          } else if (ss_type === 'NONGL_SS_WM') {
            setItemsParams(prepareEquipmentParameters(res, ["DAC", "TC"]));
          } else if (ss_type === 'NONGL_SS_CHILLER') {
            setItemsParams(prepareEquipmentParameters(res, ['CWH_RT_SP', 'CWH_ST_SP', 'CndW_Flow_SS', 'CH_Trip_SS', 'CH_Vlv_On_Off', 'CWH_Flow', 'CHW_SP']));
          } else if (ss_type === 'NONGL_SS_AHU') {
            setItemsParams(prepareEquipmentParameters(res, ['SAT', 'SAT_SP', 'RAT', 'RAT_SP', 'DSP', 'DSP_SP', 'ZAT']));
          }
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [clickedItem, type, ss_type]);

  useEffect(() => {
    fetchData(); // Initial fetch
    const intervalId = setInterval(() => {
      if (clickedItem) {
        fetchData(); // Fetch data only if an item is clicked
      }
    }, 120000); // 120000 ms = 2 minutes

    return () => clearInterval(intervalId);
  }, [fetchData, clickedItem]);

  const handleNodeToggle = (nodeId, type, ss_type) => {
    setType(type || null);
    setSs_type(ss_type || null);
    setClickedItem(nodeId);
    
    const toggleNodeState = (node) => {
      if (node.id === nodeId && node.children) {
        node.collapsed = !node.collapsed;
      }
      if (node.children) {
        node.children = node.children.map(toggleNodeState);
      }
      return node;
    };

    setOrgChart((prevOrgChart) => toggleNodeState({ ...prevOrgChart }));
    
    // Show tooltip immediately
    setTooltipVisible(true);
  };

  return (
    <>
      <ReactEcharts
        ref={chartRef}
        option={{
          tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove',
            show: tooltipVisible, // Control visibility with state
            formatter: (params) => {
              if (isNodeClicked && clickedItem) {
                return `<div style="text-align: left; font-size: 12px; line-height: 1.5;">
                  ${itemsParams}
                </div>`;
              }
              return '';
            },
          },
          series: [
            {
              type: 'tree',
              data: orgChart ? [orgChart] : [],
              top: '10%',
              left: '10%',
              bottom: '10%',
              right: '10%',
              symbolSize: 15,
              lineStyle: { color: 'black' },
              label: {
                position: 'top',
                verticalAlign: 'bottom',
                align: 'center',
                fontSize: 12,
              },
              leaves: {
                label: {
                  position: 'right',
                  verticalAlign: 'bottom',
                  align: 'center',
                },
              },
              emphasis: {
                focus: 'descendant',
              },
              expandAndCollapse: true,
              initialTreeDepth: -1,
              animationDuration: 550,
              animationDurationUpdate: 750,
              verticalSpacing: 40,
            },
          ],
        }}
        style={{ height: '600px', width: '100%' }}
        onEvents={{
          click: (params) => {
            setIsNodeClicked(true);
            handleNodeToggle(params.data.id, params.data.type, params.data.ss_type);
          },
          mouseout: () => {
            setIsNodeClicked(false);
            setTooltipVisible(false); // Hide tooltip on mouse out
          },
        }}
      />
    </>
  );
}

export default NetworkDiagram;