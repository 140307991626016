
import React, { useEffect, useState } from "react";
import { useSelector} from "react-redux";
import {  makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Card,
  ButtonBase,TextField,Paper
} from "@material-ui/core";
import pumpimg from './../../assets/img/PrimaryPump.png';
import priimg from './../../assets/img/Primary_Pump.png';
import secimg from './../../assets/img/Secondary_Pump.png';
import fanimg from './../../assets/img/AHU-fan-img.png';
import SwitchSelector from "react-switch-selector";
import api from "../../api";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import value from "views/Custom/value";
import Alert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";
import { redColor, yellowColor, greenColor, whiteColor, grayColor, blackColor, blueColor, hexToRgb } from "assets/jss/material-dashboard-react.js";
import { SemanticToastContainer, toast } from "react-semantic-toasts";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    width: "100%",
  },
  paper: {
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow:'0px 8px 40px #0123B433;',
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
     borderRadius: '12px',
     opacity:'1'
  },
  paper1:{
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow:'0px 0px 10px #0123B421',
    opacity:'1',
    borderRadius:'12px',
    height:'14vh',
    // display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center'
  },
  formControl: {
    autosize: true,
    clearable: false,
  }, 
  CardbodyInsideGrid: {
    "justify-content": "center",
    display: "inline-flex",
    padding: "0.9375rem 20px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
  },
  status:{
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    height: '100%' 
  },
  cardHeading:{
    display:'flex',
    justifyContent:'center',
    alignItems:"center",
    whiteSpace:"nowrap",
    color:"#000000",
    marginTop:'1vh',
    font:'normal normal medium 17px/60px Bw Seido Round',
    opacity:'1',
    fontWeight:'bold'
  },
  switchselector:{
    display:'flex',
    justifyContent:'center',
    width:"16vh",
    marginTop:'1vh',
  },
  semicircleBar:{
    display:'flex',
    justifyContent:'center',
    alignItems:"center",
    marginTop:'-0.8vh'
  },
  cardbody:{
    display:'flex',
    justifyContent:'center',
    alignItems:"center",
    fontSize:"4vh",
    fontWeight:'bold',
    opacity:'1',
    color:blueColor[0]
  }
}));

function GlPump(props) {
  const classes = useStyles();
  let pageType = props.pageType;
  const [floor, setFloor] = useState([]);
  const [fdata, setFdata] = useState(localStorage.getItem("floorName"));
  const buildingID = useSelector((state) => state.isLogged.data.building.id);
  const [disable, setDisable] = useState(false);
  const [allPumpsData, setAllPumpsData] = useState([]);
  const [eachPumpsData, setEachPumpsData] = useState({});
  const [runHours, setRunHours] = useState("");
  const [pumpOn_Off, setPumpOn_Off] = useState(0);
  const [am_SS, setAM_SS] = useState('');
  const [zat, setZAT] = useState('');
  const [sat, setSat] = useState('');
  const [errAlarm, setErrAlarm] = useState('');
  const [opn_SS, setOpn_SS] = useState(0);
  const [pumpStatusColor, setPumpStatusColor] = useState(0);
  const [click, setClick] = useState("");
  const [openerr,setOpenerr] = React.useState(false);
  const [errmsg,setErrmsg] = React.useState('');
  const CPM_Status = localStorage.getItem("CPM_AM_Status");
  const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  const options1 = [
    {
      label: "Card View",
      value: "cardview",
      selectedBackgroundColor: blueColor[0],
    },
    {
      label: "Table View",
      value: "tableview",
      selectedBackgroundColor: blueColor[0],
    },
  ];
  const options = [
    {
      label: "OFF",
      value: 0,
      selectedBackgroundColor: blueColor[0],
      fontSize:"12"

    },
    {
      label: "ON",
      value: 1,
      selectedBackgroundColor: "#34C759",
      fontSize:"12"
    },
  ];
  const optionsBms = [
      {
        label: "OFF",
        value: 0,
        selectedBackgroundColor: grayColor[0],
        fontSize:"10"
  
      },
      {
        label: "ON",
        value: 1,
        selectedBackgroundColor: "#34C759",
        fontSize:"10"
      },
      {
        label: "AUTO",
        value: 2,
        selectedBackgroundColor: "#0123B4",
        fontSize:"10"
      },
    ];
//   const initialSelectedIndex = options.findIndex(
//     ({ value }) => value === "active"
//   );
  const initialSelectedIndex1 = options1.findIndex(
    ({ value }) => value === "manual"
  );

  // const onClickIssue = () => {};
  useEffect(() => {
        if(props){
                  if(props.location !== undefined){
                        if(props.location.state.click == 'heading'){
                              setClick('heading')
                              setAllPumpsData(props.location.state.data)
                        }
                  }else{
                              setClick('eachPump')
                              setEachPumpsData(props.data)
                              if(props.type == 'Primary Pump'){
                                    if(props.data.Eqp_Metrics){
                                          setRunHours(props.data.Eqp_Metrics.rh_cumulative)
                                    }
                                    if(props.data.Eqp_Attributes){
                                          setPumpOn_Off((props.data["Eqp_Attributes"]["Pri_Pmp_On_Off"].presentValue)=="active"? 1:0)
                                    }
                                    if(props.data.Eqp_Attributes.Pri_Pmp_On_Off != undefined){
                                          setPumpStatusColor((props.data["Eqp_Attributes"]["Pri_Pmp_On_Off"]))
                                    }
                              }
                              else if(props.type == 'Secondary Pump'){
                                    if(props.data.Eqp_Metrics){
                                          setRunHours(props.data.Eqp_Metrics.rh_cumulative)
                                    }
                                    if(props.data.Eqp_Attributes){
                                          setPumpOn_Off((props.data.Eqp_Attributes.Sec_Pmp_SS.presentValue) =="active"? 1:0)
                                    }
                                    if(props.data.Eqp_Attributes.Sec_Pmp_SS != undefined){
                                          setPumpStatusColor((props.data["Eqp_Attributes"]["Sec_Pmp_SS"]))
                                    }
                              }
                              else if(props.type == 'Condenser Pump'){
                                    if(props.data.Eqp_Metrics){
                                          setRunHours(props.data.Eqp_Metrics.rh_cumulative)
                                    }
                                    if(props.data.Eqp_Attributes){
                                          setPumpOn_Off((props.data.Eqp_Attributes.Cnd_Pmp_SS.presentValue) =="active"? 1:0)
                                    }
                                    if(props.data.Eqp_Attributes.Cnd_Pmp_SS != undefined){
                                          setPumpStatusColor((props.data["Eqp_Attributes"]["Cnd_Pmp_SS"]))
                                    }
                              }
                              else if(props.type == 'Ventilator'){
                                    if(props.data['controlable']['VEN_AM_SS']){
                                          setAM_SS(props.data['controlable']['VEN_AM_SS'])
                                    }
                                    if(props.data['controlable']['VEN_ZAT']){
                                          setZAT(props.data['controlable']['VEN_ZAT'])
                                    }
                                    if(props.data['controlable']['VEN_Error_Alarm']){
                                          setErrAlarm(props.data['controlable']['VEN_Error_Alarm'])
                                    }
                                    if(props.data['controlable']['VEN_Opn_SS']){
                                          setOpn_SS(props.data['controlable']['VEN_Opn_SS'])
                                    }
                                   
                              }
                              else if(props.data.type == 'SS_BRE_FAN'){
                                    if(props.data['controlable']['BRE_AM_SS']){
                                          setAM_SS(props.data['controlable']['BRE_AM_SS'])
                                    }
                                    // if(props.data['controlable']['SubE_ZAT']){
                                    //       setZAT(props.data['controlable']['SubE_ZAT'])
                                    // }
                                    if(props.data['controlable']['BRE_Error_Alarm']){
                                          setErrAlarm(props.data['controlable']['BRE_Error_Alarm'])
                                    }
                                    if(props.data['controlable']['BRE_Opn_SS']){
                                          setOpn_SS(props.data['controlable']['BRE_Opn_SS'])
                                    }
                              }
                              else if(props.data.type == 'SS_HTE_FAN'){
                                    if(props.data['controlable']['HTE_AM_SS']){
                                          setAM_SS(props.data['controlable']['HTE_AM_SS'])
                                    }
                                    if(props.data['controlable']['HTE_ZAT']){
                                          setZAT(props.data['controlable']['HTE_ZAT'])
                                    }
                                    if(props.data['controlable']['HTE_Error_Alarm']){
                                          setErrAlarm(props.data['controlable']['HTE_Error_Alarm'])
                                    }
                                    if(props.data['controlable']['HTE_Opn_SS']){
                                          setOpn_SS(props.data['controlable']['HTE_Opn_SS'])
                                    }
                              }
                              else if(props.data.type == 'SS_SUBE_FAN'){
                                    if(props.data['controlable']['HTE_AM_SS']){
                                          setAM_SS(props.data['controlable']['HTE_AM_SS'])
                                    }
                                    if(props.data['controlable']['HTE_ZAT']){
                                          setZAT(props.data['controlable']['HTE_ZAT'])
                                    }
                                    if(props.data['controlable']['HTE_Error_Alarm']){
                                          setErrAlarm(props.data['controlable']['HTE_Error_Alarm'])
                                    }
                                    if(props.data['controlable']['HTE_Opn_SS']){
                                          setOpn_SS(props.data['controlable']['HTE_Opn_SS'])
                                    }
                              }
                              else if(props.type == 'Ventilator'){
                                    if(props.data['controlable']['VEN_AM_SS']){
                                          setAM_SS(props.data['controlable']['VEN_AM_SS'])
                                    }
                                    if(props.data['controlable']['VEN_ZAT']){
                                          setZAT(props.data['controlable']['VEN_ZAT'])
                                    }
                                    if(props.data['controlable']['VEN_Error_Alarm']){
                                          setErrAlarm(props.data['controlable']['VEN_Error_Alarm'])
                                    }
                                    if(props.data['controlable']['VEN_Opn_SS']){
                                          setOpn_SS(props.data['controlable']['VEN_Opn_SS'])
                                    }
                                    if(props.data['controlable']['VEN_On_Off']){
                                          setPumpOn_Off(props.data['controlable']['VEN_On_Off'] =='active'?1:0)
                                    }
                                   
                              }
                              else if(props.data.type == 'SS_BRE_FAN'){
                                    if(props.data['controlable']['BRE_AM_SS']){
                                          setAM_SS(props.data['controlable']['BRE_AM_SS'])
                                    }
                                    // if(props.data['controlable']['SubE_ZAT']){
                                    //       setZAT(props.data['controlable']['SubE_ZAT'])
                                    // }
                                    if(props.data['controlable']['BRE_Error_Alarm']){
                                          setErrAlarm(props.data['controlable']['BRE_Error_Alarm'])
                                    }
                                    if(props.data['controlable']['BRE_Opn_SS']){
                                          setOpn_SS(props.data['controlable']['BRE_Opn_SS'])
                                    }
                                    if(props.data['controlable']['BRE_Fan_On_Off']){
                                          setPumpOn_Off(props.data['controlable']['BRE_Fan_On_Off'] =='active'?1:0)
                                    }
                              }
                              else if(props.data.type == 'SS_HTE_FAN'){
                                    if(props.data['controlable']['HTE_AM_SS']){
                                          setAM_SS(props.data['controlable']['HTE_AM_SS'])
                                    }
                                    if(props.data['controlable']['HTE_ZAT']){
                                          setZAT(props.data['controlable']['HTE_ZAT'])
                                    }
                                    if(props.data['controlable']['HTE_Error_Alarm']){
                                          setErrAlarm(props.data['controlable']['HTE_Error_Alarm'])
                                    }
                                    if(props.data['controlable']['HTE_Opn_SS']){
                                          setOpn_SS(props.data['controlable']['HTE_Opn_SS'])
                                    }
                                    if(props.data['controlable']['HTE_On_Off']){
                                          setPumpOn_Off(props.data['controlable']['HTE_On_Off'] =='active'?1:0)
                                    }
                              }
                              else if(props.data.type == 'SS_SUBE_FAN'){
                                    if(props.data['controlable']['HTE_AM_SS']){
                                          setAM_SS(props.data['controlable']['HTE_AM_SS'])
                                    }
                                    if(props.data['controlable']['HTE_ZAT']){
                                          setZAT(props.data['controlable']['HTE_ZAT'])
                                    }
                                    if(props.data['controlable']['HTE_Error_Alarm']){
                                          setErrAlarm(props.data['controlable']['HTE_Error_Alarm'])
                                    }
                                    if(props.data['controlable']['HTE_Opn_SS']){
                                          setOpn_SS(props.data['controlable']['HTE_Opn_SS'])
                                    }
                                    if(props.data['controlable']['SubE_Fan_On_Off']){
                                          setPumpOn_Off(props.data['controlable']['SubE_Fan_On_Off'] =='active'?1:0)
                                    }
                              }
                  }
        }
  }, []);

  const onChange = (newValue) => {
      setDisable(true)
      setTimeout(() => { setDisable(false) }, 30000);
            if(pageType == 'BMS'){
                  setOpn_SS(newValue)
            }else{
                  setPumpOn_Off(newValue)
            }
            console.log("ss_type passing for payload",props.type)
        let ss_type= (props.type == "Primary Pump"? "NONGL_SS_PUMPS": props.type == "Secondary Pump"? "NONGL_SS_SECONDARY_PUMPS":props.type == 'Condenser Pump'? "NONGL_SS_CONDENSER_PUMPS":props.type == "Ventilator"?"SS_VENTILATOR_1":props.type == "Exhaust Fan"?(props.data.type == "SS_BRE_FAN"? "SS_BRE_FAN":props.data.type == "SS_HTE_FAN"? "SS_HTE_FAN":props.data.type == "SS_SUBE_FAN"? "SS_SUBE_FAN":""):""
             );

        const req={
            "ss_type":ss_type,
            "ss_id":String(props.data.id),
            "gl_command": newValue == 0? "TURN_OFF" :newValue == 1?"TURN_ON" :"AUTO",
            "param_id": props.type == "PrimaryPump"? "Pri_Pmp_On_Off" :props.type == "SecondaryPump"?       "Sec_Pmp_On_Off" :props.type == "Ventilator"? "VEN_On_Off" :
                  props.type == "Exhaust Fan"?(props.data.type == "SS_BRE_FAN"? "BRE_Fan_On_Off" :props.data.type == "SS_HTE_FAN"? "HTE_On_Off" :props.data.type == "SS_SUBE_FAN"? "SubE_Fan_On_Off":"") :"",
            "value":newValue === 1 ? "ON" : newValue === 0 ? "OFF" : "Auto",
            "zone_type":null,
            "zone_id":null,
            "commandFrom":"UI",
        }
      // console.log("iddddddd",id,props.data)
        api.floor.cpmOnOffControl(req).then((response)=>{
            if(pageType != 'BMS'){
                  console.log("qqqqqqqqqqqqq",response)
                  toast({
                        type:"success",
                        icon:"check circle",
                        title:"Success",
                        description: "Controlled Successfully",
                        time: 3000,
                      })
            }else{
            setPumpOn_Off(response.startsWith('Working with a Scenario')?(newValue == 0?1:0):newValue)
            toast({
                  type: response.startsWith('Working with a Scenario')?"error":"success",
                  icon: response.startsWith('Working with a Scenario')?"exclamation triangle":"check circle",
                  title: response.startsWith('Working with a Scenario')?"Error":"Success",
                  description: response,
                  time: 2000,
                })
            }
        })
        .catch((err)=>{
            setOpenerr(true)
            setErrmsg(err)          
        })
      };

      const handleChangeForsetpointSAT = (event) => {
            setSat(event.target.value);
          };

          const handleClickSat = (event) => {
            // const req = {
            //   param_id: "SAT_SP",
            //   param_value: setSat,
            //   user_id: localStorage.userID,
            // };
            const req =  {
              ss_type: props.type == 'Ventilator'? "SS_VENTILATOR_1": props.data.type == 'SS_BRE_FAN'?'SS_BRE_FAN':props.data.type == 'SS_HTE_FAN'? 'SS_HTE_FAN': props.data.type == 'SS_SUBE_FAN'? 'SS_SUBE_FAN' :'',
              ss_id: props.data.id,
              gl_command: "CHANGE_SET_POINT",
              param_id: props.type == 'Ventilator'? "VEN_ZAT": props.data.type == 'SS_BRE_FAN'?"": props.data.type == 'SS_HTE_FAN'?"HTE_ZAT":props.data.type == 'SS_SUBE_FAN'?"SubE_ZAT":"",
              value: sat,
              zone_type: null,
              zone_id: null
          }
            if (sat >= 15 && sat <= 35) {
              api.floor
                .cpmOnOffControl(req)
                .then((res) => {
                  // setSAtvalue("");
                  if (res.message === "please connect to network") {
                    toast({
                      type: "error",
                      icon: "exclamation triangle",
                      title: "Error",
                      description: "connect to network",
                      time: 2000,
                    });
                  } else if (res.id) {
                    let requestID = res.id;
                    toast({
                      type: "success",
                      icon: "check circle",
                      title: "Success",
                      description: "Sat successfully setted" + sat,
                      time: 2000,
                    });
                    const checkCommandStatus = (requestID, startTime = Date.now()) => {
                      api.floor.checkCommandStatus(requestID).then((res) => {
                        if (res[0].status === 'success') {
                          // Command was successful, stop further API calls
                          console.log("Command succeeded");
                          toast({
                            type: "success",
                            icon: "check circle",
                            title: "Command Status",
                            description: "Command processed successfully",
                            time: 2000,
                          });
                        } else if (res[0].status === 'pending') {
                          console.log("Command is still Pending")
                          const elapsedTime = Date.now() - startTime;
                    
                          if (elapsedTime < 30000) {
                            console.log(" If less than 30 seconds have passed, keep checking every 3 seconds")
                            setTimeout(() => checkCommandStatus(requestID, startTime), 3000);
                          } else {
                            console.log("Stop checking after 30 seconds and show a timeout message")
                            console.log("Command timed out after 30 seconds.");
                            toast({
                              type: "error",
                              icon: "clock",
                              title: "Timeout",
                              description: "Command is still pending after 30 seconds.",
                              time: 5000,
                            });
                          }
                        }
                      }).catch((error) => {
                        console.error("Error checking command status:", error);
                        toast({
                          type: "error",
                          icon: "exclamation triangle",
                          title: "Error",
                          description: "Error while checking command status",
                          time: 2000,
                        });
                      });
                    };
                    
                    checkCommandStatus(requestID);
                  }
                })
                .catch((error) => {
                  setOpenerr(true)
                  if(error.response){
                    setErrmsg(error.response.data.message)
                    }else{
                      setErrmsg('No response')
                    }    })
            } else {
              // setSAtvalue("");
              toast({
                type: "error",
                icon: "exclamation triangle",
                title: "Error",
                description: "SAT sp should be 15-35 ",
                time: 2000,
              });
            }
          };

      const handleerrorclose = () => {
            setOpenerr(false);
            setErrmsg('');
          };

      return (
    <div className={classes.root} style={{ marginTop: "0%" }}>
      <Snackbar open={openerr} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert style={{ cursor: "pointer" }} severity="error" variant="filled" onClose={handleerrorclose}>
          {errmsg}
        </Alert>
      </Snackbar>
        <Grid container spacing={2}>
            <Grid container spacing={2}>
                  { 
                  click == 'heading' ? <>
                  { allPumpsData.map((response) =>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                        <Card className={classes.paper} style={{height:"41.5vh"}}>
                        <Grid container xs={12} spacing={1} style={{marginTop:'0.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} direction="column">
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{color:'black',fontWeight:'bold',fontSize:'3vh',whiteSpace:'nowrap'}}>{response.name}</div></Grid>
                              </Grid>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                                          <div className={classes.switchselector} style={{height:'4vh',marginTop:'0.5vh',pointerEvents:CPM_Status == "true"?"none":"", opacity:CPM_Status == "true"?"0.4":""}}>
                                          <SwitchSelector
                                          style={{ borderRadius: "12px" }}
                                          onChange={onChange}
                                          options={options}
                                          // initialSelectedIndex={initialSelectedIndex}
                                          // border="1px solid #0123B4"
                                          backgroundColor={"#e9e5e5"}
                                          fontColor={"rgba(0, 0, 0, 0.87)"}
                                          // wrapperBorderRadius={true}
                                          optionBorderRadius={5}
                                          wrapperBorderRadius={5} />
                                          </div>
                                    </Grid>
                              </Grid>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <Card  style={{ width: '94%', height: '100%',background:'#FED0C1 0% 0% no-repeat padding-box', opacity:'1',backgroundColor: '#68DBFF', color:blueColor[0], textAlign: 'center', fontSize: '3vh', fontWeight: 'bold' }}>
                                                <Grid container xs={12} style={{ height: '100%' }}>
                                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}  className={classes.status}>
                                                      <div>OFF</div>
                                                      </Grid>
                                                </Grid>
                                                </Card>
                                    </Grid>
                              </Grid>
                              </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1} style={{marginTop:'1.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                    <Card className={classes.paper1} style={{display:'flex',height:'27vh'}}>
                                          <Grid container xs={12} spacing={1} direction="column">
                                          <Grid container item xs={12} justify="center" alignItems="center">
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <img src={props.type=='Primary Pump'? priimg: props.type == 'Secondary Pump'? secimg: pumpimg} />
                                          </Grid>
                                          </Grid>
                                          </Grid>
                                    </Card>
                              </Grid>
                              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                              <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Frequency                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.semicircleBar}>
                                                                  <SemiCircleProgressBar strokeWidth={20} stroke={blueColor[0]} diameter={100} orientation="up" percentage={50}  />
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Run Hours                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        400
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Flow GPM                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        1350
                                                                        {/* N/A */}
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading} style={{whiteSpace:'normal'}}>
                                                                        Inlet Pressure                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0 
                                                                        {/* N/A */}
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}  style={{whiteSpace:'normal'}}>
                                                                  Outlet Pressure                     
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0
                                                                        {/* N/A */}
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                              </Grid>
                        </Grid>
                        </Card>
                        </Grid>
                   )}
                   </>
                  :         
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Card className={classes.paper} style={{height:"41.5vh"}}>
                        <Grid container xs={12} spacing={1} style={{marginTop:'0.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} direction="column">
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}>
                                    <div style={{color:'black',fontWeight:'bold',fontSize:'3vh',whiteSpace:'nowrap'}}>{eachPumpsData.name}</div>
                              </Grid>
                              </Grid>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                                                            {/* <div className={classes.switchselector} style={{height:'4vh',marginTop:'0.5vh',pointerEvents:CPM_Status == "true" || disable?"none":"", opacity:CPM_Status == "true" || disable?"0.4":"",width:'22vh'}}>
                                                            <SwitchSelector
                                                                  style={{ borderRadius: "12px" }}
                                                                  onChange={onChange}
                                                                  options={pageType == 'BMS'? optionsBms: options}
                                                                  forcedSelectedIndex={pageType == 'BMS'? opn_SS: pumpOn_Off}
                                                                  // border="1px solid #0123B4"
                                                                  backgroundColor={"#e9e5e5"}
                                                                  fontColor={"rgba(0, 0, 0, 0.87)"}
                                                                  // wrapperBorderRadius={true}
                                                                  optionBorderRadius={5}
                                                                  wrapperBorderRadius={5} />
                                                            </div> */}
                                    </Grid>
                              </Grid>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{display:'flex',justifyContent:'right',alignContent:'right'}}>
                              <Grid container xs={12} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                {/* {pageType =='CPM'? 
                                                        <Card  style={{ width: '94%', height: '100%',background:'#FED0C1 0% 0% no-repeat padding-box', opacity:'1',backgroundColor:(pumpStatusColor["presentValue"] == ("inactive"||0))?"#68DBFF":(pumpStatusColor["presentValue"] == ("active"||1))?"#C1EECD":eachPumpsData.alerts_cri>0?"#FED0C1":eachPumpsData.alerts_solu>0?"#FFF36D":"grey", 
                                                               color:(pumpStatusColor["presentValue"] == ("inactive"||0))? blueColor[0]:(pumpStatusColor["presentValue"] == ("active"||1))? greenColor[0] :eachPumpsData.alerts_cri>0? redColor[0]:eachPumpsData.alerts_solu>0? yellowColor[0]:"black", textAlign: 'center', fontSize: '3vh', fontWeight: 'bold' }}>
                                                            <Grid container xs={12} style={{ height: '100%' }}>
                                                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}  className={classes.status}>
                                                                  <div>{(pumpStatusColor["presentValue"] == ("inactive"||0))?"OFF":(pumpStatusColor["presentValue"] == ("active"||1))?"ON"
                                                                    :eachPumpsData.alerts_cri>0 || eachPumpsData.alerts_solu>0?"Fault"
                                                                    :"-"}</div>
                                                               </Grid>
                                                            </Grid>
                                                        </Card>
                                                      : */}
                                                      <div className={classes.switchselector} style={{height:'4vh',marginTop:'0.5vh',marginLeft:'1vh',display:'flex',alignItems:'left',pointerEvents:CPM_Status == "true" || disable?"none":"", opacity:CPM_Status == "true" || disable?"0.4":"",width:'22vh'}}>
                                                      <SwitchSelector
                                                            style={{ borderRadius: "12px" }}
                                                            onChange={onChange}
                                                            options={pageType == 'BMS'? optionsBms : options}
                                                            forcedSelectedIndex={pageType != 'BMS'? pumpOn_Off : opn_SS }
                                                            backgroundColor={"#e9e5e5"}
                                                            fontColor={"rgba(0, 0, 0, 0.87)"}
                                                            optionBorderRadius={5}
                                                            wrapperBorderRadius={5} />
                                                      </div>
                                                {/* } */}

                                    </Grid>
                              </Grid>
                              </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1} style={{marginTop:'1.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                    <Card className={classes.paper1} style={{display:'flex',height:'28.9vh'}}>
                                          <Grid container xs={12} spacing={1} direction="column">
                                          <Grid container item xs={12} justify="center" alignItems="center">
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                {pageType == 'BMS'?<img src={fanimg}  /> :<img style={{width:'10vw'}}  src={props.type=='Primary Pump'? priimg: props.type == 'Secondary Pump'? secimg: pumpimg} />}
                                          </Grid>
                                          </Grid>
                                          </Grid>
                                    </Card>
                              </Grid>
                              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                                    <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  {pageType == 'BMS'? 'Device Mode':'Frequency' }                        
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  {pageType != 'BMS'?
                                                                  <>
                                                                  <div className={classes.semicircleBar}>
                                                                    <SemiCircleProgressBar strokeWidth={15} stroke="#0123B4" diameter={75} orientation="up" percentage= {props.type=='Primary Pump'?'100':'80'}  />
                                                                  </div>
                                                                  <div style={{ marginTop: '-2.1vh', fontSize: '11px',marginLeft:'0.7vh',fontWeight:'bold' }}>{props.type=='Primary Pump'?'50Hz':'40Hz'}</div>
                                                                  </>
                                                                        // <div className={classes.semicircleBar}>
                                                                        // <SemiCircleProgressBar strokeWidth={20} stroke={blueColor[0]} diameter={100} orientation="up" percentage={50}  />
                                                                        // </div>
                                                                        :
                                                                        <div className={classes.cardbody}>
                                                                        {am_SS}
                                                                        </div>}
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  {pageType == 'BMS'? 'Temperature Set Point':'Run Hours'}                         
                                                                  </Grid>
                                                            </Grid>

                                                                  {pageType != 'BMS'?
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                  {/* {runHours?formatter.format((formatter.format(runHours)/60))+'Hrs':'N/A'} */}
                                                                        {/* {(runHours/60)+'Hrs'} */}
                                                                        1957 Hrs
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                                  :
                                                                  <Grid container item xs={12}>
                                                                      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>      
                                                                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                      <TextField style={{marginLeft:'3vh'}}
                                                                        placeholder={formatter.format(zat)+ "℃"}
                                                                        name="Sat_set_point"
                                                                        autoComplete="off"
                                                                        value={sat}
                                                                        onChange={handleChangeForsetpointSAT}
                                                                        className={classes.text_field}
                                                                      />
                                                                      </Grid>
                                                                      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                                                      <Paper className={classes.set_button} 
                                                                      style={{ backgroundColor:blueColor[0],display:'flex', justifyContent: 'center',cursor:'pointer',marginTop:'1.5vh',marginLeft:'1vh'}} onClick={handleClickSat}>
                                                                      <div style={{color:'white'}}>set</div>
                                                                    </Paper> 
                                                                      </Grid>
                                                                      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>      
                                                            </Grid>
                                                                  }
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  {pageType != 'BMS'? 'Flow GPM': 'Status'}                       
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                  {pageType != 'BMS'?<>
                                                                        {props.type=='Primary Pump'?'1350':'2550'}
                                                                        {/* N/A */}
                                                                        </>
                                                                        :
                                                                        <>{errAlarm == 'active'? 'Tripped': errAlarm == 'inactive'?errAlarm:''}</>
                                                                        }
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          {pageType != 'BMS'?<> 
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading} style={{whiteSpace:'normal'}}>
                                                                  Inlet Pressure                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        2 Bar 
                                                                        {/* N/A */}
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}  style={{whiteSpace:'normal'}}>
                                                                  Outlet Pressure                     
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        4 Bar
                                                                        {/* N/A */}
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          </>
                                          :
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Temperature                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                       {formatter.format(zat)+'℃'}
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                           }
                                    </Grid>
                              </Grid>
                        </Grid>
                        </Card>
                   </Grid>
                  }
            </Grid>
        </Grid>
        <SemanticToastContainer position="top-center" />  
    </div>
  );
}

export default GlPump;

