import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from 'components/Card/Card';
import NotificationIcon from 'assets/img/NotificationIcon';
import { Blink } from "@bdchauvette/react-blink";
import NotificationLow from 'assets/img/NotificationLow';
import tempIcon from 'assets/img/NavBArTemp.png'
import NavIcon from 'assets/img/navBarHum.png'
import { useHistory } from 'react-router-dom';
import api from '../../api';



function NavAlarm() {
const [today, setDate] = React.useState(new Date());
const alerts = useSelector(state=>state.alarm.alarmData)
const [blink, setBlink] = React.useState(false);
const [humidity, setHumidity] = useState("");
const [temperature, setTemperature] = useState("");

const locale = 'en';
let history = useHistory();

 const redirectToAlert = () => {
    history.push('/admin/eventsviewer')
  }

useEffect(() => { 
  if(localStorage.getItem('roleID') == '6'){
  api.campus.weather().then(res=>{
    res.map((res)=>{
      if(res.param_id =='temperature'){
        setTemperature(res.param_value)
      }else if(res.param_id =='humidity'){
        setHumidity(res.param_value)
      }
    })
  }).catch((error)=>{

  })}
    let ackActive = alerts.system.filter(each => {
      if (each.acknowledged === 0) {
        return each
      }
    })  
    if (ackActive.length > 0) {
      return setBlink(true)
    } else {
      return setBlink(false)
    }
  }, [alerts.system])
  const day = today.toLocaleDateString(locale, { weekday: 'short' });
  const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, { month: 'long' })}\n\n`;
let currentDate = new Date();
const time = currentDate.getHours() + ":" + currentDate.getMinutes();
  return (
   
         <Grid container xs={12} direction='row' >
          {localStorage.getItem('roleID') != '6'?<>
                  <Grid xs={2}>
                    {blink === false ?
                      <ButtonBase style={{ width: "100%" }} onClick={() => redirectToAlert()}>
                        <Card style={{ marginTop: "0px", marginBottom: "0px", backgroundColor: "#C70B12" }}>
                          <Grid container xs={12} style={{ height: "35px" }}>
                            <Grid item xs={6}>
                              <NotificationIcon />
                            </Grid>
                            <Grid xs={6}>
                              {/* {console.log("alerts.length",alerts.system.length,format(new Date(), 'yyyy-MM-dd HH:mm:ss'))} */}
                              <div style={{ color: "#ffffff", 'marginTop': '10px', 'fontSize': '16px' }}>{alerts.system.length}</div>
                            </Grid>
                          </Grid>

                        </Card>
                      </ButtonBase>
                      :
                      <>
                        <Blink>
                          <ButtonBase style={{ width: "100%" }} onClick={() => redirectToAlert()}>
                            <Card style={{ marginTop: "0px", marginBottom: "0px", backgroundColor: "#C70B12" }}>
                              <Grid container xs={12} style={{ height: "35px" }}>
                                <Grid xs={6}>
                                  <NotificationIcon />
                                </Grid>
                                <Grid xs={6}>
                                  <div style={{ color: "#ffffff", 'marginTop': '10px', 'fontSize': '16px' }}>{alerts.system.length}</div>
                                </Grid>
                              </Grid>

                            </Card>
                          </ButtonBase>
                        </Blink>
                      </>
                    }
                  </Grid>

                  <Grid xs={2} style={{ marginLeft: '4px' }}>
                    <ButtonBase style={{ width: "100%" }} onClick={() => redirectToAlert()}>
                      <Card style={{ marginTop: "0px", marginBottom: "0px", backgroundColor: "#f2aa1a" }}>
                        <Grid container xs={12} style={{ height: "35px" }}>
                          <Grid xs={6}>
                            <NotificationLow />
                          </Grid>
                          <Grid xs={6}>
                            <div style={{ color: "#ffffff", 'marginTop': '10px', 'fontSize': '16px' }}>{alerts.solution.length}</div>
                          </Grid>
                        </Grid>

                      </Card>
                    </ButtonBase>
                  </Grid>


                  <Grid xs={3} style={{ marginLeft: '4px' }}>
                    <Card style={{ marginTop: "0px", marginBottom: "0px", backgroundColor: "#f2f2f7",pointerEvents: "none", opacity: "0.4"
  }}>
                      <Grid container xs={12} style={{ height: "35px" }}>
                        <Grid xs={3}>
                          <div>
                            <img src={tempIcon} alt='temp' style={{ "marginTop": "7px", "marginLeft": "13px" }} />
                          </div>
                        </Grid>
                        <Grid xs={3}>
                          <div style={{ color: "#000", marginTop: "4px" }}>{temperature}°C</div>
                        </Grid>
                        <Grid xs={3}>
                          <div>
                            <img src={NavIcon} alt='temp' style={{ "marginTop": "7px", "marginLeft": "13px" }} />
                          </div>

                        </Grid>
                        <Grid xs={3}>
                          <div style={{ color: "#000", marginTop: "4px" }}>{humidity}%</div>
                        </Grid>
                      </Grid>

                    </Card>
                  </Grid>

                  </>:<></>}
                  <Grid xs={4} style={{ marginLeft: localStorage.getItem('roleID') != '6'? '4px':'48vh',marginTop: localStorage.getItem('roleID') == '6'?"-2vh":""  }}>
                    <Card style={{ marginTop: "0px", marginBottom: "0px", backgroundColor: "#f2f2f7" }}>
                      <Grid container xs={12} style={{ height: "35px" }}>
                        <Grid xs={12}>
                          <div style={{ "marginTop": "3px", "marginLeft": "5vh", whiteSpace: 'nowrap' }}> {time} {date}</div>
                        </Grid>
                      </Grid>


                    </Card>
                  </Grid>

                </Grid>
    
  )
}

export default NavAlarm