import React from 'react'

function Control() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  width="14" height="14.5" viewBox="0 0 31.5 26.626">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_47" data-name="Rectangle 47" width="31.5" height="26.626" fill="#ffffff"/>
      </clipPath>
    </defs>
    <g id="Group_120" data-name="Group 120" transform="translate(0 0)">
      <g id="Group_119" data-name="Group 119" transform="translate(0 0)" clip-path="url(#clip-path)">
        <path id="Path_204" data-name="Path 204" d="M1.089,4.74H18.114a3.415,3.415,0,0,0,.319.729,3.748,3.748,0,0,0,1.316,1.323,3.629,3.629,0,0,0,3.662,0,3.8,3.8,0,0,0,1.323-1.323,3.43,3.43,0,0,0,.323-.729h5.4a1,1,0,0,0,.729-.313,1.038,1.038,0,0,0,.313-.765,1.063,1.063,0,0,0-.313-.772.992.992,0,0,0-.729-.318H25.066a3.458,3.458,0,0,0-.333-.76A3.733,3.733,0,0,0,23.41.49a3.666,3.666,0,0,0-3.662,0,3.682,3.682,0,0,0-1.316,1.323,3.483,3.483,0,0,0-.329.76H1.089a1.047,1.047,0,0,0-.778.318A1.062,1.062,0,0,0,0,3.662a1.037,1.037,0,0,0,.312.765,1.053,1.053,0,0,0,.778.313M20.232,2.278A1.9,1.9,0,0,1,23.49,3.638a1.848,1.848,0,0,1-.551,1.353,1.921,1.921,0,0,1-2.706,0,1.851,1.851,0,0,1-.551-1.353,1.847,1.847,0,0,1,.551-1.359" transform="translate(0 0)" fill="#0123b4"/>
        <path id="Path_205" data-name="Path 205" d="M30.41,14.132h-16.7a3.453,3.453,0,0,0-.327-.741,3.8,3.8,0,0,0-1.323-1.323,3.618,3.618,0,0,0-3.656,0,3.727,3.727,0,0,0-1.323,1.323,3.522,3.522,0,0,0-.323.741H1.041a.99.99,0,0,0-.735.313A1.05,1.05,0,0,0,0,15.21a1.072,1.072,0,0,0,.306.772.983.983,0,0,0,.735.319h5.71a3.448,3.448,0,0,0,.327.752A3.686,3.686,0,0,0,8.4,18.37a3.661,3.661,0,0,0,3.656,0,3.753,3.753,0,0,0,1.323-1.318,3.384,3.384,0,0,0,.331-.752h16.7A1.088,1.088,0,0,0,31.5,15.21a1.029,1.029,0,0,0-.319-.765,1.056,1.056,0,0,0-.771-.313M11.586,16.576a1.923,1.923,0,0,1-2.706,0,1.866,1.866,0,0,1-.551-1.366,1.914,1.914,0,0,1,3.257-1.359,1.85,1.85,0,0,1,.551,1.359,1.869,1.869,0,0,1-.551,1.366" transform="translate(0 -1.897)" fill="#0123b4"/>
        <path id="Path_206" data-name="Path 206" d="M30.459,25.678h-5.4a3.536,3.536,0,0,0-.324-.735A3.736,3.736,0,0,0,23.41,23.62a3.665,3.665,0,0,0-3.661,0,3.7,3.7,0,0,0-1.318,1.323,3.52,3.52,0,0,0-.319.735H1.09A1.045,1.045,0,0,0,.313,26,1.063,1.063,0,0,0,0,26.768a1.044,1.044,0,0,0,.313.766,1.058,1.058,0,0,0,.778.312H18.1a3.448,3.448,0,0,0,.327.752,3.746,3.746,0,0,0,1.318,1.323,3.622,3.622,0,0,0,3.661,0A3.783,3.783,0,0,0,24.734,28.6a3.423,3.423,0,0,0,.331-.752h5.394a1,1,0,0,0,.728-.312,1.043,1.043,0,0,0,.312-.766A1.062,1.062,0,0,0,31.187,26a.988.988,0,0,0-.728-.319m-7.52,2.444a1.948,1.948,0,1,1,.55-1.354,1.843,1.843,0,0,1-.55,1.354" transform="translate(0 -3.791)" fill="#0123b4"/>
      </g>
    </g>
  </svg>  
  )
}

export default Control