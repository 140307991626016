import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../api";
import { Grid, Select, FormControl, MenuItem } from "@material-ui/core";
import { blackColor, hexToRgb } from "assets/jss/material-dashboard-react.js";
import LandingPage from "./energyWaterMeterLanding";
// import LandingPage from "./upsEmsLanding";

const useStyles = makeStyles((theme) => ({
  formControl: {
    autosize: true,
    clearable: false,
  },
  select: {
    "&:after": {
      borderBottomColor: "blue",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      backgroundColor: "#0123b4",
      borderRadius: "8px",
    },
    "& .MuiSelect-root ": {
      marginTop: "-2vh",
    },
  },
}));

function GlEMLanding(props) {
  const classes = useStyles();
  const [floor, setFloor] = useState([]);
  const [fdata, setFdata] = useState(localStorage.getItem("floorName"));
  const [zdata, setZdata] = useState("");
  const [rdata, setRdata] = useState("");
  const [fid, setFId] = useState(localStorage.getItem("floorID"));
  const [newName, setnewName] = useState("");
  const [newId, setnewId] = useState(localStorage.getItem("floorID"));
  const [parameterLevel, setParameterLevel] = useState([]);
  const [energydevice, setEnergydevice] = useState([]);
  const [zoneId, setZoneId] = useState(""); // State for storing the selected zoneId
  const [zoneName, setZName] = useState(""); // State for storing the selected zoneId
  const [filteredZones, setFilteredZones] = useState([]);
  const [filteredRooms, setFilteredRooms] = useState([]);
  const buildingID = useSelector((state) => state.isLogged.data.building.id);
  const zone_data = useSelector((state) => state.inDashboard.locationData);
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  useEffect(() => {
    let isMounted = true;
    let zone_id = "";
    let z_data = [];

    zone_data.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

    zone_data.forEach((_each) => {
      if (_each.zone_type === "GL_LOCATION_TYPE_FLOOR") {
        z_data.push(_each);
      }
    });

    if (z_data.length > 0) {
      zone_id = z_data[0].uuid;
      if (isMounted) {
        setFdata(z_data[0].name);
        setFId(z_data[0].id);
        setnewId(z_data[0].id);
        
      }
    }

    api.floor
      .devicemap(zone_id, "energyMeter")
      .then((res) => {
        const filteredDevices = res.filter(
          (device) => device.zone_parent === zone_id
        );
        filteredDevices.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        setEnergydevice(filteredDevices);
        const filteredRes = res.filter(
          (item) => item.zone_type === "GL_LOCATION_TYPE_ZONE"
        );
        filteredRes.sort((a, b) => (a.zone_name > b.zone_name ? 1 : -1));
        console.log("filteredRes", filteredRes);

        setFilteredZones(filteredRes);
        const filteredValues = res.filter(
          (newdata) => newdata.zoneId === zone_id
        );
        filteredValues.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        setnewName(filteredValues[0].name);
        setParameterLevel(filteredValues);
      })
      .catch((err) => {
        if (isMounted) console.error("Error fetching device map:", err);
      });

    api.dashboard
      .getMetricData(buildingID)
      .then((res) => {
        if (isMounted) {
          res.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
          setFloor(res);
        }
      })
      .catch((err) => {
        if (isMounted) console.error("Error fetching metric data:", err);
      });

    // Cleanup function to avoid updates after component unmount
  }, [buildingID]);

  const handleDataFromChild = (selectedEnergymeter) => {
    setFilteredZones([selectedEnergymeter]);
    if (selectedEnergymeter) {
      setZdata(selectedEnergymeter.zone_name);
      setZoneId(selectedEnergymeter.zoneId);
      handlezonechange(
        selectedEnergymeter.zone_name,
        selectedEnergymeter.zoneId
      );
    }
  };

  const handleDataFromChildtwice = (selectedEnergymeter) => {
    setFilteredRooms([selectedEnergymeter]);
    if (selectedEnergymeter) {
      setRdata(selectedEnergymeter.zone_name);
      setZoneId(selectedEnergymeter.zoneId);
      handleroomchange(
        selectedEnergymeter.zone_name,
        selectedEnergymeter.zoneId
      );
    }
  };

  const handlefloorchange = (name, id) => {
    setFId(id);
    setFdata(name);
    setnewId(id);

    // Reset zone and room to "ALL"
    setZdata("ALL");
    setFilteredZones([]);
    setRdata("ALL");
    setFilteredRooms([]);

    // Fetch energy devices for the selected floor
    api.floor.devicemap(id, "energyMeter").then((res) => {
      if(res.length >0){
      const filteredRes = res.filter(
        (item) => item.zone_type === "GL_LOCATION_TYPE_ZONE"
      );
      filteredRes.sort((a, b) => (a.zone_name > b.zone_name ? 1 : -1));

      setFilteredZones(filteredRes);

      const filteredDevices = res.filter((device) => device.zone_parent === id);
      filteredDevices.sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });
      setEnergydevice(filteredDevices);
      const filteredValues = res.filter((newdata) => newdata.zoneId === id);
      filteredValues.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      setnewName(filteredValues[0].name);
      setParameterLevel(filteredValues);
    }else{
      setFilteredZones([])
      setEnergydevice([])
      setnewName('')
      setParameterLevel([])
    }
    });
  };

  const handlezonechange = (name, id) => {
    console.log("id,name", name, fid);
    if (!fid && name === "ALL") {
      console.warn("Please select a floor before choosing a room.");
      return; // Exit early if no floor is selected
    }
    setRdata("ALL"); // Reset Room dropdown to "ALL"
    setFilteredRooms([]); // Clear filtered rooms
    setnewId(id);
    setZoneId(id);
    setZName(name);

    if (name === "ALL") {
      // When "ALL" is selected, reset Room dropdown and fetch devices
      api.floor.devicemap(fid, "energyMeter").then((res) => {
        if(res.length > 0){
        const filteredRes1 = res.filter(
          (item) => item.zone_type === "GL_LOCATION_TYPE_ROOM"
        );
        const uniqueZones = Array.from(
          new Map(filteredRes1.map((item) => [item.zone_name, item])).values()
        );
        uniqueZones.sort((a, b) => (a.zone_name > b.zone_name ? 1 : -1));
        setFilteredRooms(uniqueZones);
        const filteredDevices = res.filter(
          (device) => device.zone_parent === fid
        );
        filteredDevices.sort(function (a, b) {
          return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
        });
        setEnergydevice(filteredDevices);
        const filteredValues = res.filter((newData) => newData.zoneId === fid);
        filteredValues.sort((a, b) => (a.name > b.name ? 1 : b.name ? -1 : 0));
        setnewName(filteredValues[0].name);
        setParameterLevel(filteredValues);
      }else{
        setFilteredRooms([])
        setEnergydevice([])
        setnewName('')
        setParameterLevel([])
      }
      });
    } else {
      // Handle individual zone selection
      api.floor.devicemap(id, "energyMeter").then((res) => {
        console.log("response from zone", res);
        if(res.length > 0){
        const filteredRes1 = res.filter(
          (item) => item.zone_type === "GL_LOCATION_TYPE_ROOM"
        );
        const uniqueZones = Array.from(
          new Map(filteredRes1.map((item) => [item.zone_name, item])).values()
        );
        uniqueZones.sort((a, b) => (a.zone_name > b.zone_name ? 1 : -1));
        setFilteredRooms(uniqueZones);
        res.sort(function (a, b) {
          return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
        });
        const filteredZones = res.filter((device) => device.zone_parent === id);

        if (filteredZones.length > 0) {
          // Sort the filtered zones if matches are found
          filteredZones.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          );
          setEnergydevice(filteredZones);
          const filteredValues = res.filter((newdata) => newdata.zoneId === id);
          filteredValues.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          );
          setnewName(filteredValues[0].name);
          setParameterLevel(filteredValues);
        } else {
          // If no matches are found, use the original response
          const filteredValues = res.filter((newdata) => newdata.zoneId === id);
          filteredValues.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          );
          setnewName(filteredValues[0].name);
          setParameterLevel(filteredValues);
          setEnergydevice([]);
          // console.log("else")
          // res.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          // setEnergydevice(res);
        }
      }else{
        setFilteredRooms([])
        setEnergydevice([])
        setnewName('')
        setParameterLevel([])
      }
      });
    }
  };

  const handleroomchange = (name, id) => {
    setnewId(id);
    if (!fid && name === "ALL") {
      console.warn("Please select a floor before choosing a Feeder");
      return;
    }
    if (name === "ALL") {
      api.floor.devicemap(zoneId, "energyMeter").then((res) => {
        if(zoneId){
        if(res.length > 0){
        const filteredZones = res.filter(
          (device) => device.zone_parent === zoneId
        );
        filteredZones.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        setEnergydevice(filteredZones);
        const filteredValues = res.filter((newData) => newData.zoneId === id);
        filteredValues.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        setnewName(filteredValues[0].name);
        setParameterLevel(filteredValues);
      }else{
        setEnergydevice([])
        setnewName('')
        setParameterLevel([])
      }
    }
      });
    } else {
      api.floor.devicemap(id, "energyMeter").then((res) => {
        if(id){
        if(res.length > 0){
        const filteredDevices = res.filter((device) => device.zoneId !== id);

        filteredDevices.sort(function (a, b) {
          return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
        });

        setEnergydevice(filteredDevices);
        const filteredValues = res.filter((newdata) => newdata.zoneId === id);
        filteredValues.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        setnewName(filteredValues[0].name);
        setParameterLevel(filteredValues);
      }
      else{
        setEnergydevice([])
        setnewName('')
        setParameterLevel([])
      }
      }
      });
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
            <FormControl
              variant="filled"
              size="large"
              className={classes.formControl}
              style={{
                width: "max-content",
                minWidth: "100%",
                backgroundColor: "#eeeef5",
                fontFamily: "Arial",
              }}
            >
              <Select
                labelId="filled-hidden-label-small"
                id="demo-simple-select-outlined"
                label="Floor"
                value={fdata || ""}
                style={{
                  fontWeight: "bold",
                  height: "6vh",
                  borderRadius: "0.8vw",
                  fontFamily: "Arial",
                }}
                className={classes.select}
                disableUnderline
              >
                {floor.map((_item) => (
                  <MenuItem
                    key={_item.name}
                    value={_item.name}
                    onClick={() => handlefloorchange(_item.name, _item.id)}
                  >
                    {_item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
            <FormControl
              variant="filled"
              size="large"
              className={classes.formControl}
              style={{
                width: "max-content",
                minWidth: "100%",
                backgroundColor: "#eeeef5",
                fontFamily: "Arial",
              }}
            >
              <Select
                labelId="filled-hidden-label-small"
                id="demo-simple-select-outlined"
                label="Zone"
                value={zdata || "ALL"}
                onChange={(e) => {
                  const selectedZone = filteredZones.find(
                    (item) => item.zone_name === e.target.value
                  );
                  if (selectedZone) {
                    setZdata(selectedZone.zone_name);
                    handlezonechange(
                      selectedZone.zone_name,
                      selectedZone.zoneId
                    );
                  }
                }}
                style={{
                  fontWeight: "bold",
                  height: "6vh",
                  borderRadius: "0.8vw",
                  fontFamily: "Arial",
                }}
                className={classes.select}
                disableUnderline
              >
                <MenuItem
                  value="ALL"
                  onClick={() => {
                    if (fid) {
                      setZdata("ALL");
                      handlezonechange("ALL", ""); // Reset Zone data when "ALL" is selected
                    }
                  }}
                  disabled={!fid} // Disable if fid is not present
                  style={{
                    opacity: fid ? 1 : 0.5, // Make the "All Rooms" option less opaque when disabled
                    pointerEvents: fid ? "auto" : "none", // Disable pointer events if fid is not present
                  }}
                >
                  All Rooms
                </MenuItem>
                {filteredZones.map((_item) => (
                  <MenuItem
                    key={_item.zone_name}
                    value={_item.zone_name}
                    onClick={() => {
                      setZdata(_item.zone_name);
                      handlezonechange(_item.zone_name, _item.zoneId);
                    }}
                  >
                    {_item.zone_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
            <FormControl
              variant="filled"
              size="large"
              className={classes.formControl}
              style={{
                width: "max-content",
                minWidth: "100%",
                backgroundColor: "#eeeef5",
                fontFamily: "Arial",
              }}
            >
              <Select
                labelId="filled-hidden-label-small"
                id="demo-simple-select-outlined"
                label="Room"
                value={rdata || "ALL"}
                onChange={(e) => {
                  const selectedRoom = filteredRooms.find(
                    (item) => item.zone_name === e.target.value
                  );
                  if (selectedRoom) {
                    setRdata(selectedRoom.zone_name);
                    handleroomchange(
                      selectedRoom.zone_name,
                      selectedRoom.zoneId
                    );
                  } else {
                    // Handle "ALL" selection
                    setRdata("ALL");
                    handleroomchange("ALL", zoneId); // Reset Room data for "ALL"
                  }
                }}
                style={{
                  fontWeight: "bold",
                  height: "6vh",
                  borderRadius: "0.8vw",
                  fontFamily: "Arial",
                }}
                className={classes.select}
                disableUnderline
              >
                <MenuItem
                  value="ALL"
                  onClick={() => {
                    if (fid) {
                      setRdata("ALL");
                      handleroomchange("ALL", zoneId); // Reset room data when "ALL" is selected
                    }
                  }}
                  disabled={!fid} // Disable if fid is not present
                  style={{
                    opacity: fid ? 1 : 0.5, // Make the "ALL Feeder" option less opaque when disabled
                    pointerEvents: fid ? "auto" : "none", // Disable pointer events if fid is not present
                  }}
                >
                  All Feeder
                </MenuItem>
                {filteredRooms.map((_item) => (
                  <MenuItem
                    key={_item.zone_name}
                    value={_item.zone_name}
                    onClick={() => {
                      setRdata(_item.zone_name);
                      handleroomchange(_item.zone_name, _item.zoneId);
                    }}
                  >
                    {_item.zone_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <LandingPage
        device={energydevice}
        buildingID={buildingID}
        parameterLevel={parameterLevel}
        newId={newId}
        newName={newName}
        fid={fid}
        fdata={fdata}
        zoneActualName={zoneName}
        zoneActualId={zoneId}
        type="energyMeter"
        onDataChange={handleDataFromChild}
        onDataChangetwice={handleDataFromChildtwice}
      />
    </div>
  );
}

export default GlEMLanding;
