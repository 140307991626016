import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../api'
import { Grid, Select, FormControl, MenuItem} from '@material-ui/core';
import {
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-react.js";
import LandingPage from './energyWaterMeterLanding'
;

const useStyles = makeStyles(theme => ({
  formControl: {
    autosize: true,
    clearable: false
  },
  select: {
    "&:after": {
      borderBottomColor: "blue",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      backgroundColor:"#0123b4",borderRadius:"8px"
    },
    "& .MuiSelect-root ": {
      marginTop:"-2vh"
    }
  }
}));

function GlEMLanding(props) {
  const classes = useStyles();
  const [floor, setFloor] = useState([]);
  const [fdata, setFdata] = useState(localStorage.getItem('floorName'));
  const [fid, setFId] = useState(localStorage.getItem('floorID'));
  const [waterdevice, setWaterdevice] = useState([]);
  const buildingID = useSelector(state => state.isLogged.data.building.id);
  const zone_data = useSelector((state) => state.inDashboard.locationData);

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let NewData = [
    {
        "zoneId": "eb32cb5b-7eeb-45c5-9da9-a4a170aa3e20",
        "zone_coordinates": [
            [
                258.469,
                349.909
            ],
            [
                45.397,
                -39.152
            ]
        ],
        "ssid": "789d6396-601c-48dc-a93f-c3ba250e2c9e",
        "zoneColor": "#6185ff",
        "name": "WM1",
        "type": "NONGL_SS_WM",
        "coordinates": null,
        "loader_state": false,
        "Run_Hr_U": "586",
        "React_Pwr_Ttl": "4839",
        "On_Hr_U": "781",
        "Volt_LL_Ph3": "171",
        "Max_Dmd_U": "825590784",
        "React_Pwr_Ph1": "380",
        "React_Pwr_Ph3": "5362",
        "Volt_LN_Avg": "378",
        "No_Inp_Volt_Intr": "333",
        "Run_Hr_G": "4853",
        "Act_Pwr_Ph1": "3838",
        "Max_Dmd_Occ_Time_U": "27",
        "Avg_Ld_Percntg": "45",
        "Volt_LN_Ph3": "368",
        "Cur_Ph3": "75353",
        "Act_Enrg": "184",
        "Apprnt_Enrg": "4323",
        "Volt_LL_Avg": "489",
        "Fwd_Run _Secs": "236120848",
        "Unbl%_Ld": "81",
        "Rising_Dmd": "2665",
        "Ph3_Ld%": "91",
        "PF_Ph2": "0",
        "On _Secs": "902554560",
        "Prsnt_Dmd": "8377",
        "Ph1_Ld%": "91",
        "Fwd_Act_Enrg": "5446",
        "Fwd_React_Enrg": "2348",
        "Volt_LL_Ph1": "51",
        "Unbl%_Volt": "50613",
        "Volt_LN_Ph2": "462",
        "Freq": "62",
        "Act_Pwr_Ph3": "9777",
        "PF_Ph1": "0",
        "Apprnt_Enrg_U": "3376",
        "Aprnt_Pwr_Ph3": "4963",
        "Volt_LN_Ph1": "317",
        "Volt_LL_Ph2": "333",
        "Aprnt_Pwr_Ph1": "1566",
        "Cur_Ph1": "46703",
        "PF_Ph3": "0",
        "Fwd_Aprnt_Enrg": "784",
        "On_Hr_G": "230",
        "Ph2_Ld%": "20",
        "Aprnt_Pwr_Ph2": "8603",
        "Act_Pwr_Ttl": "7837",
        "React_Pwr_Ph2": "9088",
        "PF_Ttl": "0",
        "Aprnt_Pwr_Ttl": "4151",
        "Act_Pwr_Ph2": "9306",
        "Cur_Ph2": "47237"
    },
    {
        "zoneId": "eb32cb5b-7eeb-45c5-9da9-a4a170aa3e20",
        "zone_coordinates": [
            [
                258.469,
                349.909
            ],
            [
                45.397,
                -39.152
            ]
        ],
        "ssid": "4c792ae9-96ed-416d-8546-988f832dc23b",
        "zoneColor": "#6185ff",
        "name": "WM2",
        "type": "NONGL_SS_WM",
        "coordinates": null,
        "loader_state": false,
        "Cur_Ph2": "32131",
        "Unbl%_Ld": "26",
        "Act_Pwr_Ph3": "7543",
        "PF_Ph3": "0",
        "Prsnt_Dmd": "6985",
        "PF_Ph2": "0",
        "Volt_LL_Ph1": "460",
        "Ph1_Ld%": "5",
        "React_Pwr_Ph3": "484",
        "Volt_LL_Ph3": "406",
        "PF_Ttl": "0",
        "Aprnt_Pwr_Ttl": "2974",
        "React_Pwr_Ttl": "3816",
        "Act_Enrg": "2487",
        "React_Pwr_Ph2": "9834",
        "Aprnt_Pwr_Ph1": "669",
        "On _Secs": "260424528",
        "Run_Hr_U": "948",
        "Aprnt_Pwr_Ph3": "9974",
        "Volt_LN_Ph2": "447",
        "Aprnt_Pwr_Ph2": "6521",
        "Max_Dmd_U": "459685984",
        "Max_Dmd_Occ_Time_U": "89",
        "Volt_LL_Avg": "91",
        "Rising_Dmd": "8810",
        "Run_Hr_G": "4354",
        "Act_Pwr_Ttl": "2229",
        "Fwd_React_Enrg": "231",
        "Volt_LN_Avg": "203",
        "Cur_Ph3": "93946",
        "On_Hr_G": "622",
        "Volt_LL_Ph2": "90",
        "Freq": "54",
        "Volt_LN_Ph1": "394",
        "Fwd_Aprnt_Enrg": "1726",
        "Fwd_Run _Secs": "515072416",
        "Unbl%_Volt": "94902",
        "React_Pwr_Ph1": "2044",
        "Fwd_Act_Enrg": "7424",
        "Apprnt_Enrg_U": "2806",
        "Volt_LN_Ph3": "385",
        "PF_Ph1": "0",
        "Ph3_Ld%": "23",
        "Avg_Ld_Percntg": "42",
        "Cur_Ph1": "78148",
        "Act_Pwr_Ph1": "1991",
        "On_Hr_U": "470",
        "Ph2_Ld%": "7",
        "No_Inp_Volt_Intr": "174",
        "Act_Pwr_Ph2": "3522",
        "Apprnt_Enrg": "8661"
    }
]

  useEffect(() => {
    let zone_id='',z_data=[]
    zone_data.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
    // zone_data.filter((_each)=>_each.zone_type==='GL_LOCATION_TYPE_FLOOR')
    // if(fdata!== null){
      zone_data.filter((_each,i) =>{
        if(_each.zone_type==='GL_LOCATION_TYPE_FLOOR'){
          z_data.push(_each);
        }
      })
      zone_id=z_data[0].uuid
      setFdata(z_data[0].name)
      setFId(z_data[0].id)
      console.log("zzzzzzzzzzzzzone_idz",zone_id)
    // } else {
    //   zone_id=zone_data[0].uuid
    //   setFdata(zone_data[0].name)
    // }
    api.floor.devicemap(zone_id, "waterMeter")
    .then((res) => {
      res.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
          setWaterdevice(res);
        })
    api.dashboard.getMetricData(buildingID).then((res) => {
        res.sort(function (a, b) {
          return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
        });
        setFloor(res);
      });

  }, [buildingID]);
  const handlefloorchange = (name, id) => {
    setFId(id)
    setFdata(name);
    api.floor.devicemap(id, "waterMeter")
    .then((res) => {
      res.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
      setWaterdevice(res);
      
    })
    
  };

  return (
    <div className={classes.root}>
    <Grid container spacing={1}>
          <Grid container item xs={12} spacing={2}>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                  <FormControl
                              variant="filled"
                              size="large"
                              className={classes.formControl}
                              style={{
                                  width: "max-content",
                                  minWidth: "100%",
                                  backgroundColor: "#eeeef5",
                                  fontFamily: "Arial"
                              }}
                              >
                              <Select
                                  labelId="filled-hidden-label-small"
                                  id="demo-simple-select-outlined"
                                  label="Floor"
                                  value={fdata || ''}
                                  style={{
                                  fontWeight: "bold",
                                  height: "6vh",
                                  borderRadius: '0.8vw',
                                  fontFamily: "Arial"
                                  }}
                                  className={classes.select}
                                  disableUnderline
                              >
                                {floor.map(_item => (
                                <MenuItem key={_item.name} value={_item.name}
                                  onClick={() => handlefloorchange(_item.name, _item.id)}
                                > {_item.name === 'GVFloor-01' ? 'Floor-17' : _item.name}</MenuItem>
                              ))}
                              </Select>
                  </FormControl>
              </Grid>
          </Grid>
    </Grid>
    <LandingPage device={waterdevice} fid={fid} fdata={fdata} type="waterMeter" />
  </div>
  )
}

export default GlEMLanding