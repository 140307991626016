import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import nesso_img from '../../assets/img/nesso_img.png'

export default function MyPlotlyViewer() {
	const [plot, setPlot] = useState(0);

	useEffect(() => {
		const fetchData = () => {
			fetch('https://ibms.graylinx.ai/fddget')
				.then(res => res.json())
				.then(data => {
					console.log('Received Data: ', Object.keys(data));
					setPlot(data);
				})
				.catch(err => console.error('Error fetching data:', err));
		};

		// Fetch data initially
		fetchData();

		// Set interval to fetch data every 30 minutes
		const interval = setInterval(fetchData, 1800000); 

		// Cleanup interval on unmount
		return () => clearInterval(interval);
	}, []);

	console.log(plot);

	return (
		<div className='content'>
			{/* <h1>Nesso</h1> */}
			<img
                              
                                src={nesso_img}
								style={{marginLeft:"1vh",marginTop:"-2vh"}}
								/>
			<Plot data={plot.data} layout={plot.layout} />
		</div>
	);
}
