import React from "react";
import ReactApexCharts from 'react-apexcharts'
import { makeStyles } from "@material-ui/core/styles";
import { format, compareAsc, toDate,addMinutes } from 'date-fns'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  customDialog: {
    // Set the desired width for the dialog
    width: '700px', // Adjust this value as needed
  },
}))

export default function ApexChart(props) {
  const classes = useStyles();
  const { data, param } = props;
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // let a=[];
  // a= (param==="ahu_chilled_valve")? <>AHU</>:(param==="static_pressure")? <>Static Pressure</>:(param==="ahu_supply_air_temperature")?<>SAT</>:<></>}
  // let element = new Map()
  let elements = new Map(), elementsMin = new Map(), elementsMax = new Map();
  let lcl = 15, ucl = 30;
  switch (param) {
    case "SAT": lcl = 11; ucl = 18; break;
    case "RAT": lcl = 22; ucl = 30; break;
    case "CHW_Vlv_Pos": lcl = 0; ucl = 100; break;
    default: lcl = 11; ucl = 30; break;
  }
  data.map((_elem, i) => {
    // var myDate = new Date(_elem.measured_time);
    // moment(oldDateObj).add(30, 'm').toDate();
    var myDate =toDate(addMinutes(new Date(_elem.measured_time),330))
    // var myDate = moment(_elem.measured_time).add(330, 'm').toDate();
    // var myDate =_elem.measured_time
    // var myEpoch = myDate.getTime()/1000.0;
    elements.set(i, { x: myDate, y: parseFloat(_elem.param_value).toFixed(2) })
    elementsMin.set(i, { x: myDate, y: lcl })
    elementsMax.set(i, { x: myDate, y: ucl })
    return (<></>);
  })
  var elemArr = [], elemArrMin = [], elemArrMax = [];;
  for (let i of elements.values()) {
    elemArr.push(i)
  }
  for (let j of elementsMin.values()) {
    elemArrMin.push(j)
  }
  for (let k of elementsMax.values()) {
    elemArrMax.push(k)
  }

  let dataSet = {}
  const onclickchart = (param) => {
    console.log("clicked on chart",param)
    handleClickOpen(true)
  }
  if (param !== 'CHW_Vlv_Pos') {
    dataSet = {
      series: [{
        name: param,
        data: elemArr
      },
      {
        name: "Upper Limit",
        data: elemArrMax
      }, {
        name: "Lower Limit",
        data: elemArrMin
      }
      ],
      options: {
        chart: {
          events: {
            click: function (event, chartContext, config) {
              onclickchart(param);
            },
          },
         
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: false,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom'
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        colors: ['#2E93fA', '#FF0000', '#FF0000'],
        stroke: {
          width: [2, 2, 2]
        },
        title: {
          text: param=='SAT'?'Supply Air Temperature[℃]':param=='RAT'?'Return Air Temperature[℃]':'CHW Position[%]',
          align: 'left'
        },
        legend: {
          show: false
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.5,
            inverseColors: false,
            opacityFrom: 0.3,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        xaxis: {
          type: 'datetime',
          // labels: {
          //   formatter: function (val) {
          //     return format(new Date(val), "HH:mm"); // Format date with hours and minutes only
          //   },
          // }
          
        },
        yaxis: {
          
            title: {
              text: "℃",
            },
          show: true,
          showAlways: true,
          // min: 0,
          // tickAmount: 6,
          // max: 30,
          decimalsInFloat: false,
          formatter: (val) => { return val },
          labels: {
            formatter: function (val) {
              return parseInt(val); // Convert the value to an integer (whole number)
            },
          },
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      },
    }
  } else {
    dataSet = {
      series: [{
        name: param,
        data: elemArr
      }
      ],
      options: {
        chart: {
            events: {
              click: function (event, chartContext, config) {
                onclickchart(param);
              },
            },
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: false,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom'
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        colors: ['#2E93fA'],
        stroke: {
          width: [2]
        },
        title: {
          text: param=='SAT'?'Supply Air Temperature[°C]':param=='RAT'?'Return Air Temperature[°C]':'Chw position[%]',
          align: 'left'
        },
        legend: {
          show: false
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.5,
            inverseColors: false,
            opacityFrom: 0.3,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        xaxis: {
          type: 'datetime',
          // labels: {
          //   formatter: function (val) {
          //     return format(new Date(val), "HH:mm"); // Format date with hours and minutes only
          //   },
          // }
        },
        yaxis: {
          show: true,
          showAlways: true,
          // min: 0,
          tickAmount: 6,
          // max: 30,
          decimalsInFloat: true,
          formatter: (val) => { return val },
          labels: {
            formatter: function (val) {
              return parseInt(val); // Convert the value to an integer (whole number)
            },
          },
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      },
    }
  }
  return (
    <div id="chart">
      {data.length !== 0 ? <>
      <ReactApexCharts options={dataSet.options} series={dataSet.series}  type="area" height={
          window.innerHeight == 641 ?
          ( props.via == 'EM'? 150: props.via =='WM'? 310: props.via =='AHU'? 140: 310):
          window.innerHeight == 768 ?//device 2
          ( props.via == 'EM'? 180: props.via =='WM'? 380: props.via =='AHU'? 170: 380):
          window.innerHeight == 1080 ?//device 1
          ( props.via == 'EM'? 240: props.via =='WM'? 380: props.via =='AHU'? 250: 380):
          window.innerHeight == 1920 ?
          ( props.via == 'EM'? 180: props.via =='WM'? 450: props.via =='AHU'? 160: 450):
          window.innerHeight == 1929 ?
          ( props.via == 'EM'? 180: props.via =='WM'? 450: props.via =='AHU'? 100: 450):
          window.innerHeight == 864 ?
          ( props.via == 'EM'? 220: props.via =='WM'? 450: props.via =='AHU'? 180: 450):
          window.innerHeight == 540 ?//office tv
          ( props.via == 'EM'? 140: props.via =='WM'? 450: props.via =='AHU'? 130: 450):
          window.innerHeight == 599 ?//pradeep browser view
          ( props.via == 'EM'? 150: props.via =='WM'? 280: props.via =='AHU'? 145: 280):
          window.innerHeight == 1280 ?//tab
          ( props.via == 'EM'? 300: props.via =='WM'? 650: props.via =='AHU'? 300: 650):
          window.innerHeight == 600 ?//hmi
          ( props.via == 'EM'? 140: props.via =='WM'? 340: props.via =='AHU'? 130: 340):
          window.innerHeight == 844 ?//iphone12
          ( props.via == 'EM'? 180: props.via =='WM'? 425: props.via =='AHU'? 180: 425):
          window.innerHeight == 633 ?//moses laptop
          ( props.via == 'EM'? 140: props.via =='WM'? 320: props.via =='AHU'? 140: 160):
          window.innerHeight == 632 ?//moses laptop
          ( props.via == 'EM'? 140: props.via =='WM'? 320: props.via =='AHU'? 140: 160):
          window.innerHeight == 900 ?//office desktop
          ( props.via == 'EM'? 210: props.via =='WM'? 440: props.via =='AHU'? 200: 440):
          window.innerHeight == 793 ?//one plus
          ( props.via == 'EM'? 185: props.via =='WM'? 390: props.via =='AHU'? 180: 390):
          window.innerHeight == 939 ?//z fold
          ( props.via == 'EM'? 225: props.via =='WM'? 430: props.via =='AHU'? 200: 430):
          window.innerHeight == 915 ?//s20 ultra
          ( props.via == 'EM'? 210: props.via =='WM'? 520: props.via =='AHU'? 200: 520):
          'auto'
        }/>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} classes={{ paper: classes.customDialog }}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {param=='SAT'?<>Supply Air Temperature</>:param=='RAT'?<>Return Air Temperature</>:<>Chilled water valve position</>}
        </DialogTitle>
        <DialogContent dividers>
        <ReactApexCharts options={dataSet.options} series={dataSet.series}  type="area" />
        </DialogContent>
      </Dialog>
      </>:<>         {param=='SAT'?<div style={{fontWeight:'bold',color:'black'}}>Supply Air Temperature</div>:param=='RAT'?<div style={{fontWeight:'bold',color:'black'}}>Return Air Temperature</div>:<div style={{fontWeight:'bold',color:'black'}}>Chilled water valve position</div>}
                  <h4 style={{display:'flex',justifyContent:'center',alignItems:'center'}}>No data available</h4>
        </>
          }
    </div>
  );
}
 