import React from 'react'
import ReactApexCharts from 'react-apexcharts'
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';


function DevicetrendChart(props) {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });
  const data = props.data
  const text = props.param
  const dev_id = data.map(_i => _i.ssid)
  const name = data.map(_i => _i.name)
  let tempArr = []
  data.map(obj => {
    let tempData = {}
    tempData.x = obj.name
    tempData.y = formatter.format(obj.actual)
    tempData.goals = []
    let goalObj = {}
    goalObj["name"] = 'Expected'
    goalObj["value"] = obj.expected
    // goalObj["strokeHeight"] = 5
    // goalObj["strokeColor"] = '#775DD0'
    tempData.goals.push(goalObj)
    tempArr.push(tempData)
    return tempArr
  })
  const onclickchart = (e, c, con) => {

    let i = con.dataPointIndex
    localStorage.setItem("deviceID", "Ahu2");
    localStorage.setItem("deviceName", "Ahu1");
    props.history.push({
      pathname: `/admin/glAhu`,
      state: {
        data: dev_id[i],
        name: name[i]
      }
    })
  }
  const dataset = {
    series: [
      {
        name: 'Actual',
        data: tempArr,
      }
    ],
    options: {
      title: {
        text: text === 'SAT'? 'Supply Air Temperature': text === 'RAT'? 'Return Air Temperature': 'ChW Valve Position'
      },

      chart: {
        type: 'bar',
        height: 350,
        events: {
          dataPointSelection: function (event, chartContext, config) {
            onclickchart(event, chartContext, config)
          }
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '25%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      fill: {
        opacity: 1
      },
      colors: ['#00E396'],
      tooltip: {
        y: {
          formatter: function (val) {
            return val 
          }
        }
      },
      yaxis: {
        title: {
          text: text == 'ChW Valve'? "%":"℃",
        },
        labels: {
          formatter: function (val) {
            return text=='ChW Valve'? parseInt(val) : parseInt(val); // Convert the value to an integer (whole number)
          },
        },
      },
    },
  };

  return (
    <div>
      <GridContainer>
        <GridItem>
          <ReactApexCharts options={dataset.options} series={dataset.series} type="bar" height={ 
           window.innerHeight == 641 ?
           ( props.via == 'EM'? 180: props.via =='WM'? 310:props.via =="Floor's page"? 140: props.via =='AHU'? 130: 330):
           window.innerHeight == 768 ?//device 2
           ( props.via == 'EM'? 180: props.via =='WM'? 380:props.via =="Floor's page"? 185: props.via =='AHU'? 170: 380):
           window.innerHeight == 1080 ?//device 1
           ( props.via == 'EM'? 240: props.via =='WM'? 380:props.via =="Floor's page"? 230: props.via =='AHU'? 250: 380):
           window.innerHeight == 1920 ?
           ( props.via == 'EM'? 180: props.via =='WM'? 450:props.via =="Floor's page"? 160: props.via =='AHU'? 160: 450):
           window.innerHeight == 1929 ?
           ( props.via == 'EM'? 180: props.via =='WM'? 450:props.via =="Floor's page"? 100: props.via =='AHU'? 100: 450):
           window.innerHeight == 864 ?
           ( props.via == 'EM'? 220: props.via =='WM'? 450:props.via =="Floor's page"? 200: props.via =='AHU'? 100: 450):
           window.innerHeight == 540 ?//office tv
           ( props.via == 'EM'? 140: props.via =='WM'? 450:props.via =="Floor's page"? 150: props.via =='AHU'? 130: 450):
           window.innerHeight == 599 ?//pradeep browser view
           ( props.via == 'EM'? 150: props.via =='WM'? 280:props.via =="Floor's page"? 125: props.via =='AHU'? 145: 280):
           window.innerHeight == 1280 ?//tab
           ( props.via == 'EM'? 300: props.via =='WM'? 650:props.via =="Floor's page"? 280: props.via =='AHU'? 300: 650):
           window.innerHeight == 600 ?//hmi
           ( props.via == 'EM'? 140: props.via =='WM'? 340:props.via =="Floor's page"? 160: props.via =='AHU'? 100: 340):
           window.innerHeight == 844 ?//iphone12
           ( props.via == 'EM'? 180: props.via =='WM'? 425:props.via =="Floor's page"? 180: props.via =='AHU'? 180: 425):
           window.innerHeight == 633 ?//moses laptop
           ( props.via == 'EM'? 140: props.via =='WM'? 320:props.via =="Floor's page"? 150: props.via =='AHU'? 100: 160):
           window.innerHeight == 632 ?//moses laptop
           ( props.via == 'EM'? 140: props.via =='WM'? 320:props.via =="Floor's page"? 140: props.via =='AHU'? 140: 160):
           window.innerHeight == 900 ?//office desktop
           ( props.via == 'EM'? 210: props.via =='WM'? 440:props.via =="Floor's page"? 180: props.via =='AHU'? 200: 440):
           window.innerHeight == 793 ?//one plus
           ( props.via == 'EM'? 185: props.via =='WM'? 390:props.via =="Floor's page"? 160: props.via =='AHU'? 180: 390):
           window.innerHeight == 939 ?//z fold
           ( props.via == 'EM'? 225: props.via =='WM'? 430:props.via =="Floor's page"? 180: props.via =='AHU'? 200: 430):
           window.innerHeight == 915 ?//s20 ultra
           ( props.via == 'EM'? 210: props.via =='WM'? 520:props.via =="Floor's page"? 190: props.via =='AHU'? 200: 520):
           'auto'}/>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default DevicetrendChart