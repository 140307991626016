import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import {Card} from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import api from './../../api'
import Typography from '@material-ui/core/Typography';
import { Map, ImageOverlay, Marker, Tooltip, ZoomControl } from 'react-leaflet';
import CoolingTowerImg from './../../assets/img/CoolingTowerImg.png'
import { withStyles } from '@material-ui/core/styles';
import TimeSeriesChart from './../TimeSeriesVav';
import TimeSeriesUpsStatic from "./../TimeSeriesUpsStatic";
import SwitchSelector from "react-switch-selector";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import TimeSeriesChillerStatic from "./../TimeSeriesChillerStatic";
import Dialog from '@material-ui/core/Dialog';
import MenuItem from "@material-ui/core/MenuItem";
import { SemanticToastContainer, toast } from "react-semantic-toasts";

const Leaflet = require('leaflet');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop:'-1vh'
  },
  card: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paper: {
    // background:'#FFFFFF 0% 0% no-repeat padding-box',
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#fcfafa",
    // boxShadow: '0px 4px 20px #0123B41A',
    // backgroundColor: 'white',
    borderRadius: '14px',
    height: '50vh',
    marginTop:"1vh",
    opacity:'1'
  },
  paper1: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  semicircularbar:{
    height:"11vh",
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#fcfafa",
    // background:"#FFFFFF 0% 0% no-repeat padding-box",
    // boxShadow: '0px 4px 20px #0123B41A;',
    opacity:"1",
    borderRadius: '14px',
  },
  graphpaper: {
    // background:'#FFFFFF 0% 0% no-repeat padding-box',
    height: "26vh",
    textAlign: 'center',
    color: theme.palette.text.secondary,
    // boxShadow: '0px 4px 20px #0123B41A',
    borderRadius: '14px',
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#fcfafa",
  },
  text: {
    fontSize: "14px",
    color: " #292929",
    fontFamily: "Arial"
  },
  control1: {
    width: "6vh",
    marginTop: "0vh",
    marginLeft: "-11vh",
    fontFamily: "Arial"
  },
  formControl: {
    autosize: true,
    clearable: false,
  },
  paper1: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  CardHeadFont:{
    '@media (min-width:0px) and (max-width:599.95px)': {//xs
      fontSize: "1.5vh"
      },
      '@media (min-width:600px) and (max-width:959.95px)': {//sm
        fontSize: "1.9vh",
        borderRadius:'10px',
      },
      '@media (min-width:960px) and (max-width:1279.95px)': {//md
        fontSize: "1.7vh",
      },
      '@media (min-width:1280px) and (max-width:1919.95px)': {//lg
        fontSize: "1.8vh",
      },
      '@media (min-width:1920px) and (max-width:2559.95px)': {//xl
        fontSize: "1.7vh",
      },
  },
  CardParamFont:{
    '@media (min-width:0px) and (max-width:599.95px)': {//xs
      fontSize: "4vh"
      },
      '@media (min-width:600px) and (max-width:959.95px)': {//sm
        fontSize: "4.5vh",
        borderRadius:'10px',
      },
      '@media (min-width:960px) and (max-width:1279.95px)': {//md
        fontSize: "4vh",
      },
      '@media (min-width:1280px) and (max-width:1919.95px)': {//lg
        fontSize: "5vh",
      },
      '@media (min-width:1920px) and (max-width:2559.95px)': {//xl
        fontSize: "5vh",
      },
  },
  select: {
    "&:after": {
      borderBottomColor: "blue",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      backgroundColor:"#0123b4",borderRadius:"8px"
    },
    "& .MuiSelect-root ": {
      marginTop:"-2vh"
    }
  },
   switchselector:{
    height:'3.5vh',
    [theme.breakpoints.down('sm')]: {
      width:'8.5vh'
    },
    [theme.breakpoints.up('md')]: {
      width:'7vh'
    },
    [theme.breakpoints.up('lg')]: {
      width:'10.5vh'
    },
    [theme.breakpoints.up('xl')]: {
      width:'10.5vh'
    },
  },
}));

export default function GlChillerPage(props) {
  const classes = useStyles();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [openerr,setOpenerr] = React.useState(false);
  const [errmsg,setErrmsg] = React.useState('');
  const [modal, setModal] = useState(false);
  const [modalHeading, setModalHeading] = useState(false);
  const [approach, setApproach] = useState([]);
  const [range, setRange] = useState([]);
  const [placeholder, setPlaceholder] = useState([]);
  const [fan, setFan] = useState([]);
  const [allCTData, setAllCTData] = React.useState([])
  const [ctData, setCTData] = React.useState({})
  const [ctOutVlvOnOffCmd, setCTOutVlvOnOffCmd] = useState([]);
  const [ctFanONOffSS, setCTFanONOffSS] = React.useState(0)
  const [ctFanAMSS, setCTFanAMSS] = React.useState("")
  const [selectedCtId, setSelectedCtId] = useState(props.location.state.id);
  const [selectedCtName, setSelectedCtName] = useState(props.location.state.data.name);
  const CPM_Status = localStorage.getItem("CPM_AM_Status");

  const mapRef = React.createRef()

  
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

  const dataSeries = [
    [
      {
        date: "2014-04-29",
        value: 79470783,
      },
      {
        date: "2014-04-30",
        value: 80170783,
      },
    ],
    [
      {
        date: "2014-01-01",
        value: 150000000,
      },
      {
        date: "2014-01-02",
        value: 160379978,
      },
      {
        date: "2014-01-03",
        value: 170493749,
      },
      {
        date: "2014-01-04",
        value: 160785250,
      },
      {
        date: "2014-01-05",
        value: 167391904,
      },
      {
        date: "2014-01-06",
        value: 161576838,
      },
      {
        date: "2014-01-07",
        value: 161413854,
      },
      {
        date: "2014-01-08",
        value: 152177211,
      },
      {
        date: "2014-01-09",
        value: 140762210,
      },
      {
        date: "2014-01-10",
        value: 144381072,
      },
      {
        date: "2014-01-11",
        value: 154352310,
      },
      {
        date: "2014-01-12",
        value: 165531790,
      },
      {
        date: "2014-01-13",
        value: 175748881,
      },
      {
        date: "2014-01-14",
        value: 187064037,
      },
      {
        date: "2014-01-15",
        value: 197520685,
      },
      {
        date: "2014-01-16",
        value: 210176418,
      },
      {
        date: "2014-01-17",
        value: 196122924,
      },
      {
        date: "2014-01-18",
        value: 207337480,
      },
      {
        date: "2014-01-19",
        value: 200258882,
      },
      {
        date: "2014-01-20",
        value: 186829538,
      },
      {
        date: "2014-01-21",
        value: 192456897,
      },
      {
        date: "2014-01-22",
        value: 204299711,
      },
      {
        date: "2014-01-23",
        value: 192759017,
      },
      {
        date: "2014-01-24",
        value: 203596183,
      },
      {
        date: "2014-01-25",
        value: 208107346,
      },
      {
        date: "2014-01-26",
        value: 196359852,
      },
      {
        date: "2014-01-27",
        value: 192570783,
      },
      {
        date: "2014-01-28",
        value: 177967768,
      },
      {
        date: "2014-01-29",
        value: 190632803,
      },
      {
        date: "2014-01-30",
        value: 203725316,
      },
      {
        date: "2014-01-31",
        value: 218226177,
      },
      {
        date: "2014-02-01",
        value: 210698669,
      },
      {
        date: "2014-02-02",
        value: 217640656,
      },
      {
        date: "2014-02-03",
        value: 216142362,
      },
      {
        date: "2014-02-04",
        value: 201410971,
      },
      {
        date: "2014-02-05",
        value: 196704289,
      },
      {
        date: "2014-02-06",
        value: 190436945,
      },
      {
        date: "2014-02-07",
        value: 178891686,
      },
      {
        date: "2014-02-08",
        value: 171613962,
      },
      {
        date: "2014-02-09",
        value: 157579773,
      },
      {
        date: "2014-02-10",
        value: 158677098,
      },
      {
        date: "2014-02-11",
        value: 147129977,
      },
      {
        date: "2014-02-12",
        value: 151561876,
      },
      {
        date: "2014-02-13",
        value: 151627421,
      },
      {
        date: "2014-02-14",
        value: 143543872,
      },
      {
        date: "2014-02-15",
        value: 136581057,
      },
      {
        date: "2014-02-16",
        value: 135560715,
      },
      {
        date: "2014-02-17",
        value: 122625263,
      },
      {
        date: "2014-02-18",
        value: 112091484,
      },
      {
        date: "2014-02-19",
        value: 98810329,
      },
      {
        date: "2014-02-20",
        value: 99882912,
      },
      {
        date: "2014-02-21",
        value: 94943095,
      },
      {
        date: "2014-02-22",
        value: 104875743,
      },
      {
        date: "2014-02-23",
        value: 116383678,
      },
      {
        date: "2014-02-24",
        value: 125028841,
      },
      {
        date: "2014-02-25",
        value: 123967310,
      },
      {
        date: "2014-02-26",
        value: 133167029,
      },
      {
        date: "2014-02-27",
        value: 128577263,
      },
      {
        date: "2014-02-28",
        value: 115836969,
      },
      {
        date: "2014-03-01",
        value: 119264529,
      },
      {
        date: "2014-03-02",
        value: 109363374,
      },
      {
        date: "2014-03-03",
        value: 113985628,
      },
      {
        date: "2014-03-04",
        value: 114650999,
      },
      {
        date: "2014-03-05",
        value: 110866108,
      },
      {
        date: "2014-03-06",
        value: 96473454,
      },
      {
        date: "2014-03-07",
        value: 104075886,
      },
      {
        date: "2014-03-08",
        value: 103568384,
      },
      {
        date: "2014-03-09",
        value: 101534883,
      },
      {
        date: "2014-03-10",
        value: 115825447,
      },
      {
        date: "2014-03-11",
        value: 126133916,
      },
      {
        date: "2014-03-12",
        value: 116502109,
      },
      {
        date: "2014-03-13",
        value: 130169411,
      },
      {
        date: "2014-03-14",
        value: 124296886,
      },
      {
        date: "2014-03-15",
        value: 126347399,
      },
      {
        date: "2014-03-16",
        value: 131483669,
      },
      {
        date: "2014-03-17",
        value: 142811333,
      },
      {
        date: "2014-03-18",
        value: 129675396,
      },
      {
        date: "2014-03-19",
        value: 115514483,
      },
      {
        date: "2014-03-20",
        value: 117630630,
      },
      {
        date: "2014-03-21",
        value: 122340239,
      },
      {
        date: "2014-03-22",
        value: 132349091,
      },
      {
        date: "2014-03-23",
        value: 125613305,
      },
      {
        date: "2014-03-24",
        value: 135592466,
      },
      {
        date: "2014-03-25",
        value: 123408762,
      },
      {
        date: "2014-03-26",
        value: 111991454,
      },
      {
        date: "2014-03-27",
        value: 116123955,
      },
      {
        date: "2014-03-28",
        value: 112817214,
      },
      {
        date: "2014-03-29",
        value: 113029590,
      },
      {
        date: "2014-03-30",
        value: 108753398,
      },
      {
        date: "2014-03-31",
        value: 99383763,
      },
      {
        date: "2014-04-01",
        value: 100151737,
      },
      {
        date: "2014-04-02",
        value: 94985209,
      },
      {
        date: "2014-04-03",
        value: 82913669,
      },
      {
        date: "2014-04-04",
        value: 78748268,
      },
      {
        date: "2014-04-05",
        value: 63829135,
      },
      {
        date: "2014-04-06",
        value: 78694727,
      },
      {
        date: "2014-04-07",
        value: 80868994,
      },
      {
        date: "2014-04-08",
        value: 93799013,
      },
      {
        date: "2014-04-09",
        value: 99042416,
      },
      {
        date: "2014-04-10",
        value: 97298692,
      },
      {
        date: "2014-04-11",
        value: 83353499,
      },
      {
        date: "2014-04-12",
        value: 71248129,
      },
      {
        date: "2014-04-13",
        value: 75253744,
      },
      {
        date: "2014-04-14",
        value: 68976648,
      },
      {
        date: "2014-04-15",
        value: 71002284,
      },
      {
        date: "2014-04-16",
        value: 75052401,
      },
      {
        date: "2014-04-17",
        value: 83894030,
      },
      {
        date: "2014-04-18",
        value: 90236528,
      },
      {
        date: "2014-04-19",
        value: 99739114,
      },
      {
        date: "2014-04-20",
        value: 96407136,
      },
      {
        date: "2014-04-21",
        value: 108323177,
      },
      {
        date: "2014-04-22",
        value: 101578914,
      },
      {
        date: "2014-04-23",
        value: 115877608,
      },
      {
        date: "2014-04-24",
        value: 112088857,
      },
      {
        date: "2014-04-25",
        value: 112071353,
      },
      {
        date: "2014-04-26",
        value: 101790062,
      },
      {
        date: "2014-04-27",
        value: 115003761,
      },
      {
        date: "2014-04-28",
        value: 120457727,
      },
      {
        date: "2014-04-29",
        value: 118253926,
      },
      {
        date: "2014-04-30",
        value: 117956992,
      },
    ],
  ];

  const options = [  
    {
      selectedFontColor: "white",
      label: "Off",
      value: 0,
      selectedBackgroundColor: "red",
    },
    {
      selectedFontColor: "white",
      label: "On",
      value: 1,
      selectedBackgroundColor: "green",
    },
    // {
    //   selectedFontColor: "white",
    //   label: "AUTO",
    //   value: 2,
    //   selectedBackgroundColor: "orange",
    // },
  ];

  const options1 = [
   
    {
      selectedFontColor: "white",
      label: "Manual",
      value: 0,
      selectedBackgroundColor: "red",
    },
    {
      selectedFontColor: "white",
      label: "Auto",
      value: 1,
      selectedBackgroundColor: "green",
    },
    // {
    //   selectedFontColor: "white",
    //   label: "AUTO",
    //   value: 2,
    //   selectedBackgroundColor: "orange",
    // },
  ];

  const initialSelectedIndex1 = options1.findIndex(
    ({ value }) => value === "Auto"
  );

  const handleClick = (data) => {
    setModalHeading(data)
    setModal(true)
  }

  const handleClose = () => {
    setModal(false);
  };

  const handleCTChange = (id,name) => {
    setSelectedCtId(id)
    setSelectedCtName(name)
    Object.values(allCTData).map((res)=>{
      if( id === res.id )
      {        
        console.log("-->",res.id)
        setCTData(res)
        if(res.Eqp_Attributes.CT_Out_Vlv_On_Off_Fbk){
          setCTFanONOffSS(res.Eqp_Attributes.CT_Out_Vlv_On_Off_Fbk.presentValue=="active"? 1:0)
        }
        if(res.Eqp_Attributes.CT_Fan_AM_SS){
          setCTFanAMSS(res.Eqp_Attributes.CT_Fan_AM_SS.presentValue)
        }
        if(res.Eqp_Attributes.CT_Out_Vlv_On_Off_Cmd){
          setCTOutVlvOnOffCmd(res.Eqp_Attributes.CT_Out_Vlv_On_Off_Cmd.presentValue)
        }
        api.floor.coolingTowerGraphDataLast1Hr(res.id).then((response)=>{
          if(response.graphData.length){
            if(response.graphData[0]['approach']){
              setApproach(response.graphData[0]['approach'])
            }
             if(response.graphData[0]['range']){
              setRange(response.graphData[0]['range'])
           }
             if(response.graphData[0]['placeholder']){
              setPlaceholder(response.graphData[0]['placeholder'])
            }
             if(response.graphData[0]['fan']){
              setFan(response.graphData[0]['fan'])
            }
          }
        }).catch((error)=>{
          setOpenerr(true)
          if(error.response){
            setErrmsg(error.response.data.message)
            }else{
              setErrmsg('')
            }     
           })
      }
    })
  }

  useEffect(()=>{
    api.floor.cpmGetDevData().then((res)=>{
      if(res.NONGL_SS_COOLING_TOWERS){
        console.log('object==>',res.
          NONGL_SS_COOLING_TOWERS)
        let data=Object.values(res.NONGL_SS_COOLING_TOWERS).sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
        setAllCTData(data) 
      }
      Object.values(res.NONGL_SS_COOLING_TOWERS).map((res)=>{
        if(props.location.state.id === res.id )
          {
          setCTData(res)
          if(res.Eqp_Attributes.CT_Out_Vlv_On_Off_Fbk){
            setCTFanONOffSS(res.Eqp_Attributes.CT_Out_Vlv_On_Off_Fbk.presentValue == "active"? 1:0)
          }
          if(res.Eqp_Attributes.CT_Fan_AM_SS){
            setCTFanAMSS(res.Eqp_Attributes.CT_Fan_AM_SS.presentValue)
          }
          if(res.Eqp_Attributes.CT_Out_Vlv_On_Off_Cmd){
            setCTOutVlvOnOffCmd(res.Eqp_Attributes.CT_Out_Vlv_On_Off_Cmd.presentValue)
          }
          if(res.id){
          api.floor.coolingTowerGraphDataLast1Hr(res.id).then((response)=>{
            if(response.graphData.length){
              if(response.graphData[0]['approach']){
                setApproach(response.graphData[0]['approach'])
              }
              if(response.graphData[0]['range']){
                setRange(response.graphData[0]['range'])
            }
              if(response.graphData[0]['placeholder']){
                setPlaceholder(response.graphData[0]['placeholder'])
              }
              if(response.graphData[0]['fan']){
                setFan(response.graphData[0]['fan'])
              }
            }
          }).catch((error)=>{
          setOpenerr(true)
          if(error.response){
            setErrmsg(error.response.data.message)
            }else{
              setErrmsg('')
            }     
          })
          }
          }  
      })
    }).catch((error)=>{
      setOpenerr(true)
      if(error.response){
        setErrmsg(error.response.data.message)
        }else{
          setErrmsg('')
        }
    })
  //   api.floor.devicemap(campus_id,'COOLINGTOWERS').then((res)=>{
  //     setAllCTData(res)
  //     res.map((res)=>{
  //       if(props.location.state.data.ssid === res.ssid )
  //       {
  //         setCTData(res)
  //   api.floor.coolingTowerGraphDataLast1Hr(res.ssid).then((response)=>{
  //     if(response.graphData.length){
  //       if(response.graphData[0]['approach']){
  //         setApproach(response.graphData[0]['approach'])
  //       }
  //        if(response.graphData[0]['range']){
  //         setRange(response.graphData[0]['range'])
  //      }
  //        if(response.graphData[0]['placeholder']){
  //         setPlaceholder(response.graphData[0]['placeholder'])
  //       }
  //        if(response.graphData[0]['fan']){
  //         setFan(response.graphData[0]['fan'])
  //       }
  //     }
  //   }).catch((error)=>{
  //     setOpenerr(true)
  //     if(error.response){
  //       setErrmsg(error.response.data.message)
  //       }else{
  //         setErrmsg('')
  //       }     
  //      })
  //     }
  //   })
  // }).catch((error)=>{
  //   setOpenerr(true)
  //   if(error.response){
  //     setErrmsg(error.response.data.message)
  //     }else{
  //       setErrmsg('')
  //     }
  // })
  // const timer = setInterval(() => {
  //   if(selectedCtId){
  //     api.floor.devicemap(campus_id,'CHILLER').then((res)=>{
  //       res.map((res)=>{
  //         if(selectedCtId === res.ssid )
  //       {
  //         // console.log("jahn",res.ssid)
  //         setCTData(res)
  //         api.floor.coolingTowerGraphDataLast1Hr(selectedCtId).then((response)=>{
  //           if(response.graphData.length){
  //             if(response.graphData[0]['approach']){
  //               setApproach(response.graphData[0]['approach'])
  //             }
  //              if(response.graphData[0]['range']){
  //               setRange(response.graphData[0]['range'])
  //            }
  //              if(response.graphData[0]['placeholder']){
  //               setPlaceholder(response.graphData[0]['placeholder'])
  //             }
  //              if(response.graphData[0]['fan']){
  //               setFan(response.graphData[0]['fan'])
  //             }
  //           }
  //         }).catch((error)=>{
  //           // setOpenerr(true)
  //           // if(error.response.data.message){
  //           //   setErrmsg(error.response.data.message)
  //           //   }else{
  //           //     setErrmsg('')
  //           //   }     
  //            })
  //       }
  //       })
  //     }).catch((error)=>{
  //       // setOpenerr(true)
  //       // if(error.response.data.message){
  //       //   setErrmsg(error.response.data.message)
  //       //   }else{
  //       //     setErrmsg('')
  //       //   }
  //       })
  //   }
    
  // }, 5000);
  const timer = setInterval(() => {
    console.log('selectedChId in interval------------>',selectedCtId)
    if(selectedCtId){
      api.floor.cpmGetDevData().then((res)=>{
        if(res.NONGL_SS_COOLING_TOWERS){
          // console.log('object==>',res.NONGL_SS_COOLING_TOWERS)
          let data=Object.values(res.NONGL_SS_COOLING_TOWERS).sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
          setAllCTData(data) 
        }
        Object.values(res.NONGL_SS_COOLING_TOWERS).map((res)=>{
          if(props.location.state.data === res.id )
            {
            setCTData(res)
            if(res.Eqp_Attributes.CT_Out_Vlv_On_Off_Fbk){
              setCTFanONOffSS(res.Eqp_Attributes.CT_Out_Vlv_On_Off_Fbk.presentValue == "active"? 1:0)
            }
            if(res.Eqp_Attributes.CT_Fan_AM_SS){
              setCTFanAMSS(res.Eqp_Attributes.CT_Fan_AM_SS.presentValue)
            }
            if(res.Eqp_Attributes.CT_Out_Vlv_On_Off_Cmd){
              setCTOutVlvOnOffCmd(res.Eqp_Attributes.CT_Out_Vlv_On_Off_Cmd.presentValue)
            }
            if(res.id){
            api.floor.coolingTowerGraphDataLast1Hr(res.id).then((response)=>{
              if(response.graphData.length){
                if(response.graphData[0]['approach']){
                  setApproach(response.graphData[0]['approach'])
                }
                if(response.graphData[0]['range']){
                  setRange(response.graphData[0]['range'])
              }
                if(response.graphData[0]['placeholder']){
                  setPlaceholder(response.graphData[0]['placeholder'])
                }
                if(response.graphData[0]['fan']){
                  setFan(response.graphData[0]['fan'])
                }
              }
            }).catch((error)=>{
            setOpenerr(true)
            if(error.response){
              setErrmsg(error.response.data.message)
              }else{
                setErrmsg('')
              }     
            })
            }
            }  
        })
      }).catch((error)=>{
        setOpenerr(true)
        if(error.response){
          setErrmsg(error.response.data.message)
          }else{
            setErrmsg('')
          }
      })
    }
  }, 5000);
  return () => clearInterval(timer);
  },[selectedCtId])
  
  const onChange = (newValue) => {
    setCTFanONOffSS(newValue)
    let id= selectedCtId;
    let action= newValue == 0? "TURN_OFF_COOLING_TOWER":"TURN_ON_COOLING_TOWER";
    let ss_type= "NONGL_SS_COOLING_TOWERS";
    if(id && action && ss_type){
    let req={
        id,
        action,
        ss_type
    }
    api.floor.cpmOnOffControl(req).then((response)=>{
      setCTFanONOffSS(response.startsWith('Working with a Scenario')?(newValue == 0?1:0):newValue)
      toast({
        type: response.startsWith('Working with a Scenario')?"error":"success",
        icon: response.startsWith('Working with a Scenario')?"exclamation triangle":"check circle",
        title:response.startsWith('Working with a Scenario')?"Error":"Success",
        description: response,
        time: 2000,
      });
    })
    .catch((err)=>{
      setOpenerr(true)
      setErrmsg(err)
    })
  }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={1}>
            {/* Left part */}
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9} xxl={9}>
                <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <FormControl
                              variant="filled"
                              size="large"
                              className={`${classes.select} ${classes.formControl}`}
                              style={{
                                width: "max-content",
                                minWidth: "100%",
                                backgroundColor: "#eeeef5",
                                fontFamily: "Arial"
                              }}
                            >
                              <Select
                                labelId="filled-hidden-label-small"
                                id="demo-simple-select-outlined"
                                label="Chiller"
                                // className={classes.select}
                                value={selectedCtName}
                                style={{
                                  fontWeight: "bold",
                                  height: "6vh",
                                  borderRadius: '0.8vw',
                                  fontFamily: "Arial"
                                }}
                                disableUnderline
                              >
                                {Object.values(allCTData).map((_item) => (
                                  <MenuItem
                                    key={_item.id}
                                    value={_item.name}
                                    onClick={() => handleCTChange(_item.id,_item.name)}
                                  >
                                    {_item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                        </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Card className={classes.paper} style={{backgroundColor:'#E5E5E5'}}>
                            <Map
                                              ref={mapRef}
                                              doubleClickZoom={false}
                                              zoomControl={false}
                                              dragging={false}
                                              scrollWheelZoom={false}
                                              crs={Leaflet.CRS.Simple}
                                              center={[0, 0]}
                                              attributionControl={false}
                                              // bounds={[[0, 0], [600, 730]]}
                                              bounds={[[0, 0], [420, 600]]}
                                              className={"floor-map"}
                                              style={{height: "48vh"}}
                                              onClick={(e) => { console.log({ x: e.latlng.lat, y: e.latlng.lng }) }}
                                            >
                                              <ImageOverlay
                                                interactive
                                                // url={'https://localhost/' + image + '.png'}
                                                url={CoolingTowerImg}
                                                // bounds={[[50, 15], [600, 730]]}
                                                // bounds={[[100, -8], [525, 640]]}
                                                bounds={[[0, 0], [420, 590]]}
                                              />
                            </Map>
                    </Card>
                </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1} style={{marginTop:"1vh"}}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Card className={classes.graphpaper}>
                                      {/* <TimeSeriesUpsStatic
                                  data={dataSeries}
                                  style={{ width: "100%", height: "100%" }}
                                ></TimeSeriesUpsStatic> */}
                                <div className={classes.CardHeadFont} style={{fontWeight:'bold',color:'black',marginTop:'0.5vh'}}>Approach(°C)</div>
                                <TimeSeriesChart
                                style={{ width: "100%", height: "50%" }}
                                data={approach}
                                param='Approach'
                              />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Card className={classes.graphpaper}>
                        {/* <TimeSeriesUpsStatic
                                  data={dataSeries}
                                  style={{ width: "100%", height: "100%" }}
                                ></TimeSeriesUpsStatic> */}
                                <div className={classes.CardHeadFont} style={{fontWeight:'bold',color:'black',marginTop:'0.5vh'}}>Range(°C)</div>
                                <TimeSeriesChart
                                style={{ width: "100%", height: "50%" }}
                                data={range}
                                param='range'
                              />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Card className={classes.graphpaper}>
                        {/* <TimeSeriesUpsStatic
                                  data={dataSeries}
                                  style={{ width: "100%", height: "100%" }}
                                ></TimeSeriesUpsStatic> */}
                                <div className={classes.CardHeadFont} style={{fontWeight:'bold',color:'black',marginTop:'0.5vh'}}>Placeholder</div>
                                <TimeSeriesChart
                                style={{ width: "100%", height: "50%" }}
                                data={placeholder}
                                param='Place Holder'
                              />
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            {/* Right part */}
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                <Grid container item xs={12} spacing={1} direction="column">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Paper style={{maxWidth:"100%",color: "white",backgroundColor: " #0123b4",borderRadius: "10px",height:"6vh",display: "flex", justifyContent: "flex-start", paddingLeft: "16px",alignItems: "center"}}>
                        <Typography style={{ color: "#ffffff",fontFamily:"Arial",fontSize:"2vh",textAlign: "left"}}>Cooling Tower</Typography>
                      </Paper>
                    </Grid> 
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Card className={classes.graphpaper} style={{marginTop:"0vh",height:'15.5vh'}}>
                                <Grid container spacing={1}>
                                  <Grid container item xs={12} style={{marginTop:'1.5vh',textAlign:'left'}} 
                                          direction="row"  alignItems="center" justify="flex-start" 
                                          >  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                                <div className={classes.CardHeadFont} style={{fontWeight:'bold',color:'black'}}>Cooling Status</div>
                                              </Grid>
                                              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>     
                                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <div 
                                                style={{pointerEvents:CPM_Status == "true"?"none":"", opacity:CPM_Status == "true"?"0.4":""}}
                                                className={classes.switchselector}>
                                                <SwitchSelector
                                                  options={options}
                                                  // initialSelectedIndex={initialSelectedIndex}
                                                  onChange={onChange}
                                                  forcedSelectedIndex={ctFanONOffSS}
                                                  backgroundColor={"rgba(0, 0, 0, 0.04)"}
                                                  fontColor={"#000"}
                                                  selectedFontColor={"#000"}
                                                  optionBorderRadius={5}
                                                  wrapperBorderRadius={7}
                                                  fontSize={8}
                                                  /></div>
                                              </Grid>
                                  </Grid>
                                  <Grid container item xs={12} style={{textAlign:'left'}} 
                                          direction="row"  alignItems="center" justify="flex-start" 
                                          >  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                                <div className={classes.CardHeadFont} style={{fontWeight:'bold',color:'black'}}>Mode</div>
                                              </Grid>
                                              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>     
                                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <div style={{pointerEvents:CPM_Status == "true"?"none":"", opacity:CPM_Status == "true"?"0.4":""}}
                                                className={classes.switchselector}>
                                                <SwitchSelector
                                                  options={options1}
                                                  // initialSelectedIndex={initialSelectedIndex}
                                                  forcedSelectedIndex={ctFanAMSS}
                                                  backgroundColor={"rgba(0, 0, 0, 0.04)"}
                                                  fontColor={"#000"}
                                                  selectedFontColor={"#000"}
                                                  optionBorderRadius={5}
                                                  wrapperBorderRadius={7}
                                                  fontSize={8}
                                                  /></div>
                                              </Grid>
                                  </Grid>
                                  <Grid  container item xs={12} style={{textAlign:'left'}} 
                                          direction="row"  alignItems="center" justify="flex-start" 
                                          >  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                                <div className={classes.CardHeadFont} style={{color:'black',fontWeight:'bold'}}>Valve</div>
                                              </Grid>
                                              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>     
                                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                              <Paper style={{height:'3.5vh',width:"9.5vh",borderRadius:"2vh",backgroundColor:"#0123b4",color:"white",textAlign:"center", display: 'flex',alignItems: 'center', justifyContent: 'center'}}>{ctOutVlvOnOffCmd == 0? "Close":"Open"}</Paper>
                                              </Grid>
                                  </Grid>
                                </Grid>
                                {/* <Grid xs={12}
                                direction="row"
                                style={{
                                  // height: "9vh",
                                  alignItems: "start",
                                  justifyContent: "start",
                                  textAlign:"start",
                                  display: "flex",
                                  marginLeft:"2vh",
                                  // marginTop:"2vh"
                                }}
                                >
                                  
                                <Grid item xs={9}>
                                <Grid container xs={12} spacing ={2} direction="column" style={{marginTop:'1vh'}}>
                                <Grid item xs={12}>
                                <Typography variant="string" style={{color:'black'}}>
                                Cooling Status
                                </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                <Typography variant="string" style={{color:'black'}}>
                                Mode
                                </Typography>
                                </Grid>
                                <Grid item xs={12} style={{marginTop:'-1vh'}}>
                                <Typography variant="string" style={{color:'black'}}>
                                Valve
                                </Typography>
                                </Grid>
                                </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                <Grid container xs={12} direction="column" spacing={1} style={{marginTop:'1vh'}}>    
                                        <Grid item xs={12}>
                                          <div
                                            className="your-required-wrapper"
                                            style={{ width: "12vh", marginLeft: "-3.5vh",height:"3.3vh" }}
                                          >
                                            <SwitchSelector
                                              // onChange={onChange}
                                              options={options}
                                              // initialSelectedIndex={initialSelectedIndex}
                                              forcedSelectedIndex='On'
                                              backgroundColor={"rgba(0, 0, 0, 0.04)"}
                                              fontColor={"#000"}
                                              selectedFontColor={"#000"}
                                              // border="5"
                                              optionBorderRadius={5}
                                              wrapperBorderRadius={8}
                                              fontSize={10}
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <div
                                            className="your-required-wrapper"
                                            style={{ width: "12vh", marginLeft: "-3.5vh",height:"3.3vh" }}
                                          >
                                            <SwitchSelector
                                              // onChange={onChange}
                                              options={options1}
                                              // initialSelectedIndex={initialSelectedIndex}
                                              forcedSelectedIndex='On'
                                              backgroundColor={"rgba(0, 0, 0, 0.04)"}
                                              fontColor={"#000"}
                                              selectedFontColor={"#000"}
                                              // border="5"
                                              optionBorderRadius={5}
                                              wrapperBorderRadius={8}
                                              fontSize={10}
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Paper className={classes.controls_paper} style={{ backgroundColor:"#00CCFF",display:'flex',alignItems:'center', justifyContent: 'center',marginLeft:'-3vh'}}>
                                            <Typography style={{color:'white',fontWeight:'bold'}}>Open</Typography>
                                            </Paper>
                                        </Grid>
                                </Grid>
                                </Grid>
                                </Grid> */}
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Paper style={{maxWidth:"100%",color: "white",backgroundColor: " #0123b4",borderRadius: "10px",height:"6vh",display: "flex", justifyContent: "flex-start", paddingLeft: "16px",alignItems: "center"}}>
                        <Typography style={{ color: "#ffffff",fontFamily:"Arial",fontSize:"2vh",textAlign: "left"}}>Cooling Tower</Typography>
                      </Paper>
                    </Grid>    
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Card
                          className={classes.paper}
                          style={{
                            marginTop:"0vh",
                            borderRadius: "20px",
                            height: "25.9vh",
                          }}
                        >
                                <Grid container item xs={12} spacing={1}>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                              <Card className={classes.semicircularbar} 
                                              // onClick={() => handleClick('WB')}
                                               style={{fontWeight:'bold',color:'black',cursor:'pointer'}}>
                                                <div className={classes.CardHeadFont}>WB</div>
                                                <div className={classes.CardParamFont} style={{fontWeight:'bold',color:'#0123B4',whiteSpace:"nowrap"}}>26°C</div>
                                              </Card>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                              <Card className={classes.semicircularbar} 
                                              // onClick={() => handleClick('DB')} 
                                              style={{fontWeight:'bold',color:'black',cursor:'pointer'}}>
                                              <div className={classes.CardHeadFont}>DB</div>
                                              <div className={classes.CardParamFont} style={{fontWeight:'bold',color:'#0123B4',whiteSpace:"nowrap"}}>28°C</div>
                                              </Card>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                              <Card className={classes.semicircularbar}  
                                              // onClick={() => handleClick('Range')}  
                                              style={{fontWeight:'bold',color:'black',cursor:'pointer'}}>
                                              <div className={classes.CardHeadFont}>Range</div>
                                              <div className={classes.CardParamFont} style={{fontWeight:'bold',color:'#0123B4',whiteSpace:"nowrap"}}>10°C</div>
                                              </Card>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                              <Card className={classes.semicircularbar} 
                                              //  onClick={() => handleClick('Apporach')} 
                                                style={{fontWeight:'bold',color:'black',cursor:'pointer'}}>
                                              <div className={classes.CardHeadFont}>Apporach</div>
                                              <div className={classes.CardParamFont} style={{fontWeight:'bold',color:'#0123B4',whiteSpace:"nowrap"}}>4°C</div>
                                              </Card>
                                          </Grid>
                                </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Card className={classes.graphpaper} style={{marginTop:'0.3vh'}}>
                        {/* <TimeSeriesUpsStatic
                                  data={dataSeries}
                                  style={{ width: "100%", height: "100%" }}
                                ></TimeSeriesUpsStatic> */}
                                
                                <div className={classes.CardHeadFont} style={{fontWeight:'bold',color:'black',marginTop:'0.5vh'}}>Fan Horse Power (kW)</div>
                                <TimeSeriesChart
                                style={{ width: "100%", height: "50%" }}
                                data={fan}
                                param='Fan'
                              />
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
      </Grid>
      
      <Dialog fullWidth={fullWidth} maxWidth={maxWidth}
      onClose={handleClose} aria-labelledby="customized-dialog-title"  open={modal} classes={{ paper: classes.customDialog }}>
                      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                     {modalHeading}
                      </DialogTitle>
                      <DialogContent dividers>
                      <TimeSeriesChillerStatic
                                  // name='kW/TR'
                                  data={dataSeries}
                                  style={{ width: "100%", height: "50%" }}
                                  // style={{ width: "100%", height: "100%" }}
                                ></TimeSeriesChillerStatic>
                      </DialogContent>
      </Dialog> 
      <SemanticToastContainer position="top-center" />  
    </div>
  )
}
