import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  Card,
} from "@material-ui/core";
import api from "../../api";
import TimeSeriesVav from "../TimeSeriesVav";
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Blink from "react-blink-text";
import SwitchSelector from "react-switch-selector";

const StyledTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "#FEE8DA",
    // backgroundColor: "red",
    fontSize: "12px"
  }
})(Tooltip);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  customDialog: {
    cursor: "pointer",
    // Set the desired width for the dialog
    width: '470px', // Adjust this value as needed
    height: '200px'
  },
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    width: "100%",
  },
  inputField: {
    margin: theme.spacing(1),
  },
  setptbutton: {
    width: '15vh',
    borderRadius: '8px',
    height: '5vh',
    fontFamily: 'Arial',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '28vh',
    marginTop: '1.5vh'
  },
  paper: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    // boxShadow:'0px 8px 40px #0123B433;',
    boxShadow: "1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor: "#fcfafa",
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: '12px',
    opacity: '1'
  },
  paper1: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    // boxShadow:'0px 0px 10px #0123B421',
    boxShadow: "1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor: "#fcfafa",
    opacity: '1',
    borderRadius: '12px',
    height: '15.7vh',
    // display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    whiteSpace: "nowrap",
    color: "#000000",
    marginTop: '1vh',
    font: 'normal normal medium 17px/60px Bw Seido Round',
    opacity: '1',
    fontWeight: 'bold',
    // fontSize:'1.7vh'
  },
  semicircleBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    marginTop: '-0.8vh'
  },
  cardbody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    fontSize: "4vh",
    fontWeight: 'bold',
    opacity: '1',
    color: '#0123B4'
  },
  formControl: {
    autosize: true,
    clearable: false,
  },
  select: {
    "&:after": {
      borderBottomColor: "blue",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      backgroundColor: "#0123b4", borderRadius: "8px"
    },
    "& .MuiSelect-root ": {
      marginTop: "-2vh"
    }
  },
  CardHeadFont: {
    '@media (min-width:0px) and (max-width:599.95px)': {//xs
      fontSize: "1.5vh",
    },
    '@media (min-width:600px) and (max-width:959.95px)': {//sm
      fontSize: "1.9vh",
      borderRadius: '10px'
    },
    '@media (min-width:960px) and (max-width:1279.95px)': {//md
      fontSize: "1.4vh",
    },
    '@media (min-width:1280px) and (max-width:1919.95px)': {//lg
      fontSize: "1.6vh",
    },
    '@media (min-width:1920px) and (max-width:2559.95px)': {//xl
      fontSize: "1.7vh",
    },
  },
}));

function GLEquipmentType2(props) {
  const classes = useStyles();
  const role_id = localStorage.getItem("roleID")
  const [eqpType, setEqpType] = useState(props.eqpType);
  const [heading, setEqpHeading] = useState(props.heading);
  const [floor, setFloor] = useState([]);
  const [fdata, setFdata] = useState(localStorage.getItem("floorName"));
  const zone_data = useSelector((state) => state.inDashboard.locationData);
  const buildingID = useSelector((state) => state.isLogged.data.building.id);
  const [eqpList, setEqpList] = useState([]);
  const [disable, setDisable] = useState(false);
  const [onOff, setOnOff] = useState(1);
  const [data, setData] = useState("");
  const [modal, setModal] = useState(false);
  const [errmsg, setErrmsg] = React.useState('');
  const [fid, setFId] = useState(localStorage.getItem('floorID'));
  const [openerr, setOpenerr] = React.useState(false);
  const user_id = localStorage.getItem("userID")
  const [openmodal, setOpenModal] = useState(false);
  const [blinkText, setBlinkText] = useState(false);
  const [equipName, setequipName] = useState('');
  const [eqp1, setEqp1] = useState({});
  const isNumeric = (str) => !isNaN(str) && !isNaN(parseFloat(str));
  const isAlphabetic = (str) => /^[a-zA-Z]+$/.test(str);
  const isAlphanumeric = (str) => /^[a-zA-Z0-9]+$/.test(str);
  // const options = [
  //   {
  //     label: "Card View",
  //     value: "cardview",
  //     selectedBackgroundColor: "#0123b4",
  //   },
  //   {
  //     label: "Table View",
  //     value: "tableview",
  //     selectedBackgroundColor: "#0123b4",
  //   },
  // ];

  const options = [
    {
      selectedFontColor: "white",
      label: "OFF",
      value: 0,
      selectedBackgroundColor: "red",
    },
    {
      selectedFontColor: "white",
      label: "ON",
      value: 1,
      selectedBackgroundColor: "green",
    },
    // {
    //   selectedFontColor: "white",
    //   label: "AUTO",
    //   value: 2,
    //   selectedBackgroundColor: "orange",
    // },
  ];

  useEffect(() => {
    let zone_id = '', z_data = []
    zone_data.sort(function (a, b) { return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0); });
    zone_data.filter((_each) => _each.zone_type === 'GL_LOCATION_TYPE_FLOOR')
    console.log("zone_data", zone_data, "fdata", fdata)
    if (fdata !== null) {
      zone_data.filter((_each, i) => {
        if (_each.zone_type === 'GL_LOCATION_TYPE_FLOOR' && _each.name === fdata) {
          return zone_id = _each.uuid
        }
      })
    } else {
      zone_data.filter((_each, i) => {
        if (_each.zone_type === 'GL_LOCATION_TYPE_FLOOR') {
          z_data.push(_each);
        }
      })
      zone_id = z_data[0].uuid
      setFdata(z_data[0].name)
      setFId(zone_id[0].uuid)
    }
    if(zone_id){
    api.floor
      .newDevicemapApi(zone_id, eqpType)
      .then((res) => {
        res.sort(function (a, b) {
          const numA = parseInt(a.name, 10);
          const numB = parseInt(b.name, 10);

          if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB;
          } else {
            return a.name.localeCompare(b.name);
          }
        });
        setEqpList(res);
      }).catch((error) => {
        console.log(error)
      })
    }
    api.dashboard.getMetricData(buildingID)
      .then((res) => {
        res.sort(function (a, b) {
          return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
        });
        setFloor(res);
      }).catch((error) => {
        console.log(error)
      })

    const timer = setInterval(() => {
      if(fid){
      api.floor
        .newDevicemapApi(fid, eqpType)
        .then((res) => {
          const loaderState = res.find(item => item.loader_state === true);
          if (loaderState) {
            // Show the loader
            setBlinkText(true);
          } else {
            setBlinkText(false);
          }
          res.sort(function (a, b) {
            const numA = parseInt(a.name, 10);
            const numB = parseInt(b.name, 10);

            if (!isNaN(numA) && !isNaN(numB)) {
              return numA - numB;
            } else {
              // If either or both are not numbers, use localeCompare for string comparison
              return a.name.localeCompare(b.name);
            }
          });
          setEqpList(res);
        }).catch((error) => {
          console.log(error)
        })
      }
    }, 10000);
    return () => clearInterval(timer);
  }, [buildingID, fid]);

  const onChange = (newValue) => {
    setDisable(true)
    setTimeout(() => {     setDisable(false)    }, 30000);
    const msg = newValue === 1 ? "ON" : newValue === 0 ? "OFF" : "Auto";
    const va = newValue === 1 ? 1 :newValue === 0 ? 0: null;
    const gl_command = newValue === 1 ? "TURN_ON": newValue === 0 ? "TURN_OFF" : "Auto";
    // setOnOff(va);
    // console.log('qqqqqqqqqqqqqqqqqcont',va)
    // const req = {
    //   ss_type: "FRESH_AIR_UNIT",
    //   ss_id: deviceid,
    //   param_id: "FAU_On_Off",
    //   gl_command,
    //   value: msg,
    //   zone_type: null,
    //   zone_id: null
    // };
    // api.floor.cpmOnOffControl(req).then((res) => {
    //   if (res.message === "please connect to network") {
    //     setOnOff(Math.round(value4));
    //     toast({
    //       type: "error",
    //       icon: "exclamation triangle",
    //       title: "Error",
    //       description: "connect to network",
    //       time: 2000,
    //     });
    //   } else if (res) {
    //     setOnOff(va);
    //     toast({
    //       type: "success",
    //       icon: "check circle",
    //       title: "Success",
    //       description: "successfully turned" + msg,
    //       time: 2000,
    //     });
    //   }
    // }).catch((error)=>{
    //   setOpenerr(true)
    //   if(error.response){
    //     setErrmsg(error.response.data.message)
    //     }else{
    //       setErrmsg('No response')
    //     }    })
  };

  const handlefloorchange = (name, id) => {
    setFId(id)
    setFdata(name);
    api.floor.newDevicemapApi(id, eqpType)
      .then((res) => {
        res.sort(function (a, b) {
          const numA = parseInt(a.name, 10);
          const numB = parseInt(b.name, 10);

          if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB;
          } else {
            // If either or both are not numbers, use localeCompare for string comparison
            return a.name.localeCompare(b.name);
          }
        });
        console.log("HFCCCC",res)
        setEqpList(res);
      }).catch((error) => {
        console.log(error)
      })

  };

  const handleClose = () => {
    setOpenModal(false);
    setModal(false)
  };

  const handleSwitchChange = (res, button) =>{
    const req =  
    {
      "ss_type":res.ss_type,
      "ss_id":[String(res.ssid)],
      "gl_command":button == 0? "TURN_OFF":button == 1?"TURN_ON":"AUTO",
      "param_id":res.ss_type == "SS_VENTILATOR_1"? "VEN_On_Off": res.ss_type == "SS_BRE_FAN"? "BRE_Fan_On_Off":  res.ss_type == "SS_HTE_FAN"? "HTE_On_Off": res.ss_type == "SS_SUBE_FAN"? "SubE_Fan_On_Off":"",
      "value":button === 1 ? "ON" : button === 0 ? "OFF" : "Auto",
      "zone_type":null,
      "zone_id":null,
      "commandFrom":"UI"
  }
    // {
    //   "ss_type":res.ss_type,
    //   "ss_id":res.ssid,
    //   "gl_command":button == 'On' ? "TURN_ON": "TURN_OFF",
    //   "param_id":"",
    //   "value":button == 'On' ? "ON":"OFF",
    //   "zone_type":null,
    //   "zone_id":null
    // }
    api.floor.cpmOnOffControl(req).then((res) => {
      if (res.message === "please connect to network") {
        // setOnOff(Math.round(value4));
        toast({
          type: "error",
          icon: "exclamation triangle",
          title: "Error",
          description: "connect to network",
          time: 2000,
        });
      } else if (res.id) {
        // setOnOff(va);
        let requestID = res.id;
        toast({
          type: "success",
          icon: "check circle",
          title: "Success",
          description: "successfully turned "+button ,
          time: 2000,
        });
        const checkCommandStatus = (requestID, startTime = Date.now()) => {
          api.floor.checkCommandStatus(requestID).then((res) => {
            if (res[0].status === 'success') {
              // Command was successful, stop further API calls
              console.log("Command succeeded");
              toast({
                type: "success",
                icon: "check circle",
                title: "Command Status",
                description: "Command processed successfully",
                time: 2000,
              });
            } else if (res[0].status === 'pending') {
              console.log("Command is still Pending")
              const elapsedTime = Date.now() - startTime;
        
              if (elapsedTime < 30000) {
                console.log(" If less than 30 seconds have passed, keep checking every 3 seconds")
                setTimeout(() => checkCommandStatus(requestID, startTime), 3000);
              } else {
                console.log("Stop checking after 30 seconds and show a timeout message")
                console.log("Command timed out after 30 seconds.");
                toast({
                  type: "error",
                  icon: "clock",
                  title: "Timeout",
                  description: "Command is still pending after 30 seconds.",
                  time: 5000,
                });
              }
            }
          }).catch((error) => {
            console.error("Error checking command status:", error);
            toast({
              type: "error",
              icon: "exclamation triangle",
              title: "Error",
              description: "Error while checking command status",
              time: 2000,
            });
          });
        };
        
        checkCommandStatus(requestID);
      }
    }).catch((error)=>{
      setOpenerr(true)
      if(error.response){
        setErrmsg(error.response.data.message)
        }else{
          setErrmsg('No response')
        }    })  }

  const eachEqpData = (element, index) => {
    console.log("eachEqpData--",element)
    let active = {}
    if (element.ssid) {
      active["ssid"] = element.ssid
    }
    active["name"] = element.name
    active["ss_type"] = element.type
    for (let item in element.controlable) {
      active[item] =  (item.slice(-11) === 'Error_Alarm')? (element.controlable[item] == 'inactive'? 'Normal':'Fault'):
      (item.slice(-5) == 'AM_SS')? (element.controlable[item] == 'inactive'? 'Manual':'Auto'):
      (item.slice(-6) == 'Opn_SS')? (element.controlable[item] == 'inactive'? 'Off':'On'):
       (element.controlable[item]);
      // active[item] = element.controlable[item];
    }
    return (
      <>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
          <Card className={classes.paper} style={{ height: "43.5vh" }}>
            <Grid container xs={12} spacing={1} style={{ marginTop: '0.5vh' }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Grid container item xs={12} direction="row">
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ textAlign: 'left' }}>
                    <div style={{ color: 'black', fontWeight: 'bold', fontSize: '3vh', whiteSpace: 'nowrap' }}>{active.name}</div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ textAlign: 'right' }}>
                    {blinkText ? (
                      <Blink
                        color="blue"
                        text="o"
                        fontSize="20px"
                      ></Blink>
                    ) : null}

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={1} style={{ marginTop: '1.5vh',marginLeft:'0vh' }}>
              {Object.keys(active).sort((a, b) => {return a.localeCompare(b);}).map(pname =>
                (heading.map
              ((resp)=>((pname !== 'name') && (pname !== 'ssid')) ?
              (resp.param==pname ?
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <Card className={classes.paper1} style={{ cursor: 'pointer' }}>
                      <Grid container xs={12} spacing={1}>
                        <Grid container item xs={12}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={`${classes.cardHeading} ${classes.CardHeadFont}`}>
                            {resp.title}
                            {/* {heading[pname] ? heading[pname] : pname} */}
                          </Grid>
                        </Grid>
                        {resp.type == 'read'?
                        <Grid container item xs={12}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', whiteSpace: "nowrap", fontSize: "4vh", color: "#0123B4", fontWeight: "bold", marginTop: "1vh" }}>
                            {/* {active[pname]} */}
                            {isAlphabetic(active[pname]) ? active[pname]:parseFloat(active[pname]).toFixed(2)}
                          </Grid>
                        </Grid>
                        :
                        <Grid container item xs={12}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', whiteSpace: "nowrap", fontSize: "4vh", color: "#0123B4", fontWeight: "bold", marginTop: "1vh" }}>
                          <div style={{pointerEvents: (role_id !=2)||disable ?"none":"", opacity:(role_id !=2)||disable ?"0.4":""}}
                                                    className={classes.switchselector}>
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      alignItems: 'center',
                                                      userSelect: 'none',
                                                      width: '100%',
                                                      padding: '10px',
                                                    }}
                                                  >
                                                    {/* Manual Button */}
                                                    <button
                                                      onClick={() => {
                                                      // Set value to 0 for Manual
                                                      handleSwitchChange(active,'Off')
                                                      }}
                                                      style={{
                                                        backgroundColor: active[resp.param] === "inactive" ? 'orange' : 'grey',
                                                        color: 'white',
                                                        padding: '10px 20px',
                                                        borderRadius: '5px',
                                                        marginRight: '3px',
                                                        border: 'none',
                                                        cursor: 'pointer',height:'4vh',width:'1vh'
                                                      }}
                                                    >
                                                      <div style={{display:'flex',justifyContent:'center',fontSize:'1.6vh',marginTop:'-0.5vh'}}>Off</div>
                                                    </button>

                                                    {/* Auto Button */}
                                                    <button
                                                      onClick={() => handleSwitchChange(active,'On')} // Set value to 1 for Auto
                                                      style={{
                                                        backgroundColor: active[resp.param] === "active" ? 'green' : 'grey',
                                                        color: 'white',
                                                        padding: '10px 20px',
                                                        borderRadius: '5px',
                                                        marginLeft: '3px',
                                                        border: 'none',
                                                        cursor: 'pointer',height:'4vh',width:'1vh'
                                                      }}
                                                    >
                                                      <div  style={{display:'flex',justifyContent:'center',fontSize:'1.6vh',marginTop:'-0.5vh'}}>On</div>
                                                    </button>
                                                  </div>
                            </div>
                          </Grid>
                        </Grid>
                      }

                      </Grid>
                    </Card>
                  </Grid>
                  :<></>)
                  :
                  <></>
              )))
              }

            </Grid>
          </Card>
        </Grid>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={modal} classes={{ paper: classes.customDialog }}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {equipName} 's Temperature[°C]
          </DialogTitle>
          <DialogContent dividers>
            {/* <ReactApexCharts options={dataSet.options} series={dataSet.series}  type="area" /> */}
            {Object.keys(eqp1).map((key) => (<>
              {key == 'HTE_ZAT' ?
                <TimeSeriesVav style={{ width: "100%", height: "50%" }}
                  data={eqp1[key]}
                  param={key}
                /> : <></>
              }</>
            ))}
          </DialogContent>
        </Dialog>
      </>
    )
  }



  return (
    <div className={classes.root} style={{ marginTop: "0%" }}>
      <Grid container spacing={2}>
        {props.via == 'LandingPage' ?
            <></> :
                  <Grid container item spacing={1}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                    <FormControl variant="filled" size="small"
                      style={{ width: "max-content", minWidth: "100%", backgroundColor: "#eeeef5" }}>
                      {/* <InputLabel id="demo-simple-select-outlined-label">
                                  Floor
                              </InputLabel> */}
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Floor"
                        className={classes.select}
                        value={fdata}
                        style={{
                          borderRadius: '0.8vw', height: '6vh'
                          , fontWeight: "bold"
                        }}
                        disableUnderline
                      // onChange={handlefloorchange}
                      >
                        {floor.map((_item) => (
                          <MenuItem key={_item.name} value={_item.name}
                            onClick={() => handlefloorchange(_item.name, _item.id)}
                          >
                            {_item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  </Grid>
                   
      }
        {eqpList.length != 0 ?
          <Grid container spacing={2}>
            {eqpList.map((element, index) => (
              <>{props.via == 'LandingPage' ?
                // eachEqpData(element, index)
               <>{props.devId == element.ssid?<>
               {eachEqpData(element, index)}
               </>:<></>}</>
                :
                eachEqpData(element, index)
              }
              </>
            ))
            }
          </Grid>
          :
          <Grid container spacing={2}>
            <div style={{ marginLfet: "2vh", marginTop: "4vh" }}>
              No data available
            </div>
          </Grid>
        }
      </Grid>
      <SemanticToastContainer position="top-center" />
    </div>
  );
}

export default GLEquipmentType2;
