import React, { useEffect, useState } from "react";
import GLCPMEquipmentType1 from "./GlCPMEqpType1";


function GlChiller(props) {
  const initialState = props.location!= null ? props.location.state.name : localStorage.getItem("deviceName");
  const initialState1 = props.location != null ? props.location.state.data : localStorage.getItem("deviceID");
  const role_id = localStorage.getItem("roleID");

// console.log("props for glahuuuuu",props.location.state)


let imageParams = [
  {backgroundColor:'grey',title: 'ChW In',parameter :'CHW_Out_Temp', coordinates: [120.89,403], unit:'℃', tooltipDirection:'right',minRange:'7',maxRange:'9'},
  {backgroundColor:'grey',title: 'ChW Out',parameter :'CHW_In_Temp', coordinates: [120.89,229], unit:'℃', tooltipDirection:'left',minRange:'11',maxRange:'13'},
  // {backgroundColor:'grey',title: 'CW',parameter :'CH_On_Off', coordinates: [186.29,113.20], unit:'', tooltipDirection:'bottom',minRange:'',maxRange:''},
  // {backgroundColor:'grey',title: 'Valve status',parameter :'CH_AM_SS', coordinates: [ 203.171,417], unit:'', tooltipDirection:'right',minRange:'0',maxRange:'100'},
    ];

  let controlsCard = [
    {title: 'Chiller',parameter: 'CPM_AM_Status',component: 'Switch Selector',unit:'',type:'OnOff',label1:'OFF',label2:'ON'},
    {title: 'Chiller Mode',parameter: 'CH_AM_SS',component: 'Chip',type:'',unit:''},
    {title: 'Chiller Water Supply Temperature SP',parameter: 'CWH_ST_SP',component: 'Chip',type:'',unit:'℃'},
    // {title: 'Valve',parameter: 'CH_Out_Vlv_On_Off',component: 'Chip',type:'',unit:''}
  ]

  let paramsCard = [
    {title: 'IKW/TR',parameter: 'rh_cumulative',component: 'Text',unit:'',type:''},
    // {title: 'IKW/TR',parameter: 'rh_cumulative',component: 'Progress Bar',unit:'',type:''},
    {title: 'Chiller Loading',parameter: 'rh_cumulative',component: 'Progress Bar',unit:'',type:''},
    {title: 'Run Hours',parameter: 'rh_cumulative',component: 'Text',unit:'Hrs',type:'',label1:'',label2:''}
  ] 

  let graphsCard = [{index:'1',title:'Chilled Water Temperature[℃]',parameters:['CWH_ST','CWH_RT']}, {index:'2',title:'Condenser Water Temperature[℃]',parameters: ['CndW_HST','CndW_HRT']}, {index:'3',title:'Current[A]',parameters:['VFD_Ph_Cur']}]

  return (
    <>
    <GLCPMEquipmentType1 initialState={initialState} initialState1={initialState1} role_id={role_id} device='Chiller' eqpType='NONGL_SS_CHILLER' imageParams={imageParams} controlsCard={controlsCard} paramsCard={paramsCard} graphsCard={graphsCard} />
     {/* <GLCPMEquipmentType1 title={sample} eqpType="AHU"/> */}
    </>
  );
}

export default GlChiller;
