/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from "@material-ui/core/Icon";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";
// import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowForward from '@material-ui/icons/ArrowForwardIos';
import Collapse from "@material-ui/core/Collapse";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import BusinessIcon from '@material-ui/icons/Business';
import Home from '@material-ui/icons/House';
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { TrainRounded } from "@material-ui/icons";
import StarBorder from '@material-ui/icons/StarBorder';
import Notifications from "@material-ui/icons/Notifications";
import AssessmentIcon from '@material-ui/icons/Assessment';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import NotificationsIcon from '@material-ui/icons/Notifications';
import simplyIO from "assets/img/simply-io.jpg";

const drawerWidth = 245;
const useStyles = makeStyles(styles);
const useStyles1 = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }
}));


export default function Sidebar(props) {
  const role_id = localStorage.getItem("roleID")
  const classes = useStyles();
  const classes1 = useStyles1();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [AlOpen, setAlOpen] = React.useState(false);
  const [rou, setRou] = React.useState([]);
  const [controlOpen,setControlOpen]=React.useState(false);
  const [controlOpen1,setControlOpen1]=React.useState(false);
  const [controlOpen2,setControlOpen2]=React.useState(false);
  const [spaceOpen,setSpaceOpen]=React.useState(false);
  const [reportOpen,setReportOpen]=React.useState(false);
  const[schOpen,setSchOpen]=React.useState(false);
  const[analytics,setAnalytics]=React.useState(false);
  const[instruments,setInstruments]=React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickAl = () => {
    setAlOpen(!AlOpen);
  };
  const handleControl = () =>{
    setControlOpen(!controlOpen)
  };
  const handleControl1 = () =>{
    setControlOpen1(!controlOpen1)
  };
  const handleControl2 = () =>{
    setControlOpen2(!controlOpen2)
  };
  const handleSpace = () =>{
    setSpaceOpen(!spaceOpen)
  };
  const handleSchedule=()=>{
    setSchOpen(!schOpen)
  }
  const handleAnalytics=()=>{
    setAnalytics(!analytics)
  }
  const handleinstrument=()=>{
    setInstruments(!instruments)
  }
  // const handleSchedule=()=>{
  //   setSchOpen(!schOpen)
  // }
  const handleReport = () =>{
    console.log("resport------>")
    setReportOpen(!reportOpen)
  };

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, logoText, routes } = props;
  // var links = (
  //   <List className={classes.list}>
  //     {routes.map((prop, key) => {
  //       let urlPath = prop.path;
  //       if(urlPath.indexOf("/:id/") > -1) {
  //         let splitPaths = urlPath.split("/:id/")
  //         let assetId = splitPaths[0].includes("building") ? localStorage.getItem("buildingID") : splitPaths[0].includes("floor") ? localStorage.getItem("floorID") : splitPaths[0].includes("zone") ? localStorage.getItem("zoneID"):  localStorage.getItem("deviceID")
  //         urlPath = splitPaths[0] + "/" + assetId + "/" +splitPaths[1];
  //       }
  //       var activePro = " ";
  //       var listItemClasses;
  //       if (prop.path === "/upgrade-to-pro") {
  //         activePro = classes.activePro + " ";
  //         listItemClasses = classNames({
  //           [" " + classes[color]]: true
  //         });
  //       } else {
  //         listItemClasses = classNames({
  //           [" " + classes[color]]: activeRoute(prop.layout + urlPath)
  //         });
  //       }
  //       const whiteFontClasses = classNames({
  //         [" " + classes.whiteFont]: activeRoute(prop.layout + urlPath)
  //       });
  //       return (
  //         <NavLink
  //           to={prop.layout + urlPath}
  //           className={activePro + classes.item}
  //           activeClassName="active"
  //           key={key}
  //         >
  //           <ListItem button className={classes.itemLink + listItemClasses}>
  //             {typeof prop.icon === "string" ? (
  //               <Icon
  //                 className={classNames(classes.itemIcon, whiteFontClasses, {
  //                   [classes.itemIconRTL]: props.rtlActive
  //                 })}
  //               >
  //                 {prop.icon}
  //               </Icon>
  //             ) : (
  //               <prop.icon
  //                 className={classNames(classes.itemIcon, whiteFontClasses, {
  //                   [classes.itemIconRTL]: props.rtlActive
  //                 })}
  //               />
  //             )}
  //             <ListItemText
  //               primary={props.rtlActive ? prop.rtlName : prop.name}
  //               className={classNames(classes.itemText, whiteFontClasses, {
  //                 [classes.itemTextRTL]: props.rtlActive
  //               })}
  //               disableTypography={true}
  //             />
  //           </ListItem>
  //         </NavLink>
  //       );
  //     })}
  //   </List>
  // );
  var links=(
    <div className={classes.sidebarWrapper}>
    {/* {links} */}
    <List >
    {localStorage.getItem('roleID') == '6'?
                 <NavLink
                 to={"/admin/Parkingsolution"}
                 className={" " + classes.item}
                 activeClassName="active"
               >
                 <ListItem button className={classes.itemLink + classNames({
                   [" " + classes[color]]: activeRoute("/admin/Parkingsolution")
                 })}>
 
                   <BusinessIcon
                     className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/Parkingsolution') })}
                   />
 
                   <ListItemText
                     primary={'Parking Management'}
                     className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/Parkingsolution') })}
                     disableTypography={true}
                   />
                 </ListItem>
             </NavLink> 
:<>
    <NavLink
        to={"/admin/building/"+localStorage.getItem('buildingID')+"/dashboard"}
        className={" " + classes.item}
        activeClassName="active"
      >
        <ListItem button className={classes.itemLink }>

          <Home
            className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/building/:id/dashboard') })}
          />

          <ListItemText
            primary={'Home'}
            className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/building/:id/dashboard') })}
            disableTypography={true}
          />
        </ListItem>
    </NavLink>
      <div className={classNames({[" " + classes[color]]: controlOpen})}  style={{borderRadius: '14px',margin:'5px,5px'}}>
        <ListItem button className={classes.itemLink}  onClick={handleControl} >
          <BusinessIcon   className={classes.itemIcon}/>   
          <ListItemText
            primary={'Building Controls'}
            className={classes.itemText + classNames({ [" " + classes.whiteFont]: true })}
            disableTypography={true}
          />
           <ListItemSecondaryAction className={classes.expandIcon} >
           {controlOpen ? <ExpandMore
              onClick={handleControl} /> : <ArrowForward className={classes.arrow} onClick={handleControl} />}
            </ListItemSecondaryAction>
         </ListItem>
         <div className={classes.item} >
         <Collapse in={controlOpen} timeout="auto" unmountOnExit >
         <div className={classNames({[" " + classes[color]]: controlOpen1})}  style={{borderRadius: '14px',margin:'0px,5px',marginTop:"-20px"}}>  
         <ListItem button className={classes.itemLink}  onClick={handleControl1} >
          <ListItemText
            primary={'Plant Room'}
            className={classes.itemText + classNames({ [" " + classes.whiteFont]: true })}
            disableTypography={true}
            style={{padding:'0px 2.5em'}}
          />
           <ListItemSecondaryAction className={classes.expandIcon} >
           {controlOpen1 ? <ExpandMore
              onClick={handleControl1} /> : <ArrowForward className={classes.arrow} onClick={handleControl1} />}
            </ListItemSecondaryAction>
         </ListItem>
         <div className={classes.item} >
         <Collapse in={controlOpen1} timeout="auto" unmountOnExit >
         <NavLink to={'/admin/GlChillerLanding'} className={" " + classes.item} activeClassName="active">
            <ListItem button className={classes.divItemLink + classNames({
                    [" " + classes.sidebarItemBlue]: activeRoute('/admin/GlChillerLanding')
                    })}>
            <ListItemText
                primary={'Chiller'}
                className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glAhu') })}
                disableTypography={true}
                style={{padding:'0px 2.5em'}}
              />
            </ListItem>
        </NavLink>
        {/* <NavLink to={'/admin/GlChillerLanding'} className={" " + classes.item} activeClassName="active">
            <ListItem button className={classes.divItemLink + classNames({
                    [" " + classes.sidebarItemBlue]: activeRoute('/admin/GlChillerLanding')
                    })}>
            <ListItemText
                primary={'Pumps'}
                className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glAhu') })}
                disableTypography={true}
                style={{padding:'0px 2.5em'}}
              />
            </ListItem>
        </NavLink>
        <NavLink to={'/admin/GlChillerLanding'} className={" " + classes.item} activeClassName="active">
            <ListItem button className={classes.divItemLink + classNames({
                    [" " + classes.sidebarItemBlue]: activeRoute('/admin/GlChillerLanding')
                    })}>
            <ListItemText
                primary={'Cooling Tower'}
                className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glAhu') })}
                disableTypography={true}
                style={{padding:'0px 2.5em'}}
              />
            </ListItem>
        </NavLink> */}
         </Collapse>
         </div>
         </div>
         </Collapse>
         </div>
         <div className={classes.item} >
         <Collapse in={controlOpen} timeout="auto" unmountOnExit >
         <div className={classNames({[" " + classes[color]]: controlOpen2})}  style={{borderRadius: '14px',margin:'5px,5px',marginTop:"-20px"}}>  
         <ListItem button className={classes.itemLink}  onClick={handleControl2} >
          <ListItemText
            primary={'Air Side'}
            className={classes.itemText + classNames({ [" " + classes.whiteFont]: true })}
            disableTypography={true}
            style={{padding:'0px 2.5em'}}
          />
           <ListItemSecondaryAction className={classes.expandIcon} >
           {controlOpen1 ? <ExpandMore
              onClick={handleControl2} /> : <ArrowForward className={classes.arrow} onClick={handleControl2} />}
            </ListItemSecondaryAction>
         </ListItem>
         <div className={classes.item} >
         <Collapse in={controlOpen2} timeout="auto" unmountOnExit >
         <NavLink to={'/admin/selector'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                        [" " + classes.sidebarItemBlue]: activeRoute('/admin/selector')
                        })}>
               <ListItemText
                   primary={'AHU'}
                   className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/selector') })}
                   disableTypography={true}
                   style={{padding:'0px 2.5em'}}
                 />
               </ListItem>
           </NavLink>
           <NavLink to={'/admin/glVav'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                    [" " + classes.sidebarItemBlue]: activeRoute('/admin/glVav')
                    })}>
               <ListItemText
                 primary={'VAV'}
                 className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glVav') })}
                 disableTypography={true}
                 style={{padding:'0px 2.5em'}}                         />
                </ListItem>
           </NavLink>
           {/* <NavLink to={'/admin/LandingPage'} className={" " + classes.item} activeClassName="active">
                       <ListItem button className={classes.divItemLink + classNames({
                            [" " + classes.sidebarItemBlue]: activeRoute('/admin/LandingPage')
                            })}>
                       <ListItemText
                         primary={'Landing Page'}
                         className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/LandingPage') })}
                         disableTypography={true}
                         style={{padding:'0px 2.5em'}}                         />
                        </ListItem>
           </NavLink> */}
           {/* <NavLink to={'/admin/glFAU'} className={" " + classes.item} activeClassName="active">
                       <ListItem button className={classes.divItemLink + classNames({
                            [" " + classes.sidebarItemBlue]: activeRoute('/admin/glFAU')
                            })}>
                       <ListItemText
                         primary={'Fresh Air Unit'}
                         className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glFAU') })}
                         disableTypography={true}
                         style={{padding:'0px 2.5em'}}                         />
                        </ListItem>
           </NavLink>
           <NavLink to={'/admin/glHeatExhaustFan'} className={" " + classes.item} activeClassName="active">
                       <ListItem button className={classes.divItemLink + classNames({
                            [" " + classes.sidebarItemBlue]: activeRoute('/admin/glHeatExhaustFan')
                            })}>
                       <ListItemText
                         primary={'Heat Exhaust Fan'}
                         className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glHeatExhaustFan') })}
                         disableTypography={true}
                         style={{padding:'0px 2.5em'}}                         />
                        </ListItem>
           </NavLink> */}
           <NavLink to={'/admin/GlVentilator'} className={" " + classes.item} activeClassName="active">
                       <ListItem button className={classes.divItemLink + classNames({
                            [" " + classes.sidebarItemBlue]: activeRoute('/admin/GlVentilator')
                            })}>
                       <ListItemText
                         primary={'Ventilator'}
                         className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/GlVentilator') })}
                         disableTypography={true}
                         style={{padding:'0px 2.5em'}}                         />
                        </ListItem>
           </NavLink>
           {/* <NavLink to={'/admin/GlBathroomExhaustFan'} className={" " + classes.item} activeClassName="active">
                       <ListItem button className={classes.divItemLink + classNames({
                            [" " + classes.sidebarItemBlue]: activeRoute('/admin/GlBathroomExhaustFan')
                            })}>
                       <ListItemText
                         primary={'Bathroom Exhaust Fan'}
                         className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/GlBathroomExhaustFan') })}
                         disableTypography={true}
                         style={{padding:'0px 2.5em'}}                         />
                        </ListItem>
           </NavLink> */}
           <NavLink to={'/admin/GlAllExhaustFans'} className={" " + classes.item} activeClassName="active">
                       <ListItem button className={classes.divItemLink + classNames({
                            [" " + classes.sidebarItemBlue]: activeRoute('/admin/GlAllExhaustFans')
                            })}>
                       <ListItemText
                         primary={'Exhaust Fans'}
                         className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/GlAllExhaustFans') })}
                         disableTypography={true}
                         style={{padding:'0px 2.5em'}} 
                         />
                        </ListItem>
           </NavLink>
           {/* <NavLink to={'/admin/GlSubstationExhaustFan'} className={" " + classes.item} activeClassName="active">
                       <ListItem button className={classes.divItemLink + classNames({
                            [" " + classes.sidebarItemBlue]: activeRoute('/admin/GlSubstationExhaustFan')
                            })}>
                       <ListItemText
                         primary={'Substation Exhaust Fan'}
                         className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/GlSubstationExhaustFan') })}
                         disableTypography={true}
                         style={{padding:'0px 2.5em'}}                         />
                        </ListItem>
           </NavLink> */}
         </Collapse>
         </div>
         </div>
         </Collapse>
         </div>
         <div className={classes.item} >
         <Collapse in={controlOpen} timeout="auto" unmountOnExit >
         <NavLink to={'/admin/floors'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                    [" " + classes.sidebarItemBlue]: activeRoute('/admin/floors')
                    })}>
               <ListItemText
                 primary={'Lighting'}
                 className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/floors') })}
                 disableTypography={true}h
                 style={{padding:'0px 1.5em'}}
                 />
                </ListItem>
           </NavLink>
           <NavLink to={'/admin/schedule'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                    [" " + classes.sidebarItemBlue]: activeRoute('/admin/schedule')
                    })}>
               {/* <BusinessIcon
                   className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/floors') })}
                 /> */}
               <ListItemText
                 primary={'LMS schedule'}
                 className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/schedule') })}
                 disableTypography={true}
                 style={{padding:'0px 1.5em'}}
                 />
                </ListItem>
           </NavLink>
         </Collapse>
         </div>
         
     </div>
     <div className={classNames({[" " + classes[color]]: open})}  style={{borderRadius: '14px',margin:'5px,5px'}}>
        <ListItem button className={classes.itemLink}  onClick={handleClick} >
          <BusinessIcon   className={classes.itemIcon}/>   
          <ListItemText
            primary={'Building Dashboard'}
            className={classes.itemText + classNames({ [" " + classes.whiteFont]: true })}
            disableTypography={true}
          />
           <ListItemSecondaryAction className={classes.expandIcon} >
           {open ? <ExpandMore
              onClick={handleClick} /> : <ArrowForward className={classes.arrow}  onClick={handleClick} />}
            </ListItemSecondaryAction>
         </ListItem>
         <div className={classes.item} >
         <Collapse in={open} timeout="auto" unmountOnExit >
           {/* <NavLink to={"/admin/building/"+localStorage.getItem('buildingID')+"/dashboard"} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                        [" " + classes.sidebarItemBlue]: activeRoute("/admin/building/"+localStorage.getItem('buildingID')+"/dashboard")
                        })}>
               <BusinessIcon
                      className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/building/:id/dashboard') })}
                   />
               <ListItemText
                   primary={'Plant Room'}
                   className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/building/:id/dashboard') })}
                   disableTypography={true}
                   style={{padding:'0px 1.5em'}}
                 />
               </ListItem>
           </NavLink> */}
          {/* <NavLink to={'/admin/glLandingUps'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                    [" " + classes.sidebarItemBlue]: activeRoute('/admin/glLandingUps')
                    })}> */}
               {/* <BusinessIcon
                   className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glLandingUps') })}
                 /> */}
              {/* <ListItemText
                 primary={'Air Side'}
                 className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glLandingUps') })}
                 disableTypography={true}
                 style={{padding:'0px 1.5em'}}
                 />
                </ListItem>
           </NavLink>*/}
          <NavLink to={'/admin/glLandingUps'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                    [" " + classes.sidebarItemBlue]: activeRoute('/admin/glLandingUps')
                    })}>
               <ListItemText
                 primary={'UPS'}
                 className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glLandingUps') })}
                 disableTypography={true}
                 style={{padding:'0px 1.5em'}}
                 />
                </ListItem>
           </NavLink>
           <NavLink to={'/admin/glEMLanding'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                    [" " + classes.sidebarItemBlue]: activeRoute('/admin/glEMLanding')
                    })}>
               {/* <BusinessIcon
                   className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glLandingUps') })}
                 /> */}
               <ListItemText
                 primary={'Energy Monitoring'}
                 className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glEMLanding') })}
                 disableTypography={true}
                 style={{padding:'0px 1.5em'}}
                 />
                </ListItem>
           </NavLink>
           <NavLink to={'/admin/glWaterMonitor'} className={" " + classes.item} activeClassName="active">
                       <ListItem button className={classes.divItemLink + classNames({
                            [" " + classes.sidebarItemBlue]: activeRoute('/admin/glWaterMonitor')
                            })}>
                       <ListItemText
                         primary={'Water Monitoring'}
                         className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glWaterMonitor') })}
                         disableTypography={true}
                         style={{padding:'0px 1.5em'}}
                         />
                        </ListItem>
                   </NavLink>
           {/* <NavLink to={'/admin/glEnergyMeter'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                    [" " + classes.sidebarItemBlue]: activeRoute('/admin/glEnergyMeter')
                    })}>
               
               <ListItemText
                 primary={'Energy meter'}
                 className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glEnergyMeter') })}
                 disableTypography={true}
                 />
                </ListItem>
           </NavLink> */}
         </Collapse>
         </div>
     </div>
     <div className={classNames({[" " + classes[color]]: spaceOpen})}  style={{borderRadius: '14px',margin:'5px,5px'}}>
        <ListItem button className={classes.itemLink}  onClick={handleSpace} >
          <MeetingRoomIcon   className={classes.itemIcon}/>   
          <ListItemText
            primary={'Space Management'}
            className={classes.itemText + classNames({ [" " + classes.whiteFont]: true })}
            disableTypography={true}
          />
           <ListItemSecondaryAction className={classes.expandIcon} >
           {spaceOpen ? <ExpandMore
              onClick={handleSpace} /> : <ArrowForward className={classes.arrow} onClick={handleSpace} />}
            </ListItemSecondaryAction>
         </ListItem>
         <div className={classes.item} >
         <Collapse in={spaceOpen} timeout="auto" unmountOnExit >
           <NavLink to={'/admin/room-booking/0'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                        [" " + classes.sidebarItemBlue]: activeRoute('/admin/room-booking/0')
                        })}>
               {/* <BusinessIcon
                      className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/room-booking/0') })}
                   /> */}
               <ListItemText
                   primary={'Conference Room'}
                   className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/room-booking/0') })}
                   disableTypography={true}
                   style={{padding:'0px 1.5em'}}
                 />
               </ListItem>
           </NavLink>
         </Collapse>
         </div>
     </div>
     {/* <div className={classNames({[" " + classes[color]]: controlOpen})}  style={{borderRadius: '14px',margin:'5px,5px'}}>
        <ListItem button className={classes.itemLink}  onClick={handleSchedule} >
          <CalendarTodayIcon   className={classes.itemIcon}/>   
          <ListItemText
            primary={'Scheduler'}
            className={classes.itemText + classNames({ [" " + classes.whiteFont]: true })}
            disableTypography={true}
          />
           <ListItemSecondaryAction className={classes.expandIcon} >
           {schOpen ? <ExpandMore
              onClick={handleSchedule} /> : <ArrowForward className={classes.arrow} onClick={handleSchedule} />}
            </ListItemSecondaryAction>
         </ListItem>
         <div className={classes.item} >
         <Collapse in={schOpen} timeout="auto" unmountOnExit >
            <NavLink to={'/admin/Glbmscheduler'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                        [" " + classes.sidebarItemBlue]: activeRoute('/admin/Glbmscheduler')
                        })}>
             
               <ListItemText
                   primary={'Schedule'}
                   className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glScheduleCreation') })}
                   disableTypography={true}
                   style={{padding:'0px 1.5em'}}
                 />
               </ListItem>
           </NavLink> 
          
           <NavLink to={'/admin/glScheduleCreation'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                    [" " + classes.sidebarItemBlue]: activeRoute('/admin/glScheduleCreation')
                    })}>
             
               <ListItemText
                 primary={'Weekly Schedule'}
                 className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glScheduleCreation') })}
                 disableTypography={true}
                 style={{padding:'0px 1.5em'}}
                 />
                </ListItem>
           </NavLink>
         </Collapse>
         </div>
     </div> */}
    
     { 
     role_id !=2 ? 
      <div className={classNames({[" " + classes[color]]: reportOpen})}  style={{borderRadius: '14px',margin:'5px,5px',pointerEvents: "none", opacity: "0.4"}}>
          <NavLink
            to={'/admin/reportsviewer'}
            className={" " + classes.item}
            activeClassName="active"
          >
              <ListItem button className={classes.itemLink + classNames({
                [" " + classes[color]]: activeRoute('/admin/reportsviewer')
              })}>

                <AssessmentIcon
                  className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/reportsviewer') })}
                />

                <ListItemText
                  primary={'Report'}
                  className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/reportsviewer') })}
                  disableTypography={true}
                />
              </ListItem>
           </NavLink>
      </div>
   :
    <NavLink
      to={'/admin/reportsviewer'}
      className={" " + classes.item}
      activeClassName="active"
    >
      <ListItem button className={classes.itemLink + classNames({
        [" " + classes[color]]: activeRoute('/admin/reportsviewer')
      })}>

        <AssessmentIcon
          className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/reportsviewer') })}
        />

        <ListItemText
          primary={'Report'}
          className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/reportsviewer') })}
          disableTypography={true}
        />
      </ListItem>
    </NavLink>
    //  <div className={classNames({[" " + classes[color]]: reportOpen})}  style={{borderRadius: '14px',margin:'5px,5px'}}>
    //     <ListItem button className={classes.itemLink} onClick={handleReport} >
    //       <AssessmentIcon   className={classes.itemIcon}/>   
    //       <ListItemText
    //         primary={'Reports'}
    //         className={classes.itemText + classNames({ [" " + classes.whiteFont]: true })}
    //         disableTypography={true}
    //       />
    //        <ListItemSecondaryAction className={classes.expandIcon} >
    //        {reportOpen ? <ExpandMore
    //           onClick={handleReport} /> : <ArrowForward  className={classes.arrow} onClick={handleReport} />}
    //         </ListItemSecondaryAction>
    //      </ListItem>
    //      <div className={classes.item} >
    //      <Collapse in={reportOpen} timeout="auto" unmountOnExit >
    //        <NavLink to={'/admin/reportsviewer'} className={" " + classes.item} activeClassName="active">
    //            <ListItem button className={classes.divItemLink + classNames({
    //                     [" " + classes.sidebarItemBlue]: activeRoute('/admin/reportsviewer')
    //                     })}>
    //            {/* <BusinessIcon
    //                   className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/reportsviewer') })}
    //                /> */}
    //            <ListItemText
    //                primary={'Report'}
    //                className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/reportsviewer') })}
    //                disableTypography={true}
    //              />
    //            </ListItem>
    //        </NavLink>
    //      </Collapse>
    //      </div>
    //  </div>
      }
     
      <NavLink
        to={'/admin/networkDiagram'}
        className={" " + classes.item}
        activeClassName="active"
      >
        <ListItem button className={classes.itemLink + classNames({
          [" " + classes[color]]: activeRoute('/admin/networkDiagram')
        })}>

          <SettingsEthernetIcon
            className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/networkDiagram') })}
          />

          <ListItemText
            primary={'Network Diagram'}
            className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/networkDiagram') })}
            disableTypography={true}
          />
        </ListItem>
      </NavLink>
      <NavLink
        to={'/admin/alerts'}
        className={" " + classes.item}
        activeClassName="active"
      >
        <ListItem button className={classes.itemLink + classNames({
          [" " + classes[color]]: activeRoute('/admin/alerts')
        })}>

          <NotificationsIcon
            className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/alerts') })}
          />

          <ListItemText
            primary={'Alarms'}
            className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/alerts') })}
            disableTypography={true}
          /> 
        </ListItem>
      </NavLink>
      <NavLink
        to={'/admin/glossary'}
        className={" " + classes.item}
        activeClassName="active"
      >
        <ListItem button className={classes.itemLink + classNames({
          [" " + classes[color]]: activeRoute('/admin/glossary')
        })}>

          <ImportContactsIcon
            className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glossary') })}
          />

          <ListItemText
            primary={'Glossary'}
            className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/glossary') })}
            disableTypography={true}
          />
        </ListItem>
      </NavLink>
      <div className={classNames({[" " + classes[color]]: controlOpen})}  style={{borderRadius: '14px',margin:'5px,5px'}}>
        <ListItem button className={classes.itemLink}  onClick={handleAnalytics} >
          <CalendarTodayIcon   className={classes.itemIcon}/>   
          <ListItemText
            primary={'Analytics'}
            className={classes.itemText + classNames({ [" " + classes.whiteFont]: true })}
            disableTypography={true}
          />
           <ListItemSecondaryAction className={classes.expandIcon} >
           {analytics ? <ExpandMore
              onClick={handleAnalytics} /> : <ArrowForward className={classes.arrow} onClick={handleAnalytics} />}
            </ListItemSecondaryAction>
         </ListItem>
         {/* <div className={classes.item} >
         <Collapse in={analytics} timeout="auto" unmountOnExit >
            <NavLink to={'/admin/GlAnalytics'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                        [" " + classes.sidebarItemBlue]: activeRoute('/admin/GlAnalytics')
                        })}>
             
               <ListItemText
                   primary={'Analytics'}
                   className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/GlAnalytics') })}
                   disableTypography={true}
                   style={{padding:'0px 1.5em'}}
                 />
               </ListItem>
           </NavLink> 
         </Collapse>
         </div> */}
         <div className={classes.item} >
         <Collapse in={analytics} timeout="auto" unmountOnExit >
            <NavLink to={'/admin/IAQAnalytics1'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                        [" " + classes.sidebarItemBlue]: activeRoute('/admin/IAQAnalytics1')
                        })}>                   
               <ListItemText
                   primary={'IAQ Analytics 1'}
                   className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/IAQAnalytics1') })}
                   disableTypography={true}
                   style={{padding:'0px 1.5em'}}
                 />
               </ListItem>
           </NavLink>
         </Collapse>
         </div>
         <div className={classes.item} >
         <Collapse in={analytics} timeout="auto" unmountOnExit >
            <NavLink to={'/admin/IAQAnalytics2'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                        [" " + classes.sidebarItemBlue]: activeRoute('/admin/IAQAnalytics2')
                        })}>         
               <ListItemText
                   primary={'IAQ Analytics 2'}
                   className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/IAQAnalytics2') })}
                   disableTypography={true}
                   style={{padding:'0px 1.5em'}}
                 />
               </ListItem>
           </NavLink>
         </Collapse>
         </div>
         <div className={classes.item} >
         <Collapse in={analytics} timeout="auto" unmountOnExit >
            <NavLink to={'/admin/IAQAnalytics3'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                        [" " + classes.sidebarItemBlue]: activeRoute('/admin/IAQAnalytics3')
                        })}>
               <ListItemText
                   primary={'Device Analytics'}
                   className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/IAQAnalytics3') })}
                   disableTypography={true}
                   style={{padding:'0px 1.5em'}}
                 />
               </ListItem>
           </NavLink>
         </Collapse>
         </div>
         <div className={classes.item} >
         <Collapse in={analytics} timeout="auto" unmountOnExit >
            <NavLink to={'/admin/IAQAnalytics4'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                        [" " + classes.sidebarItemBlue]: activeRoute('/admin/IAQAnalytics4')
                        })}>
               <ListItemText
                   primary={'Occupant Dashboard'}
                   className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/IAQAnalytics4') })}
                   disableTypography={true}
                   style={{padding:'0px 1.5em'}}
                 />
               </ListItem>
           </NavLink>
         </Collapse>
         </div>
         <div className={classes.item} >
         <Collapse in={analytics} timeout="auto" unmountOnExit >
            <NavLink to={'/admin/IAQAnalytics5'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                        [" " + classes.sidebarItemBlue]: activeRoute('/admin/IAQAnalytics5')
                        })}>
             
               <ListItemText
                   primary={'Energy Dashboard'}
                   className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/IAQAnalytics5') })}
                   disableTypography={true}
                   style={{padding:'0px 1.5em'}}
                 />
               </ListItem>
           </NavLink>
         </Collapse>
         </div>
         <div className={classes.item} >
         <Collapse in={analytics} timeout="auto" unmountOnExit >
            <NavLink to={'/admin/IAQAnalytics6'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                        [" " + classes.sidebarItemBlue]: activeRoute('/admin/IAQAnalytics6')
                        })}>
             
               <ListItemText
                   primary={'Chiller FDD'}
                   className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/IAQAnalytics6') })}
                   disableTypography={true}
                   style={{padding:'0px 1.5em'}}
                 />
               </ListItem>
           </NavLink>
         </Collapse>
         </div>
         {/* <div className={classes.item} >
         <Collapse in={analytics} timeout="auto" unmountOnExit >
            <NavLink to={'/admin/GlAnalyticsForAllDevices'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                        [" " + classes.sidebarItemBlue]: activeRoute('/admin/GlAnalyticsForAllDevices')
                        })}>
             
               <ListItemText
                   primary={'All devices faults'}
                   className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/GlAnalyticsForAllDevices') })}
                   disableTypography={true}
                   style={{padding:'0px 1.5em'}}
                 />
               </ListItem>
           </NavLink> 
         </Collapse>
         </div> */}



         {/* <div className={classes.item} >
         <Collapse in={analytics} timeout="auto" unmountOnExit >
            <NavLink to={'/admin/Runhours'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                        [" " + classes.sidebarItemBlue]: activeRoute('/admin/Runhours')
                        })}>
             
               <ListItemText
                   primary={'Runhours'}
                   className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/Runhours') })}
                   disableTypography={true}
                   style={{padding:'0px 1.5em'}}
                 />
               </ListItem>
           </NavLink> 
         </Collapse>
         </div>

         <div className={classes.item} >
         <Collapse in={analytics} timeout="auto" unmountOnExit >
            <NavLink to={'/admin/GlAnalyticsopenFaults'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                        [" " + classes.sidebarItemBlue]: activeRoute('/admin/GlAnalyticsopenFaults')
                        })}>
             
               <ListItemText
                   primary={'OpenFaults'}
                   className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/GlAnalyticsopenFaults') })}
                   disableTypography={true}
                   style={{padding:'0px 1.5em'}}
                 />
               </ListItem>
           </NavLink> 
         </Collapse>
         </div> */}

     </div>
      {/* <ListItem button className={classes.itemLink}  onClick={handleinstrument} >
          <CalendarTodayIcon   className={classes.itemIcon}/>   
          <ListItemText
            primary={'Instrumentation'}
            className={classes.itemText + classNames({ [" " + classes.whiteFont]: true })}
            disableTypography={true}
          />
           <ListItemSecondaryAction className={classes.expandIcon} >
           {instruments ? <ExpandMore
              onClick={handleinstrument} /> : <ArrowForward className={classes.arrow} onClick={handleinstrument} />}
            </ListItemSecondaryAction>
         </ListItem> */}
         {/* <div className={classes.item} >
         <Collapse in={instruments} timeout="auto" unmountOnExit >
            <NavLink to={'/admin/instrumentation'} className={" " + classes.item} activeClassName="active">
               <ListItem button className={classes.divItemLink + classNames({
                        [" " + classes.sidebarItemBlue]: activeRoute('/admin/instrumentation')
                        })}>                   
               <ListItemText
                   primary={'Instrumentation'}
                   className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/instrumentation') })}
                   disableTypography={true}
                   style={{padding:'0px 1.5em'}}
                 />
               </ListItem>
           </NavLink>
         </Collapse>
         </div> */}
     
       {/* <NavLink
        to={'/admin/Glschedule'}
        className={" " + classes.item}
        activeClassName="active"
      >
        <ListItem button className={classes.itemLink + classNames({
          [" " + classes[color]]: activeRoute('/admin/Glschedule')
        })}>

          <CalendarTodayIcon
            className={classes.itemIcon + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/Glschedule') })}
          />

          <ListItemText
            primary={'Scheduler'}
            className={classes.itemText + classNames({ [" " + classes.whiteFont]: activeRoute('/admin/Glschedule') })}
            disableTypography={true}
          /> 
        </ListItem>
      </NavLink>  */}
     </>       }
    </List>
  </div>
  )
  var brand = (
    <div className={classes.logo}>
      <a
        href="https://graylinx.ai/"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
        target="_blank"
      >
        <div className={classes.logoImage}>
          <img src={localStorage.getItem('username')=='Guest1'? simplyIO : logo} alt="logo" className={classes.img} />
        </div>
      </a>
      <IconButton onClick={props.handleDrawerClose} style={{zIndex: "4", float: "right", marginTop: "-15%"}}>
          <ChevronLeftIcon    className={classes.itemIcon}/>
      </IconButton>
    </div>
  );
  return (
   <div> 
        <Hidden  mdUp implementation="css">
        <Drawer
        className={classes1.drawer}
        variant="persistent"
        anchor="left"
        open={props.open}
        classes={{
          paper: classes1.drawerPaper,
        }}
      >
        {brand}
        <div className={classes.sidebarWrapper}>
        <AdminNavbarLinks/>
           {links}
        </div>
        {image !== undefined ? (
          <div
          className={classes.background}
          style={{ backgroundImage: "url(" + image + ")" }}
          />
        ) : null}
      </Drawer>
        </Hidden>

      <Hidden smDown implementation="css">
     <Drawer
        className={classes1.drawer}
        variant="persistent"
        anchor="left"
        open={props.open}
        classes={{
          paper: classes1.drawerPaper,
        }}
      >
        <div
          className={classes.background}
         // style={{ backgroundColor:'#0123B4'}}
          />
        {/* {image !== undefined ? (
          <div
          className={classes.background}
          style={{ backgroundImage: "url(" + image + ")" }}
          />
        ) : null} */}
        {brand}
        <Divider />
            {links}
        </Drawer>
      </Hidden>
   </div>
       
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red","info"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};

