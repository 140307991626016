import React, {  useEffect, useState } from "react";
import ReactApexCharts from 'react-apexcharts'
import { format, compareAsc, toDate,addMinutes } from 'date-fns'
import { propTypes } from "react-progressbar-semicircle";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  customDialog: {
    // Set the desired width for the dialog
    width: '700px', // Adjust this value as needed
  },
  graphSize: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      height: '2vh' // Adjust the height for small screens (sm)
    },
    [theme.breakpoints.up('md')]: {
      height: '2vh' // Adjust the height for medium screens (md)
    },
    [theme.breakpoints.up('lg')]: {
      height: '2vh' // Adjust the height for large screens (lg)
    },
    [theme.breakpoints.up('xl')]: {
      height: '2vh' // Adjust the height for extra-large screens (xl)
    },
    [theme.breakpoints.up('xxl')]: {
      height: '2vh' // Adjust the height for extra extra large screens (xxl)
    },
  },
}))

export default function ApexChart(props)  {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
   const data=(props.data !== undefined) ? props.data: []
   const data2=(props.data2 !== undefined) ? props.data2: []
   const data3=(props.data3 !== undefined) ? props.data3: []
// data.length && data2.length && data3.length == 0 ?
// <>console.log("inside if")</>:<>console.log("inside else")</>
// if(data.length && data2.length && data3.length == 0){
//   console.log("inside if")
// }else{
//   console.log("inside else")
// }
    let elements = new Map();
    let elements1 = new Map();
    let elements2 = new Map();

    data.map((_elem, i) =>{
      var myDate =toDate(addMinutes(new Date(_elem.measured_time),330))
        elements.set(i, {x: myDate, y: parseFloat(_elem.param_value).toFixed(2)})
      
    })
    data2.map((_elem, i) =>{
        var myDate =toDate(addMinutes(new Date(_elem.measured_time),330))
        elements1.set(i, {x: myDate, y: parseFloat(_elem.param_value).toFixed(2)})   
    })
    data3.map((_elem, i) =>{
        var myDate =toDate(addMinutes(new Date(_elem.measured_time),330))
        elements2.set(i, {x: myDate, y: parseFloat(_elem.param_value).toFixed(2)})   
    })
    var elemArr = [], elemArr1 = [], elemArr2 = [];
    for(let i of elements.values()) {
        elemArr.push(i)
    }
    for(let i of elements1.values()) {
        elemArr1.push(i)
    }
    for(let i of elements2.values()) {
        elemArr2.push(i)
    }
    const dataset = {
      series1: [{
            name: props.name=='Chilled Water Temperature'? "CWH_ST":"CndW_HST",
            data: elemArr
          },
          {
            name: props.name=='Chilled Water Temperature'? "CWH_RT":"CndW_HRT",
            data: elemArr1
          }
        ],
        series: props.name=='Current[A]'?[{
              name: "Phase-1",
              data: elemArr
            }
        ]
        :props.name=='Chilled Water Temperature[℃]'?[{
          name: "CWH_ST",
          data: elemArr
        },{
          name: "CWH_RT",
          data: elemArr1
        }
    ]
        :props.name=='Condenser Water Temperature[℃]'?[
          {
          name: "CndW_HST",
          data: elemArr
        },
          {
          name: "CndW_HRT",
          data: elemArr1
        }
    ]
        :[{
          name: "Phase-1",
          data: elemArr
        },
        {
          name: "Phase-2",
          data: elemArr1
        },
        {
          name: "Phase-3",
          data: elemArr2
        }],
        options: {
          // responsive: [
          //   {
          //     breakpoint: 576, // xs
          //     options: {
          //       chart: {
          //         height: 300, // Set the height to 300 pixels on extra small screens
          //       },
          //     },
          //   },
          //   {
          //     breakpoint: 768, // sm
          //     options: {
          //       chart: {
          //         height: 350, // Set the height to 350 pixels on small screens
          //       },
          //     },
          //   },
          //   {
          //     breakpoint: 992, // md
          //     options: {
          //       chart: {
          //         height: 400, // Set the height to 400 pixels on medium screens
          //       },
          //     },
          //   },
          //   {
          //     breakpoint: 1200, // xl
          //     options: {
          //       chart: {
          //         height: 450, // Set the height to 450 pixels on extra-large screens
          //       },
          //     },
          //   },
           
          // ],
          chart: {
            type: 'area',
            stacked: false,
            // height: 350,
            events: {
              click: function (event, chartContext, config) {
                onclickchart();
              },
            },
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true,
            
             
            },
            toolbar: {
              autoSelected: 'zoom'
            }
          },
          dataLabels: {
            enabled: false
          },
          markers: {
            size: 0,
          },           
          title: {
            align: 'left'
          },
          stroke: {
            width: [2,2,2]
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 0.5,
              inverseColors: false,
              opacityFrom: 0.3,
              opacityTo: 0,
              stops: [0, 90, 100]
            },
          },
          yaxis: {
             tickAmount:2,
             labels: {
              formatter: function (val) {
                return parseInt(val); // Convert the value to an integer (whole number)
              }
            }
          },
          xaxis: {
            type: 'datetime',
            // formatter: (val) => { return val },
          // labels: {
          //   formatter: function (val) {
          //     return parseInt(val); // Convert the value to an integer (whole number)
          //   },
          // },
            // labels: {
            //   formatter: function (val) {
            //     return format(new Date(val), "HH:mm"); // Format date with hours and minutes only
            //   },
            // }
          },
          tooltip: {
            shared: false,
            // y: {
            //   formatter: function (val) {
            //     return (val / 1000000).toFixed(0)
            //   }
            // }
          }
        },
      
      
      };
    
      const onclickchart = () => {
        // console.log("clicked on chart",param)
        handleClickOpen(true)
      }

    return (
    <div id="chart">
      {
data.length || data2.length || data3.length !== 0 ?
        <>
                <ReactApexCharts options={dataset.options} series={props.name=='Condenser Water Temperature'||props.name=='Chilled Water Temperature'?dataset.series1:dataset.series} type="area" height={ window.innerHeight == 641 ?
                  ( props.via == 'EM'? 150: props.via == 'Chiller'? 130: props.via == 'UPS'? 130: 310):
                  window.innerHeight == 768 ?
                  ( props.via == 'EM'? 180: props.via == 'UPS'? 145: props.via == 'Chiller'? 150: 380):
                  window.innerHeight == 1080 ?
                  ( props.via == 'EM'? 240: props.via == 'UPS'? 220: 380):
                  window.innerHeight == 1920 ?
                  ( props.via == 'EM'? 180: 450):
                  window.innerHeight == 1929 ?
                  ( props.via == 'EM'? 180: 450):
                  window.innerHeight == 864 ?
                  ( props.via == 'EM'? 220: props.via == 'UPS'? 170: 450):
                  window.innerHeight == 540 ?//office tv
                  ( props.via == 'EM'? 140: props.via == 'UPS'? 150: props.via == 'Chiller'? 125: 450):
                  window.innerHeight == 599 ?//pradeep browser view
                  ( props.via == 'EM'? 150: props.via == 'UPS'? 115: props.via == 'Chiller'? 125: 280):
                  window.innerHeight == 1280 ?//tab
                  ( props.via == 'EM'? 300: props.via == 'UPS'? 270: props.via == 'Chiller'? 240: 650):
                  window.innerHeight == 600 ?//hmi
                  ( props.via == 'EM'? 140: props.via == 'UPS'? 120: props.via == 'Chiller'? 140: 340):
                  window.innerHeight == 844 ?//iphone12
                  ( props.via == 'EM'? 180: props.via == 'UPS'? 170: props.via == 'Chiller'? 160: 425):
                  window.innerHeight == 633 ?//moses laptop
                  ( props.via == 'EM'? 140: props.via == 'UPS'? 120: props.via == 'Chiller'? 140: 320):
                  window.innerHeight == 900 ?//office desktop
                  ( props.via == 'EM'? 210: props.via == 'UPS'? 165: props.via == 'Chiller'? 170: 440):
                  window.innerHeight == 793 ?//one plus
                  ( props.via == 'EM'? 185: props.via == 'UPS'? 165: props.via == 'Chiller'? 140: 390): 
                  window.innerHeight == 939 ?//z fold
                  ( props.via == 'EM'? 225: props.via == 'UPS'? 165: props.via == 'Chiller'? 140: 430):
                  window.innerHeight == 915 ?//s20 ultra
                  ( props.via == 'EM'? 210: props.via == 'UPS'? 165: props.via == 'Chiller'? 140: 520):
                  'auto'} />
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} classes={{ paper: classes.customDialog }}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                  {props.name}
                </DialogTitle>
                <DialogContent dividers>
                <ReactApexCharts options={dataset.options} series={props.name=='Condenser Water Temperature'||props.name=='Chilled Water Temperature'?dataset.series1:dataset.series}  type="area" />
                </DialogContent>
              </Dialog>

        </>
        :<> 
                  <h4 style={{marginTop:"44px",marginLeft:"5px"}}>No data available</h4>
 
        </>
    }
    </div>
    );
    }
 




  
