import React, { useRef, useEffect, useState } from 'react'
import api from '../../api'
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card, ButtonGroup, Divider } from '@material-ui/core';
import { Map, ImageOverlay, Marker, Tooltip, ZoomControl, Rectangle, Circle, Polygon } from 'react-leaflet';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import DevicetrendChart from 'views/DevicetrendChart';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import 'leaflet/dist/leaflet.css';
import "../../assets/css/leaflet.css";
import { useSelector } from 'react-redux';
import FloorTempIcon from 'assets/img/FloorTempIcon';
import FloorHumIcon from 'assets/img/FloorHumIcon';
import AirQualityIcon from 'assets/img/AirQualityIcon';
import { message, Spin } from 'antd';
import ControlWhite from 'assets/img/ControlWhite';
import Control from 'assets/img/Control';
import { SemanticToastContainer } from 'react-semantic-toasts';
import '../../assets/css/leaflet.sass';
import ReactSimpleRange from "react-simple-range";
import {
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-react.js";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import FloorTempIconWhite from 'assets/img/FloorTempIconWhite';
import Alert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";
import floor2 from '../../assets/Images/Floor-1.png';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip1 from '@material-ui/core/Tooltip';
import DevicemapTHL from '../Heatmap/DevicemapTHL';
import LandingPage from './../Heatmap/upsEmsLanding';
import { greenColor, grayColor, redColor, yellowColor} from "assets/jss/material-dashboard-react";
const Leaflet = require('leaflet');

const StyledTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "#FEE8DA",
    // backgroundColor: "red",
    fontSize: "12px"
  }
})(Tooltip1);

const useStyles = makeStyles(theme => ({
  customDialog: {
    cursor: "pointer",
    // Set the desired width for the dialog
    width: '360px', // Adjust this value as needed
  },
  alerts: {
    width: '8%',
    height: '7%',
    borderRadius: '3%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  transparentTooltip: {
    border: "1px solid black",
    boxShadow: "none",
    // fontSize: "14px",
    // fontWeight: "bold",
    margin: "0"
  },
  transparentTooltip1: {
    border: "0.5px solid black",
    borderRadius: "10px",
    boxShadow: "none",
    // fontSize: "14px",
    // fontWeight: "bold",
    margin: "0"
  },
  vertical: {
    borderLeft: '1px solid lightgrey',
    height: "4em",
    marginLeft: "-7%",
    fontColor: 'blue',
    marginTop: '-10vh'
  },
  vavbutton: {
    width: '29%',
    height: '4vh',
    backgroundColor: "#D3D3D3",
    border: "none",
    cursor: "pointer",
    fontSize: "2vh",
    borderRadius: "0.4vw",
    margin: '2%'
  },
  paper: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 8px 40px #0123B433;',
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: '12px',
    opacity: '1'
  },
  paper1: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 10px #0123B421',
    opacity: '1',
    borderRadius: '12px',
    height: '15vh',
    // display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    whiteSpace: "nowrap",
    color: "#000000",
    marginTop: '1vh',
    font: 'normal normal medium 17px/60px Bw Seido Round',
    opacity: '1',
    fontWeight: 'bold'
  },
  semicircleBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    marginTop: '-0.8vh'
  },
  cardbody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    fontSize: "4vh",
    fontWeight: 'bold',
    opacity: '1',
    color: '#0123B4'
  },
  select: {
    "&:after": {
      borderBottomColor: "blue",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      backgroundColor: "#0123b4", borderRadius: "8px"
    },
    "& .MuiSelect-root ": {
      marginTop: "-2vh"
    }
  },
  root: {
    flexGrow: 1,
    marginTop: '-0.5vh'
  },
  formControl: {
    autosize: true,
    clearable: false
  },
  select: {
    "&:after": {
      borderBottomColor: "blue",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      backgroundColor: "#0123b4", borderRadius: "8px"
    },
    "& .MuiSelect-root ": {
      marginTop: "-2vh"
    }
  },
  paper: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    // boxShadow: '0px 4px 20px #0123B41A',
    // backgroundColor: 'white',
    // borderRadius: '14px',
    borderRadius: "6px",
    boxShadow: "1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor: "#fcfafa",
    height: '10vh',
    marginTop: "1vh",
    opacity: '1'
  },
  imagecard: {
    height: "61vh",
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // boxShadow: '0px 4px 20px #0123B41A;',
    // backgroundColor: 'white',
    boxShadow: "1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor: "#Fefefa",
    // opacity:"1",
    borderRadius: '6px',
  },
  graphpaper: {
    height: "24.9vh",
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // boxShadow: '0px 4px 20px #0123B41A;',
    // backgroundColor: 'white',
    boxShadow: "1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor: "#Fefefa",
    // opacity:"1",
    borderRadius: '6px',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor: '#Fefefa',
    height: '19.5vh',
  },
  button: {
    whiteSpace: 'nowrap',
    border: 'none',
    textTransform: 'none',
    '&.selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  smallbuttons: {
    width: '29%',
    height: '4vh',
    backgroundColor: "rgb(239 229 229 / 87%)",
    border: "none",
    cursor: "pointer",
    fontSize: "2vh",
    borderRadius: "0.4vw",
    margin: '2%'
  },
}));

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: "43vh",
    width: "69vh"
  };
}

function getJSONElement(myJson, elementPath = []) {
  let eValue = myJson;
  for (let i = 0; i < elementPath.length; i++) {
    if (eValue !== undefined && eValue !== null) {
      eValue = eValue[elementPath[i]];

      // Check if the value is the string "NULL" and return null
      if (typeof eValue === 'string' && eValue.toUpperCase() === 'NULL') {
        return null;
      }
    } else {
      eValue = undefined;
      console.log(`Unable to process JSON: ${elementPath}`);
      break;
    }
  }
  return eValue !== undefined ? eValue : null;
}

function Devicemap(props) {
  const circleRefs = useRef([]);
  const classes = useStyles();
  const mapRef = React.createRef()
  const [AhuOpen, setAhuOpen] = React.useState(false)
  const tooltipRefs = useRef([]);
  const [heatMapData, setHeatMapdata] = React.useState({
    rectData: [],
    addressPoints: [],
    mapSubType: props.param ? props.param.toLocaleLowerCase() : "ahu"
  });

  const colorScale = (value, bounds) => {
    // console.log("value",value)
    // console.log("bounds",bounds)
    if (value > 28) {
      return "red";
    } else if (value > 23 && value < 27) {
      return "green";
    } else {
      return "blue";
    }
  };

  const getColor = (temperature) => {
    if (temperature >= 0 && temperature <= 20) {
      return '#F6BE00'; // Yellow
    } else if (temperature >= 21 && temperature <= 25) {
      return '#21ba45'; // Green
    } else if (temperature > 25 && temperature <= 35) {
      return '#fc6434'; // Red
    } else {
      return 'defaultColor'; // Set your default color here
    }
  };

  const heatmapConf = {
    THL: {
      format: "℃",
      maxIntensity: 27,
    },
    humidity: {
      format: "RH",
      maxIntensity: 70,
    },
    luminosity: {
      format: "LX",
      maxIntensity: 270,
    },
    occupancy: {
      format: "%",
      maxIntensity: 74,
    },
    DALI_Slave: {
      format: "LX",
      maxIntensity: 0,
    },
    WAC: {
      format: "LX",
      maxIntensity: 0,
    }
  }

  // const getFillColor = (RAT, Temperature) => {
  //   console.log(`RAT ${RAT} Temperature ${Temperature}`)
  //   if (RAT > 28 || Temperature > 28) {
  //     return 'red';
  //   } else if ((RAT >= 23 && RAT <= 27) || (Temperature >= 23 && Temperature <= 27)) {
  //     return 'green';
  //   } else {
  //     return 'blue';
  //   }
  // };
  const getFillColor = (RAT) => {
    // console.log(`RAT ${RAT}`)
    if (RAT >= 33) {
      return 'red';
    } else if (RAT >= 23 && RAT <= 32) {
      return 'green';
    } else {
      return 'blue';
    }
  };


  const alerts = useSelector(state => state.alarm.alarmData)
  const [criticalAlertsAhu, setcriticalAlertsAhu] = React.useState(0);
  const [soluAlertsAhu, setsoluAlertsAhu] = React.useState(0);
  const [eachAhuData, setEachAhuDAta] = React.useState([])
  const [iconDevice, setIconDevice] = React.useState({});
  const [tempOpen, setTempOpen] = React.useState(false);
  const [humOpen, setHumOpen] = React.useState(false);
  const [luxOpen, setLuxOpen] = React.useState(false);
  const [newvalue, setNewValue] = React.useState("ahu");
  const buildingID = useSelector(state => state.isLogged.data.building.id);
  // const iconDevice1 = new Leaflet.Icon({
  //   iconUrl: require('../../assets/img/thl-1.png'),
  //   iconRetinaUrl: require('../../assets/img/thl-1.png'),
  //   iconSize: new Leaflet.Point(25, 25),
  //   className: 'leaflet-div-icon-1'
  // });
  // const iconDevice3 = new Leaflet.Icon({
  //   iconUrl: require('../../assets/img/AHU.png'),
  //   iconRetinaUrl: require('../../assets/img/AHU.png'),
  //   iconSize: new Leaflet.Point(30,30),
  //   className: 'leaflet-div-icon-2'
  // });

  const [deviceTrendData, setDeviceTrend] = React.useState([])
  const [openmodal, setOpenmodal] = React.useState(false)
  const [modalStyle] = React.useState(getModalStyle);
  const [setpt, setSetpt] = React.useState("");
  const [text, setText] = React.useState(false);
  const [openerr, setOpenerr] = React.useState(false);
  const [errmsg, setErrmsg] = React.useState('');
  const [show, setShow] = React.useState(true);
  const [vavData, setVavData] = useState({});
  const [loading, setLoading] = useState(false);
  const [hoveredButton, setHoveredButton] = useState(null);


  const coords = [];

  let MAX_AIR_FLOW = 1350;
  let MIN_AIR_FLOW = 0;
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const [vavdevice, setVavdevice] = useState([]);
  const rectangleBounds = [[234.49, -0.08], [234.5, -0.06]];
  const options1 = [
    {
      label: "Auto",
      value: "auto",
      selectedBackgroundColor: "green",
      fontSize: "12"
    },
    {
      label: "Manual",
      value: "manual",
      selectedBackgroundColor: "green",
      fontSize: "12"

    },
  ];
  const initialSelectedIndex1 = options1.findIndex(
    ({ value }) => value === "manual"
  );

  const handleButtonHover = (data) => {
    setHoveredButton(data);
  };

  const handleButtonLeave = () => {
    setHoveredButton(null);
  };

  const iconDevice1 = new Leaflet.Icon({
    iconUrl: require('../../assets/img/sensor-icon.png'),
    iconRetinaUrl: require('../../assets/img/sensor-icon.png'),
    iconSize: new Leaflet.Point(10, 10),
    className: 'leaflet-div-icon-1'
  });

  // const mapRef = useRef(null);
  const rectangleRef = useRef(null);
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  useEffect(() => {
    localStorage.removeItem("type")
    let type = heatMapData.mapSubType === "aqi" ? "all" : heatMapData.mapSubType === '' ? 'ahu' : heatMapData.mapSubType
    switch (type) {
      case "ahu": setAhuOpen(true)
        // setIconDevice(iconDevice3)
        break;
      default: break;
    }
    let f_id = localStorage.getItem('floorID')
    console.log("f_id", f_id)
    console.log("type.toLocaleUpperCase()", type.toLocaleUpperCase())
    api.floor.devicemap(f_id, type.toLocaleUpperCase()).then((res) => {
      console.log("resssssssssssssssss", res)
      res.sort(function (a, b) { return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0); });
      if (res.length !== 0) {
        let sdevices = []
        let CriticalTotal = 0
        let solutionTotal = 0
        if (alerts.system.length === 0 && alerts.solution.length === 0) {
          let con = 0
          res.map(element => {
            let obj = {}
            con++
            obj["name"] = element.name
            obj["ssid"] = element.ssid
            obj["type"] = element.type
            obj["alerts_cri"] = 0
            obj["alerts_solu"] = 0
            sdevices.push(obj)
            if (res.length === con) {
              setEachAhuDAta(sdevices)
            }
            return element
          })
        }


        if (alerts.system.length > 0 && alerts.solution.length > 0) {
          res.map(element => {
            let obj = {}
            obj["name"] = element.name
            obj["ssid"] = element.ssid
            obj["type"] = element.type
            let count = 0
            let ci = 0
            alerts.system.map(ele => {
              if (element.name === ele.device_name) {
                count++
                ci++
                CriticalTotal++
                if (alerts.system.length === count) {
                  obj["alerts_cri"] = ci
                  setcriticalAlertsAhu(CriticalTotal)
                  let si = 0
                  let counts = 0
                  alerts.solution.map(ele1 => {
                    if (element.name === ele1.device_name) {
                      counts++
                      si++
                      solutionTotal++
                      if (alerts.solution.length === counts) {
                        obj["alerts_solu"] = si
                        sdevices.push(obj)
                        setEachAhuDAta(sdevices)
                        setsoluAlertsAhu(solutionTotal)
                      }
                    } else {
                      counts++
                      if (alerts.solution.length === counts) {
                        obj["alerts_solu"] = si
                        sdevices.push(obj)
                        setEachAhuDAta(sdevices)
                        setsoluAlertsAhu(solutionTotal)
                      }
                    }
                    return ele1
                  })

                }
              } else {
                count++
                if (alerts.system.length === count) {
                  obj["alerts_cri"] = ci
                  setcriticalAlertsAhu(CriticalTotal)
                  let si = 0
                  let counts = 0
                  alerts.solution.map(ele => {
                    if (element.name === ele.device_name) {
                      counts++
                      si++
                      solutionTotal++
                      if (alerts.solution.length === counts) {
                        obj["alerts_solu"] = si
                        sdevices.push(obj)
                        setEachAhuDAta(sdevices)
                        setsoluAlertsAhu(solutionTotal)
                      }
                    } else {
                      counts++
                      if (alerts.solution.length === counts) {
                        obj["alerts_solu"] = si
                        sdevices.push(obj)
                        setEachAhuDAta(sdevices)
                        setsoluAlertsAhu(solutionTotal)
                      }
                    }
                    return ele
                  })
                }
              }
              return ele
            })
            return element
          })
        }
        if (alerts.system.length > 0 && alerts.solution.length === 0) {
          res.map(element => {
            let obj = {}
            obj["name"] = element.name
            obj["ssid"] = element.ssid
            obj["type"] = element.type
            let count = 0
            let ci = 0
            alerts.system.map(ele => {
              if (element.name === ele.device_name) {
                count++
                ci++
                CriticalTotal++
                if (alerts.system.length === count) {
                  obj["alerts_cri"] = ci
                  obj["alerts_solu"] = 0
                  setcriticalAlertsAhu(CriticalTotal)
                  sdevices.push(obj)
                  setEachAhuDAta(sdevices)
                }
              } else {
                count++
                if (alerts.system.length === count) {
                  obj["alerts_cri"] = ci
                  obj["alerts_solu"] = 0
                  setcriticalAlertsAhu(CriticalTotal)
                  sdevices.push(obj)
                  setEachAhuDAta(sdevices)
                }
              }
              return ele
            })
            return element
          })


        }
        if (alerts.system.length === 0 && alerts.solution.length > 0) {
          res.map(element => {
            let obj = {}
            obj["name"] = element.name
            obj["ssid"] = element.ssid
            obj["type"] = element.type
            let count = 0
            let ci = 0
            alerts.solution.map(ele => {
              if (element.name === ele.device_name) {
                count++
                ci++
                solutionTotal++
                if (alerts.solution.length === count) {
                  obj["alerts_solu"] = ci
                  obj["alerts_cri"] = 0
                  sdevices.push(obj)
                  setsoluAlertsAhu(solutionTotal)
                  setEachAhuDAta(sdevices)
                }
              } else {
                count++
                if (alerts.solution.length === count) {
                  obj["alerts_solu"] = ci
                  obj["alerts_cri"] = 0
                  sdevices.push(obj)
                  setsoluAlertsAhu(solutionTotal)
                  setEachAhuDAta(sdevices)
                }
              }
              return ele
            })
            return element
          })
        }
        let zoneData = []
        setHeatMapdata({
          ...heatMapData,
          rectData: zoneData,
          addressPoints: res,
          mapSubType: type
        })
      } else {
        setHeatMapdata({
          ...heatMapData,
          rectData: [],
          addressPoints: [],
          mapSubType: ""
        })
        setEachAhuDAta([]);
      }
    }).catch((error) => {
      setOpenerr(true)
      if (error.response) {
        setErrmsg(error.response.data.message)
      } else {
        setErrmsg('')
      }
      // setErrmsg(error.response.data.message)
    })
    api.dashboard.getMetricData(buildingID).then(res => {
    }).catch((error) => {
      setOpenerr(true)
      if (error.response) {
        setErrmsg(error.response.data.message)
      } else {
        setErrmsg('')
      }
      // setErrmsg(error.response.data.message)
    })
    const trendData = [{
      // 'ahu_chill_water_temperature':[],
      //'ahu_chilled_valve': [],
      'ChW Valve': [],
      // 'CHW_Vlv_Pos' : [],
      'RAT': [],
      'SAT': []
      // 'ahu_filter_status':[],
      // 'ahu_in_air_temperature':[],
      // 'ahu_on_off':[],
      // 'ahu_run_status':[],
      // 'ahu_set_point':[],
      //'ahu_supply_air_temperature': [],
      // 'ahu_trip_status':[],
      // 'ahu_vfd_mode':[],
      // 'fan_motor_speed':[],
      // 'mode':[],
      //'supply_air_flow': []
    }]
    // api.floor.ae(props.data.location.state.data).then(res => {
    api.floor.ae(localStorage.getItem('floorID')).then(res => {
      res.sort(function (a, b) { return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0); });
      if (res.length !== 0) {
        for (let keysss in trendData[0]) {
          res.forEach(obj => {
            for (let da in obj) {
              if (da === keysss) {
                trendData[0][keysss].push(obj[da])
              }
            }
          })
        }
        // console.log("trendData",trendData)
        setDeviceTrend(trendData)
      } else {
        setDeviceTrend([]);
      }
    }).catch((error) => {
      setOpenerr(true)
      // setErrmsg(error)
      // setErrmsg(error.response.data.message)
      if (error.response) {
        setErrmsg(error.response.data.message)
      } else {
        setErrmsg('')
      }
    })
    //eslint-disable-next-line
    api.floor
      .devicemap(f_id, "VAV")
      .then((res) => {
        res.sort(function (a, b) { return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0); });
        console.log("vaaaaaav", res)
        setVavdevice(res);
      }).catch((error) => {
        console.log(error)
      })
  }, [localStorage.getItem('floorName')]);


  const handleDeviceClick = (value) => { }
  // const handleClose1 = () => {
  //   setOpen(false);
  // };
  const handleerrorclose = () => {
    setOpenerr(false);
    setErrmsg('');
  };

  const onChangetype = newValue => {
    setNewValue(newValue)
    // console.log("new value",newValue)
    let type = heatMapData.mapSubType === "aqi" ? "all" : heatMapData.mapSubType
    // console.log("typppppppppppppppppppppppppeeeeeeeeeeeee",type)
    switch (newValue) {
      case "ahu":
        setAhuOpen(true)
        setTempOpen(false)
        setHumOpen(false)
        setLuxOpen(false)
        // setIconDevice(iconDevice3)
        break
      case "aqi":
        setLuxOpen(true)
        setAhuOpen(false)
        setTempOpen(false)
        setHumOpen(false)
        // setIconDevice(iconDevice1)
        break
      case "THL":
        setTempOpen(true)
        setLuxOpen(false)
        setAhuOpen(false)
        setHumOpen(false)
        setIconDevice(iconDevice1)
        break
      case "humidity":
        setHumOpen(true)
        setTempOpen(false)
        setLuxOpen(false)
        setAhuOpen(false)
        // setIconDevice(iconDevice1)
        break
      default: break;
    }

    if (newValue === 'ahu') {
      setLoading(true)
      const apiRequest = api.floor.devicemap(props.data.location.state.data, newValue.toLocaleUpperCase());
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          reject(new Error('Timeout: The API response took too long.'));
        }, 3000);
      });

      Promise.race([apiRequest, timeoutPromise])
        .then((res) => {
          let zoneData = []
          setAhuOpen(true)
          setHeatMapdata({
            ...heatMapData,
            rectData: zoneData,
            addressPoints: res,
            mapSubType: "ahu"
          });
        })
        .catch((error) => {
          setOpenerr(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // setLoading(true)
      if(props.data.location.state.data && newValue){
      const apiRequest = api.floor.heatmap(props.data.location.state.data, newValue.toLocaleUpperCase())
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          reject(new Error('Timeout: The API response took too long.'));
        }, 3000);
      });

      Promise.race([apiRequest, timeoutPromise])
        .then((res) => {
          let zoneData = [], devData = [];
          let obj = {}, deviceObj = {};
          setHeatMapdata({
            ...heatMapData,
            rectData: zoneData,
            addressPoints: res,
            mapSubType: newValue
          });
        })
        .catch((error) => {
          setOpenerr(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
  };
  const onClickIssue = (id, name, param) => {
    console.log('issuuuee', id, name, param)
    localStorage.setItem("deviceID", id);
    localStorage.setItem("deviceName", name);
    props.data.history.push({
      pathname: `/admin/glAhu`,
      state: {
        data: id,
        name: name
      }
    })
  }

  const handlevavclick = (data) => {
    setOpenmodal(true)
    setVavData(data)
  }

  const handleClose = () => {
    setOpenmodal(false)
    setText(false)
  }

  const eachVavData = (element, index) => {
    let active = {}
    active["name"] = element.name
    if (element.ssid) {
      active["ssid"] = element.ssid
    }
    if (element.VAV_ZAT_SP) {
      active["VAV_ZAT_SP"] = Math.round(element.VAV_ZAT_SP)
    }
    if (element.VAV_Dmpr_Pos) {
      active["VAV_Dmpr_Pos"] = Math.round(element.VAV_Dmpr_Pos)
    }
    if (element.VAV_ZAT) {
      active["VAV_ZAT"] = Math.round(element.VAV_ZAT)
    }
    if (element.VAV_CFM_Actual) {
      active["VAV_CFM_Actual"] = Math.round(element.VAV_CFM_Actual)
    }
    if (element.coordinates) {
      active["coordinates"] = element.coordinates
    }
    if (element.VAV_CFM_Design) {
      active["VAV_CFM_Design"] = Math.round(element.VAV_CFM_Design)
    }
    if (element.ss_tag) {
      active["ss_tag"] = element.ss_tag
    }

    const formattedCoordsArray = heatMapData.addressPoints.map((value) => {
      const coordinates = getJSONElement(value, ['coordinates']);
      if (coordinates && Array.isArray(coordinates) && coordinates.length >= 2) {
        return [
          coordinates[0],         // Latitude
          coordinates[1],         // Longitude
          parseFloat(value.temperature) // Temperature
        ];
      } else {
        return null; // or handle the case where coordinates are missing or invalid
      }
    });

    formattedCoordsArray.forEach((formattedCoords) => {
      coords.push(formattedCoords);
    });
    return (
      <>
        <StyledTooltip title={active.ss_tag} className={classes.tooltip} arrow>
          {/* <button  onClick={() => handlevavclick(active)} onMouseEnter={() => handleButtonHover(active)} onMouseLeave={handleButtonLeave} className={classes.vavbutton} style={{ backgroundColor:(active.VAV_ZAT <= 25 && active.VAV_ZAT >= 21)?"#21ba45":(active.VAV_ZAT <= 20)?"#F6BE00":"#FF0000", color: 'white',boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)" }}>{active.name}</button> */}
          <button onClick={() => handlevavclick(active)} onMouseLeave={handleButtonLeave} className={classes.smallbuttons} style={{ backgroundColor:(active.VAV_ZAT <= 25 && active.VAV_ZAT >= 21)? greenColor[0]:(active.VAV_ZAT <= 20)? yellowColor[0]: redColor[0], color: 'white', boxShadow: "1px 0px 8px 2px rgba(" + hexToRgb(blackColor) + ", 0.14)", color: 'white' }}>{active.name}</button>
        </StyledTooltip>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openmodal} classes={{ paper: classes.customDialog }}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {vavData.ss_tag}
          </DialogTitle>
          <DialogContent dividers>
            <Card className={classes.paper} style={{ height: "35vh" }}>
              <Grid container xs={12} spacing={1} style={{ marginTop: '0.5vh' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Card className={classes.paper1}>
                    <Grid container xs={12} spacing={1}>
                      <Grid container item xs={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                          Temperature Set Point
                        </Grid>
                      </Grid>

                      <Grid container item xs={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', whiteSpace: "nowrap", fontSize: "4vh", color: "#0123B4", fontWeight: "bold" }}>
                          {vavData.VAV_ZAT_SP}°C
                        </Grid>
                      </Grid>

                      {/* <Grid container item xs={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                          <div style={{ width: '100px' }}>
                            <ReactSimpleRange
                              min={0}
                              max={100}
                              step={1}
                              defaultValue={vavData.VAV_ZAT_SP}
                              trackColor='#0123B4'
                              thumbColor="#0123B4"
                              label={true}
                              eventWrapperPadding={8} />
                          </div>
                        </Grid>
                      </Grid> */}
                    </Grid>
                  </Card>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Card className={classes.paper1}>
                    <Grid container xs={12} spacing={1}>
                      <Grid container item xs={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                          Damper Position
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                          <div className={classes.semicircleBar}>
                            <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={vavData.VAV_Dmpr_Pos} showPercentValue />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Card className={classes.paper1} style={{ backgroundColor: vavData.VAV_ZAT <= 25 ? "#C1EECD" : "#fed0c1", color: vavData.VAV_ZAT <= 25 ? "#34C759" : "#fc6434" }}>
                    <Grid container xs={12} spacing={1}>
                      <Grid container item xs={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                          Zone Temperature
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ whiteSpace: "nowrap" }}>
                          <div className={classes.cardbody} style={{ color: vavData.VAV_ZAT <= 25 ? "#34C759" : "#fc6434" }}>
                            {vavData.VAV_ZAT}°C
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Card className={classes.paper1}>
                    <Grid container xs={12} spacing={1}>
                      <Grid container item xs={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                          Air flow CFM
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                          <StyledTooltip title={vavData.VAV_CFM_Actual} className={classes.tooltip} arrow>
                            <div style={{ marginTop: "-1vh", display: 'flex', justifyContent: 'center' }}>
                              <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={vavData.VAV_CFM_Actual / vavData.VAV_CFM_Design * 100} />
                            </div>
                          </StyledTooltip>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                          <Grid container item xs={12} direction="row">
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{ display: 'flex', justifyContent: 'right', color: '#000000', fontWeight: 'bold' }}>{MIN_AIR_FLOW}</Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ display: 'flex', justifyContent: 'right', color: '#000000', fontWeight: 'bold', marginLeft: '-0.5vh', marginTop: '-2.2vh' }}>{vavData.VAV_CFM_Actual == undefined ? ' ' : Math.round(vavData.VAV_CFM_Actual)}</Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{ display: 'flex', justifyContent: 'left', color: '#000000', fontWeight: 'bold', marginLeft: '1vh' }}>{vavData.VAV_CFM_Design}</Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Card>
          </DialogContent>
        </Dialog>
      </>
    )

  }

  const numEmptyCards = 24 - vavdevice.length; // Adjust the total number as needed
  const emptyCardsVav = Array.from({ length: numEmptyCards }, (_, index) => (
    <button className={classes.smallbuttons}></button>
  ));
  return (
    <div className={classes.root}>
      
      <Snackbar open={openerr} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert style={{ cursor: "pointer" }} severity="error" variant="filled" onClose={handleerrorclose}>
          {errmsg}
        </Alert>
      </Snackbar>
      <Grid container item xs={12} spacing={1}>
        {/* left side */}
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9} xxl={9}>
          <LandingPage processDataFromJson={false} device={eachAhuData} criticalAlerts={criticalAlertsAhu} soluAlerts={soluAlertsAhu} type="AHU" />
          <Grid container spacing={1} style={{ marginTop: '1vh' }}>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                <Grid container item xs={12} spacing={1}>
                  {/* VAV buttons */}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Card className={classes.imagecard} style={{ height: '41vh' }}>
                      <div className={classes.CardHeadFont} style={{ marginTop: window.innerHeight == '1280' ? "1vh" : '-1vh', fontWeight: 'bold', color: 'black' }}>VAV</div>
                      <Grid container direction="row">
                        {vavdevice.map((element, index) => (
                          <>
                            {eachVavData(element, index)}
                          </>
                        ))
                        }
                        {emptyCardsVav}
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  {/* switch buttons */}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
  <ButtonGroup fullWidth orientation="vertical" className={classes.buttonGroup}>
    <Button
      className={`${classes.button} ${newvalue === 'ahu' ? 'selected' : ''}`}
      onClick={() => onChangetype('ahu')}
      style={{ height: '60px' }} // Set a fixed height
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <span style={{ marginRight: '10px' }}>
          {newvalue === 'ahu' ? <ControlWhite /> : <Control />}
        </span>
        Controllables
      </div>
    </Button>
    <Button
      className={`${classes.button} ${newvalue === 'THL' ? 'selected' : ''}`}
      onClick={() => onChangetype('THL')}
      style={{ height: '60px' }} // Set a fixed height
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <span style={{ marginRight: '10px' }}>
          {newvalue === 'THL' ? <FloorTempIconWhite /> : <FloorTempIcon />}
        </span>
        Temperature
      </div>
    </Button>
    <Button
  className={`${classes.button} ${newvalue === 'humidity' ? 'selected' : ''}`}
  onClick={() => onChangetype('humidity')}
  style={{ height: '60px' }} // Set a fixed height
>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%',marginLeft:"-28px" }}>
    <span style={{ marginRight: '15px' }}>
      {newvalue === 'humidity' ? <FloorHumIcon /> : <FloorHumIcon />}
    </span>
    <span style={{ paddingLeft: '-34px', paddingRight: '10px' }}>Co2</span> {/* Add padding */}
  </div>
</Button>
    <Button
      className={`${classes.button} ${newvalue === 'aqi' ? 'selected' : ''}`}
      onClick={() => onChangetype('aqi')}
      style={{ height: '60px' }} // Set a fixed height
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <span style={{ marginRight: '15px' }}>
          {newvalue === 'aqi' ? <AirQualityIcon /> : <AirQualityIcon />}
        </span>
        <span style={{ paddingLeft: '-25px',paddingRight: '10px'}}> AirQuality</span>
      </div>
    </Button>
  </ButtonGroup>
</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={10} xl={10} xxl={10}>
                {/* heatmap */}
                <Card className={classes.imagecard}>
                  <Spin spinning={loading} size="default" style={{ justifyContent: 'center', alignContent: 'center', position: 'fixed' }}>
                    <Map
                      ref={mapRef}
                      doubleClickZoom={false}
                      zoomControl={false}
                      dragging={true}
                      scrollWheelZoom={false}
                      crs={Leaflet.CRS.Simple}
                      // center={[0, 0]}
                      center={[50, -100.66667]}
                      attributionControl={false}
                      // bounds={[[0, 0], [600, 730]]}
                      bounds={[[0, -290], [420, 850]]}
                      maxZoom={2}
                      className={"floor-map"}
                      style={{ backgroundColor: "white" }}
                      onClick={(e) => { console.log({ x: e.latlng.lat, y: e.latlng.lng }) }}
                    >
                      <ImageOverlay
                        interactive
                        // url={'https://localhost/' + localStorage.floorName + '.jpg'}
                        url={floor2}
                        // bounds={[[50, 15], [600, 730]]}
                        // bounds={[[100, -8], [525, 640]]}
                        bounds={[[0, -290], [420, 850]]}
                      />
                      {hoveredButton !== null && (
                        <Polygon
                          positions={hoveredButton?.coordinates || []}
                          fillOpacity={0.9}
                          color={hoveredButton !== null ? getColor(hoveredButton?.VAV_ZAT
                          ) : 'defaultColor'}
                          fillColor={hoveredButton !== null ? getColor(hoveredButton?.VAV_ZAT) : 'defaultColor'}
                        >
                          {/* <Tooltip className={classes.transparentTooltip}  
                                        opacity={1}>
                                          {`Polygon ${hoveredButton}`}
                                          <br />
                                          {`Temperature: ${buttonToPolygonInfo[hoveredButton]?.temperature}`}
                                        </Tooltip>           */}
                        </Polygon>
                      )}
                      {heatMapData.mapSubType !== 'aqi' && heatMapData.addressPoints ? (
                        heatMapData.addressPoints.map((value1, index) => (
                          newvalue === 'THL' ? (
                            <DevicemapTHL key={index} data={heatMapData.addressPoints} value={newvalue} data1={coords} iconDevice={iconDevice} />
                          ) : newvalue === 'ahu' || newvalue === 'humidity' ? (
                            <React.Fragment key={index}>
                              {value1.zone_coordinates && Array.isArray(value1.zone_coordinates) ? (
                                <Polygon
                                  key={index}
                                  positions={value1.zone_coordinates}
                                  color={getFillColor(value1.RAT)}
                                  fillColor={getFillColor(value1.RAT)}
                                  dashArray="5, 5"
                                  weight = {2} 
                                  fillOpacity={0.2}
                                />
                              ) : null}
                              {value1.coordinates && Array.isArray(value1.coordinates) && value1.coordinates.length >= 2 ? (
                                <Circle
                                  center={[value1.coordinates[0], value1.coordinates[1]]}
                                  radius={5}
                                  fillColor={getFillColor(value1.RAT)}
                                  fillOpacity={0.7}
                                  color={getFillColor(value1.RAT)}
                                >
                                  <Tooltip className={classes.transparentTooltip1} opacity={1}>
                                    {Object.keys(value1).map((key) => (
                                      key !== "ssid" &&
                                        key !== "coordinates" &&
                                        key !== "zoneId" &&
                                        key !== "zone_coordinates" &&
                                        key !== "type" &&
                                        key !== "zoneColor" &&
                                        key !== "id" ? (
                                        <React.Fragment key={key}></React.Fragment>
                                      ) : (
                                        <p key={key}>
                                          {key === "type" && value1.type === "NONGL_SS_AHU" ? (
                                            <>
                                              <b>{value1.name}</b>
                                              <br />
                                              <b>RAT:</b> {formatter.format(value1.RAT)}°C
                                              <br />
                                              <b>AHU STATUS:</b> {value1.AHU_On_Off}
                                            </>
                                          ) : (
                                            <>
                                              {key === "name" ? (
                                                <>{value1[key]}</>
                                              ) : key === "Temperature" ? (
                                                <>
                                                  {key}:{formatter.format(value1[key])}°C
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          )}
                                        </p>
                                      )
                                    ))}
                                  </Tooltip>
                                </Circle>
                              ) : null}
                            </React.Fragment>
                          ) : null
                        ))
                      ) : null}


                      <ZoomControl position="topright" />
                    </Map>
                  </Spin>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* right side(graphs) */}
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
          {
            deviceTrendData.map((trend) => (
              Object.keys(trend).map((key) => (
                <>
                  <Grid container item xs={12} spacing={1}style={{ marginTop: '1vh' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Card className={classes.graphpaper}>
                        <DevicetrendChart style={{ width: "100%"}} data={trend[key]} param={key} changeContext={props.changeContext} history={props.data.history} via="Floor's page"/>
                      </Card>
                    </Grid>
                  </Grid>
                </>
              ))
            ))
          }
        </Grid>
      </Grid>
      <SemanticToastContainer position='top-center' />
      {loading === true ?
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        :
        <div></div>
      }
    </div>
  )

}

export default Devicemap
